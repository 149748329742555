import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBInput, MDBNavbar, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { addresarvation } from '../../redux/actions/resaActions';
import NavbarLogout from '../../components/NavbarLogout';

import ToutResevation from './ToutResevation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment'
import Statistique from './Statistique';
import ReservationModifie from './ReservationModifie';



const ResertionAjout = () => {
  const [iconsActive, setIconsActive] = useState('tab1');
  const [sousAcompte, setSousAcompte] = useState([{ acompt: '', bqacompt: '', datacompt: '', soldacompt: '' }]);
  const [sousOC, setSousOC] = useState([{ OC: '', montantOC: '', datOC: '', payemenOC: '', bqOC: '', soldOC: '' }]);
  const [idresa,setIdResa]=useState("")
  const [villa,setVilla]=useState("")
  const [client,setClient]=useState("")
  const [nat,setNat]=useState("")
  const [nbP,setNbP]=useState(0)
  const [siteresa,setSiteresa]=useState("")
  const [dateresa,setDateresa]=useState("")
  const [dateA,setDateA]=useState("")
  const [dateD,setDateD]=useState("")
  const [nbN,setNbN]=useState(0)
  const [codeloc,setCodeloc]=useState("")
  const [totLoc,setTotLoc]=useState(0)
  const [commis,setCommis]=useState(0)
  const [totAComis,setTotAComis]=useState(0)
  const [dateVirT,setDateVirT]=useState("")
  const [etatD,setEtatD]=useState("")
  const [dateEC,setDateEC]=useState("")
  const [dateRC,setDateRC]=useState("")
  const dispatch=useDispatch()

  useEffect(() => {
    setTotAComis(totLoc - commis);
  }, [totLoc, commis]);

  useEffect(() => {
    const updatedSousAcompte = sousAcompte.map((item) => ({
      ...item,
      soldacompt: totAComis - item.acompt
    }));
    setSousAcompte(updatedSousAcompte);
  }, [totAComis, sousAcompte]);

  useEffect(() => {
    // Fonction pour calculer le nombre de nuitées
    const calculateNbNights = () => {
      // Vérifier si les dates d'arrivée et de départ sont valides
      if (dateA && dateD) {
        const arrivalDate = moment(dateA, "YYYY-MM-DD");
        const departureDate = moment(dateD, "YYYY-MM-DD");
        
        // Calculer la différence en jours
        const nights = departureDate.diff(arrivalDate, 'days');
        
        // Mettre à jour le nombre de nuitées
        setNbN(nights);
      }
    };

    // Appeler la fonction de calcul lorsque les dates changent
    calculateNbNights();
  }, [dateA, dateD]);


  const handleChangesousAcompte = (index, event) => {
    const { name, value } = event.target;
    const nouveauxSousChamps = [...sousAcompte];
    nouveauxSousChamps[index][name] = value;
    setSousAcompte(nouveauxSousChamps);
  };
  const ajouterSousAcompte = () => {
    setSousAcompte([...sousAcompte, { acompt: '', bqacompt: '', datacompt: '', soldacompt: '' }]);
  };

  const handleChangeSousOC = (index, event) => {
    const { name, value } = event.target;
    const nouveauxSousChamps = [...sousOC];
    nouveauxSousChamps[index][name] = value;
    setSousOC(nouveauxSousChamps);
  };
  const ajouterSousOC = () => {
    setSousOC([...sousOC, { OC: '', montantOC: '', datOC: '', payemenOC: '', bqOC: '', soldOC: '' }]);
  };

  const formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  };


      const handleSubmit = async (event) => {

        try {
          event.preventDefault(); // Empêcher le rafraîchissement de la page

          if (!idresa) {
            toast.error("Veuillez remplir ID réservation.");
            return;
          }
        //  Vérifier la période
          if (!villa) {
            toast.error("Veuillez remplir villa.");
            return;
          }
          //  Vérifier la période
          if (!client) {
            toast.error("Veuillez remplir client.");
            return;
          }
          //  Vérifier la période
          if (!nat) {
            toast.error("Veuillez remplir nationnalité.");
            return;
          }
          //  Vérifier la période
          if (!nbP) {
            toast.error("Veuillez remplir nombre de personne.");
            return;
          }
          //  Vérifier la période
          if (!siteresa) {
            toast.error("Veuillez remplir site de reservation.");
            return;
          }
          //  Vérifier la période
          if (!dateresa) {
            toast.error("Veuillez remplir date de reservation.");
            return;
          }
          //  Vérifier la période
          if (!dateA) {
            toast.error("Veuillez remplir date arrivé.");
            return;
          }
          //  Vérifier la période
          if (!dateD) {
            toast.error("Veuillez remplir date départ.");
            return;
          }
          //  Vérifier la période
          if (!nbN) {
            toast.error("Veuillez remplir nombre de nuit.");
            return;
          }
          //  Vérifier la période
          if (!codeloc) {
            toast.error("Veuillez remplir code location.");
            return;
          }
          //  Vérifier la période
          if (!totLoc) {
            toast.error("Veuillez remplir total location.");
            return;
          }
          //  Vérifier la période
          if (!commis) {
            toast.error("Veuillez remplir commission.");
            return;
          }
          //  Vérifier la période
          if (!totAComis) {
            toast.error("Veuillez remplir total apres commission.");
            return;
          }
          //  Vérifier la période
          if (!dateVirT) {
            toast.error("Veuillez remplir date virement final.");
            return;
          }
          //  Vérifier la période
          if (!etatD) {
            toast.error("Veuillez remplir etat dossier.");
            return;
          }


        const formData = {
          // tous les autres champs
          idresa: idresa,
          villa: villa, 
          nomL: client,
          nat: nat,
          nbP: nbP,
          siteR: siteresa,
          dateR: formatDate(dateresa),
          dateA: formatDate(dateA),
          dateD: formatDate(dateD),
          nbN: nbN,
          codL: codeloc,
          totalL: totLoc,
          Comsion: commis,
          TACommi: totAComis,
          dateRVT: formatDate(dateVirT),
          dossiefini: etatD,
          dateEC: formatDate(dateEC),
          dateRC: formatDate(dateRC),
          // les champs add
          sousAcompte,
          sousOC
        };
        await dispatch(addresarvation(formData))
        toast.success("Votre réservation confirmé a été enregistrer avec succès.");

        // Naviguer après un court délai
        setTimeout(() => {
          setIconsActive('tab3');
        }, 3000); // 3000 millisecondes = 3 secondes
                    
          } catch (error) {
          // Handle error
          console.error(error);
          }

              };    

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }

    setIconsActive(value);
  };
  return (
    <div>
      {/* Container pour les toasts */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            <header>
                            <MDBNavbar expand='lg' light bgColor='white' sticky>
                              <MDBContainer fluid style={{justifyContent:'center'}}>
                                
                                {/* Importation de narbar component  */}
                                <NavbarLogout/>

                              </MDBContainer>
                            </MDBNavbar>

                            {/* Contenu de la page contactez-nous en dissous en ferme avec </header */}
                

      <MDBTabs className='mb-3' style={{padding:'20px'}}>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
            <MDBIcon fas icon='plus-square' className='me-2' /> Ajouter Resa Confirmer
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
          <MDBIcon fas icon='cogs' className='me-2' /> Modifier Resa Confirmer
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
          <MDBIcon fas icon='chart-bar' className='me-2' /> Consulter Resa Confirmer
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
          <MDBIcon fas icon='chart-bar' className='me-2' /> Statistique
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane open={iconsActive === 'tab1'}>
                  <form style={{padding:'20px'}} onSubmit={handleSubmit}>

                <MDBRow className='mb-4' >
                <MDBCol>
                    <MDBInput name='idresa' type='text' label='ID Réservation ou Facture' onChange={(e)=>setIdResa(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='villa' type='text' label='Nom de villa' onChange={(e)=>setVilla(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='nomL' type='text' label='Nom de locateur' onChange={(e)=>setClient(e.target.value)}/>
                  </MDBCol>
                  </MDBRow>
                  <MDBRow className='mb-4'>
                  <MDBCol>
                    <MDBInput name='nat' type='text' label='Nationnalité' onChange={(e)=>setNat(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='nbP' type='number' label='Nombre de personne' onChange={(e)=>setNbP(e.target.value)} />
                  </MDBCol>
                  </MDBRow>
                  <MDBRow className='mb-4'>
                  <MDBCol>
                    <MDBInput name='siteR' type='text' label='Site de réservation' onChange={(e)=>setSiteresa(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='dateR' type='date' label='Date de réservation' onChange={(e)=>setDateresa(e.target.value)}/>
                  </MDBCol>
                </MDBRow>
                <MDBRow className='mb-4' >
                  <MDBCol>
                    <MDBInput name='dateA' type='date' label='Date arrivé' value={dateA} onChange={(e)=>setDateA(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='dateD' type='date' label='Date départ' value={dateD} onChange={(e)=>setDateD(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='nbN' type='number' label='Nombre de nuité' value={nbN} readOnly/>
                  </MDBCol>
                  </MDBRow>
                  <MDBRow className='mb-4' >
                  <MDBCol>
                    <MDBInput name='codL' type='text' label='Code location' onChange={(e)=>setCodeloc(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='totalL' type='number' label='Total location' onChange={(e)=>setTotLoc(e.target.value)}/>
                  </MDBCol>
                  </MDBRow>
                  <MDBRow className='mb-4' >
                  <MDBCol>
                    <MDBInput name='Comsion' type='number' label='Commission Plateforme' onChange={(e)=>setCommis(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='TACommi' type='number' label='Total Aprés Commission' value={totAComis} readOnly/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='dateRVT' type='date' label='Date de virement final' onChange={(e)=>setDateVirT(e.target.value)}/>
                  </MDBCol>
                  </MDBRow>
                  {/* Affichage des sous-champs */}
                  {sousAcompte.map((sousAcompte, index) => (
                  <MDBRow className='mb-4' key={index}>
                     {/* Bouton pour ajouter un sous-champ */}
                    <MDBBtn floating tag='a' onClick={ajouterSousAcompte}><MDBIcon fas icon='plus' /></MDBBtn>
                  <MDBCol>
                    <MDBInput name='acompt' type='number' label='Acompte 1' value={sousAcompte.acompt} onChange={(e)=>handleChangesousAcompte(index, e)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='bqacompt' type='text' label='Banque' value={sousAcompte.bqacompt} onChange={(e)=>handleChangesousAcompte(index, e)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='datacompt' type='date' label='Date' value={sousAcompte.datacompt} onChange={(e)=>handleChangesousAcompte(index, e)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='soldacompt' type='number' label='Solde' value={sousAcompte.soldacompt} readOnly/>
                  </MDBCol>
                  </MDBRow>
                  ))}  

                <MDBInput wrapperClass='mb-4' name='dossiefini' type='text' label='Etat de dossier' onChange={(e)=>setEtatD(e.target.value)}/>
                <MDBRow className='mb-4' >
                <MDBCol>
                    <MDBInput name='dateEC' type='date' label='Date envois contrat' value={dateEC} onChange={(e)=>setDateEC(e.target.value)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='dateRC' type='date' label='Date recevoir contrat' value={dateRC} onChange={(e)=>setDateRC(e.target.value)}/>
                  </MDBCol>
                  </MDBRow>

                {/* Affichage des sous-champs */}
                {sousOC.map((sousOC, index) => (
                <MDBRow className='mb-4' key={index}>
                  {/* Bouton pour ajouter un sous-champ */}
                  <MDBBtn floating tag='a' onClick={ajouterSousOC}><MDBIcon fas icon='plus' /></MDBBtn>
                  <MDBCol>
                    <MDBInput name='OC' type='text' label='Options choisis' value={sousOC.OC} onChange={(e)=>handleChangeSousOC(index, e)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='montantOC' type='number' label='Montant' value={sousOC.montantOC} onChange={(e)=>handleChangeSousOC(index, e)}/>
                  </MDBCol>
                  <MDBCol>
                    <MDBInput name='datOC' type='date' label='Date' value={sousOC.datOC} onChange={(e)=>handleChangeSousOC(index, e)}/>
                  </MDBCol>     
                  <MDBCol>
                    <MDBInput name='payemenOC' type='text' label='Payement' value={sousOC.payemenOC} onChange={(e)=>handleChangeSousOC(index, e)}/>
                  </MDBCol> 
                  <MDBCol>
                    <MDBInput name='bqOC' type='text' label='Banque' value={sousOC.bqOC} onChange={(e)=>handleChangeSousOC(index, e)}/>
                  </MDBCol> 
                  <MDBCol>
                    <MDBInput name='soldOC' type='number' label='Solde' value={sousOC.soldOC} onChange={(e)=>handleChangeSousOC(index, e)}/>
                  </MDBCol>           
                  </MDBRow>

                    ))}
                
                <MDBBtn className='mb-4' onClick={handleSubmit} block>
                  Enregistrer
                </MDBBtn>
              </form>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab2'}>
          <ReservationModifie/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab3'}>
        <ToutResevation/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab4'}>
        <Statistique/>
        </MDBTabsPane>
      </MDBTabsContent>


      </header>
      
    </div>
  )
}

export default ResertionAjout