import { ADD_CALENDRESA, FAIL_CALENDRESA, GET_CALENDRESA, LOAD_CALENDRESA } from "../actionTypes/actionTypes"



//initialisation
const initialState ={  
    calend:null,
    load:false,    
    error:null
}
//pure function
const calendReducer =(state=initialState,{type,payload})=>{
    switch (type) {
        case LOAD_CALENDRESA:
            return{...state,load:true}
        case ADD_CALENDRESA:
            return{...state,calend:payload.newEvent,load:false}
        case GET_CALENDRESA:
            return{...state,calend:payload.events,load:false}    
        case FAIL_CALENDRESA:
            return{...state,error:payload}     
        default:
            return state
    }
}
export default calendReducer