import React, { useState } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBModalFooter,
  MDBBtn,
  MDBModalBody,
  MDBModalTitle,
  MDBModalHeader,
  MDBModalContent,
  MDBModalDialog,
  MDBModal
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';
import { MdBedroomParent, MdBedroomChild  } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { FiUserCheck } from "react-icons/fi";
import CarouselTralala from './CarouselImage/CarouselTralala';
import CarouselRTK from './CarouselImage/CarouselRTK';
import GeneraterPrix from './GenerateurPrix/GeneraterPrix';
// import image from '../images/promocode.jpg'

const VillaLux = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal1, setBasicModal1] = useState(false);
  
  

  const toggleOpen = () => setBasicModal(!basicModal);
  const toggleOpen1 = () => setBasicModal1(!basicModal1);

  const keyframesStyleVL = `
  @keyframes blinkingVL {
    0% { color: red; }
    50% { color: green; }
    75% { color: white; }
    100% { color: red; }
  }
  @keyframes vibratingVL {
    0%, 100% { transform: translate(0); }
    25% { transform: translate(-2px, 2px); }
    50% { transform: translate(2px, -2px); }
    75% { transform: translate(-2px, -2px); }
  }
`;

const TextStyleVL = {
  animation: 'blinkingVL 10.5s infinite'
};
// const CodePromoVL = {
//   animation: 'blinkingVD 1.5s infinite, vibratingVD 0.3s infinite'
// };
  
  return (
    <div>
            <style>
              {keyframesStyleVL}
            </style>
      <MDBTypography tag='div' className='display-2 pb-3 mb-3 border-bottom' style={{padding:'70px',justifyContent:'center',display:'flex', ...TextStyleVL}}>DJERBA EDEN RESORT LUXE</MDBTypography>
      <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{paddingLeft:'10px', paddingRight:'10px',justifyContent:'space-around'}}>
      
      <MDBCol>
        <MDBCard className='h-100'>
          
           {/* importation carousel azale depuis galerie azale */}
           <CarouselTralala/>

          <MDBCardBody>
            <MDBCardTitle>Chambre d'hôte Tralala 
              {/* <Link to='/tralala'><img src={image} alt='' style={{width:'100px', height:'100px', ...CodePromoVL}}/></Link> */}
              </MDBCardTitle>
            {/* <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 220€ /<FiUserCheck /> </MDBCardTitle> */}
            <MDBCardTitle><FaUserAlt />=2  <MdBedroomParent />=1 </MDBCardTitle>
            <MDBCardText>
            Suite parentale avec un grand lit de 180 cm et salle de bain- WC privatifs, cuisine et salon majestueux, piscine et terrasse de délassement. Le grand luxe au calme avec une prestation haut de gamme.
            </MDBCardText>
            <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
            <Link to='/tralala'><MDBBtn className='me-1'>Consulter les dates disponibles et / ou réserver</MDBBtn></Link>
            <MDBBtn onClick={toggleOpen} className='me-1' color='secondary'>Détails</MDBBtn>
            </div>
            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Tralala
              <MDBCardTitle style={{color:'gray',fontSize:'25px'}}>
              <FaUserAlt />=2  <MdBedroomParent />=1
                </MDBCardTitle>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            UNIQUEMENT EN CHAMBRE D’HOTES AVEC PETIT-DEJEUNER
            <hr className="hr hr-blurry" />
            Suite parentale avec un grand lit de 180 cm et salle de bain- WC privatifs, cuisine et salon majestueux, piscine à débordement et terrasses de délassement. Le grand luxe au calme avec une prestation haut de gamme. Les photos parlent d’elles-mêmes….

            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn color='secondary'><Link to='/tralala'>Consulter les dates disponibles et / ou réserver</Link></MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          
           {/* importation carousel azale depuis galerie azale */}
           <CarouselRTK/>

          <MDBCardBody>
            <MDBCardTitle>ROCK THE KASBAH 
              {/* <Link to='/rock'><img src={image} alt='' style={{width:'100px', height:'100px', ...CodePromoVL}}/></Link> */}
              </MDBCardTitle>
            {/* <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 33€ /<FiUserCheck /> </MDBCardTitle> */}
            <MDBCardTitle><FaUserAlt />=8  <MdBedroomParent />=3 <MdBedroomChild />=2</MDBCardTitle>
            <MDBCardText>
            The must in Djerba avec deux suites parentales, deux chambres (1 avec lit de 180 et 1 avec deux lits de 120), 3 salles de bain avec WC, une grande cuisine, un salon exceptionnel, une piscine à débordement et tout le confort pour un séjour d’exception.
            </MDBCardText>
            <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
            <Link to='/rock'><MDBBtn className='me-2' >Dates disponibles et / ou réserver</MDBBtn></Link>
            {/* importation generateur prix */}
            <GeneraterPrix/>
            
            <MDBBtn onClick={toggleOpen1} className='me-2' color='secondary'>Détails</MDBBtn>
            </div>
            <MDBModal open={basicModal1} setOpen={setBasicModal1} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>ROCK THE KASBAH
              <MDBCardTitle style={{color:'gray',fontSize:'25px'}}>
              <FaUserAlt />=8  <MdBedroomParent />=3 <MdBedroomChild />=2
                </MDBCardTitle>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen1}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            Accessible aussi en chambre d’hôtes avec petit-déjeuner en Suite Familiale (pour 4 adultes ou deux adultes et deux enfants) ou en Suite (Suite Piscine ou Suite jardin) pour 2 adultes.
            <hr className="hr hr-blurry" />
            The must in Djerba avec deux suites parentales, deux chambres (une avec un lit de 180 et une avec deux lits de 120), 3 salles de bain avec WC, une grande cuisine, un salon exceptionnel, une piscine à débordement et tout le confort pour un séjour d’exception.
            Là aussi nos photos parlent d’elles-mêmes……


            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen1}>
                Close
              </MDBBtn>
              <MDBBtn color='secondary'><Link to='/rock'>Consulter les dates disponibles et / ou réserver</Link></MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
    </div>
  )
}

export default VillaLux