import React, { useState } from "react";
import { DatePicker, Button } from "antd";
import dayjs from "dayjs";
import frFR from "antd/es/date-picker/locale/fr_FR"; // Importez la locale française

import AzaleSearch from "./AzaleSearch";
import BougainvillierSearch from "./BougainvillierSearch";
import CasablancaSearch from "./CasablancaSearch";
import RTKSearch from "./RTKSearch";
import TralalaSearch from "./TralalaSearch";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { RangePicker } = DatePicker;

// Fonction pour vérifier si une date est dans une plage bloquée
const isDateBlocked = (current, startDate, endDate) => {
  return current >= startDate && current <= endDate;
};

function Searcheavailabledate() {
  const [selectedDates, setSelectedDates] = useState([]);
  const [availableVillas, setAvailableVillas] = useState([]);

  const handleSearch = () => {
    if (selectedDates.length !== 2) {
      toast.success("Veuillez sélectionner une plage de dates valide.");
      return;
    }

    const startDate = selectedDates[0];
    const endDate = selectedDates[1];

    // Logique de recherche
    console.log("Date d'arrivée :", startDate.format("DD/MM/YYYY"));
    console.log("Date de départ :", endDate.format("DD/MM/YYYY"));

    // Votre logique pour trouver les villas disponibles va ici...
    const available = [];

    // Vérifiez pour chaque villa si elle est disponible
    // Villa Azalé
    if (
      !isDateBlocked(startDate, dayjs("2024-04-17"), dayjs("2024-06-06")) &&
      !isDateBlocked(startDate, dayjs("2024-06-18"), dayjs("2024-06-30")) &&
      !isDateBlocked(startDate, dayjs("2024-07-17"), dayjs("2024-07-27")) &&
      !isDateBlocked(startDate, dayjs("2024-08-05"), dayjs("2024-08-19")) &&
      !isDateBlocked(startDate, dayjs("2024-08-27"), dayjs("2024-08-31")) &&
      !isDateBlocked(startDate, dayjs("2024-09-18"), dayjs("2024-10-31"))
    ) {
      available.push("Azalé");
    }

    // Villa Bougainvillier
    if (
      !isDateBlocked(startDate, dayjs("2024-04-01"), dayjs("2024-06-17")) &&
      !isDateBlocked(startDate, dayjs("2024-07-20"), dayjs("2024-08-10")) &&
      !isDateBlocked(startDate, dayjs("2024-08-12"), dayjs("2024-08-25")) &&
      !isDateBlocked(startDate, dayjs("2024-09-12"), dayjs("2024-12-31"))
    ) {
      available.push("Bougainvillier");
    }

    // Villa Casablanca
    if (
      !isDateBlocked(startDate, dayjs("2024-06-27"), dayjs("2024-08-01")) &&
      !isDateBlocked(startDate, dayjs("2024-08-03"), dayjs("2024-08-13")) &&
      !isDateBlocked(startDate, dayjs("2024-08-16"), dayjs("2024-08-23")) &&
      !isDateBlocked(startDate, dayjs("2024-09-27"), dayjs("2024-10-19")) &&
      !isDateBlocked(startDate, dayjs("2024-10-21"), dayjs("2024-10-28"))
    ) {
      available.push("Casablanca");
    }

    // Villa RTK
    if (
      !isDateBlocked(startDate, dayjs("2024-07-18"), dayjs("2024-07-25")) &&
      !isDateBlocked(startDate, dayjs("2024-07-27"), dayjs("2024-08-04")) &&
      !isDateBlocked(startDate, dayjs("2024-08-07"), dayjs("2024-08-25")) &&
      !isDateBlocked(startDate, dayjs("2024-09-27"), dayjs("2024-11-10")) &&
      !isDateBlocked(startDate, dayjs("2024-12-26"), dayjs("2025-01-16")) &&  
      !isDateBlocked(startDate, dayjs("2025-04-19"), dayjs("2025-05-03"))    
    ) {
      available.push("RTK");
    }

    // Villa Tralala
    if (
      !isDateBlocked(startDate, dayjs("2024-04-01"), dayjs("2024-04-30")) &&
      !isDateBlocked(startDate, dayjs("2024-06-16"), dayjs("2024-09-14")) &&
      !isDateBlocked(startDate, dayjs("2024-09-25"), dayjs("2024-11-14"))
    ) {
      available.push("Tralala");
    }

    setAvailableVillas(available);

    if (available.length === 0) {
      toast.info("Aucune villa disponible pour les dates sélectionnées.");
    }
  };

  return (
    <div className="search-container">

      {/* Container pour les toasts */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />


      <div style={{display:'flex',justifyContent:'center',paddingTop:'20px',gap:'10px',paddingBottom:'20px',backgroundColor:'ButtonShadow'}}>
      <RangePicker
        format="DD/MM/YYYY"
        locale={frFR} // Définissez la locale française
        onChange={(dates) => setSelectedDates(dates)}
      />
      <Button type="primary" onClick={handleSearch}>
        Rechercher
      </Button>
      </div>


      <div >
      {availableVillas.length === 1 && (
        <>
          {availableVillas[0] === "Azalé" && <AzaleSearch />}
          {availableVillas[0] === "Bougainvillier" && <BougainvillierSearch />}
          {availableVillas[0] === "Casablanca" && <CasablancaSearch />}
          {availableVillas[0] === "RTK" && <RTKSearch />}
          {availableVillas[0] === "Tralala" && <TralalaSearch />}
        </>
      )}

      {availableVillas.length > 1 && (
        <>
          {availableVillas.includes("Azalé") && <AzaleSearch />}
          {availableVillas.includes("Bougainvillier") && <BougainvillierSearch />}
          {availableVillas.includes("Casablanca") && <CasablancaSearch />}
          {availableVillas.includes("RTK") && <RTKSearch />}
          {availableVillas.includes("Tralala") && <TralalaSearch />}
        </>
      )}

      </div>
    </div>
  );
}

export default Searcheavailabledate;
