
import React from 'react';
import {
  MDBNavbar,
  MDBContainer,
  // MDBBtn,
  // MDBModal,
  // MDBModalDialog,
  // MDBModalContent,
  // MDBModalHeader,
  // MDBModalTitle,
  // MDBModalBody,
  // MDBModalFooter,
} from 'mdb-react-ui-kit';
import Navbar from './Navbar';
// import Discription from './Discription';
import VillasDjerbien from './VillasDjerbien';
import VillaLux from './VillaLux';
// import Bateau from './Bateau';
import Book from './Book/Book';
import Searcheavailabledate from './BarreSearche/Searcheavailabledate';


const SlideAcceuil = () => {
  // const [basicModal, setBasicModal] = useState(false);

  // const toggleOpen = () => setBasicModal(!basicModal);

  const keyframesStyleSA = `
  @keyframes blinkingSA {
    0% { background-color: red; }
    50% { background-color: yellow; }
    100% { background-color: green; }
  }
  @keyframes vibratingSA {
    0%, 100% { transform: translate(0); }
    25% { transform: translate(-2px, 2px); }
    50% { transform: translate(2px, -2px); }
    75% { transform: translate(-2px, -2px); }
  }
  @keyframes modalBlinkingSA {
    0%, 100% { border: 2px solid red; }
    50% { border: 2px solid yellow; }
  }
  @keyframes textBlinkingSA {
    0%, 100% { color: white; }
    50% { color: red; }
  }
  @keyframes modalVibratingSA {
    0%, 100% { transform: translate(0); }
    25% { transform: translate(-2px, 2px); }
    50% { transform: translate(2px, -2px); }
    75% { transform: translate(-2px, -2px); }
  }
  `;

  // const blinkingButtonStyle = {
  //   animation: 'blinkingSA 1s infinite, vibratingSA 0.3s infinite'
  // };
  // const blinkingTextStyle = {
  //   animation: 'textBlinkingSA 1s infinite, vibratingSA 0.3s infinite'
  // };
  // const blinkingModalStyle = {
  //   animation: 'modalBlinkingSA 1.5s infinite, modalVibratingSA 0.5s infinite'
  // };
  

  return (
    <div>
      <style>
        {keyframesStyleSA}
      </style>
      <header>
      <MDBNavbar expand='lg' light bgColor='white' sticky>
        <MDBContainer fluid style={{justifyContent:'center'}}>
          
          {/* Importation de narbar component */}
          <Navbar/>

        </MDBContainer>
      </MDBNavbar>

      <Searcheavailabledate/>

      <div
        className='p-5 text-center bg-image'
        style={{ backgroundImage: "url('https://res.cloudinary.com/dp0fbotva/image/upload/v1710240208/payout_massage_plein_air-transformed_biyx6y.jpg')", height: 1000 }}
      >
        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
          <div className='d-flex justify-content-center align-items-center h-100'>
            <div className='text-white'>
              <h1 className='mb-5'>Tunisia differently – La Tunisie autrement</h1>
              <h4 className='mb-4'>Bienvenue chez Eden Resort</h4>
              <a className='btn btn-outline-light btn-lg' href='/' role='button'>
                Réservez votre séjour
              </a>



              {/* <MDBBtn className='btn btn-outline-light btn-lg' onClick={toggleOpen} style={blinkingButtonStyle}>Nouveau : Achetez votre villa</MDBBtn> */}
              
      

            </div>
          </div>
        </div>
      </div>

      {/* <Discription/> */}
      
    
      <VillasDjerbien/>

      <VillaLux/>

      <Book />

      {/* on elimine bateau suite demande catherine */}

      {/* <Bateau/> */}

    </header>

    {/* <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent style={blinkingModalStyle}>
            <MDBModalHeader>
              <MDBModalTitle style={blinkingTextStyle}>Nouveau : Achetez votre villa</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody >
              Nos villas Tralala ou Rock The Kasbah vous plaisent ?
              <hr className="hr hr-blurry" />
              Vous souhaitez la même ? 
              <hr className="hr hr-blurry" />
              Possible pour 240 000 € TOUT COMPRIS
              <hr className="hr hr-blurry" />
              Louez une semaine pour vous rendre compte....
              Vous souhaitez investir sur Djerba et vivre un rêve éveillé au soleil, sous le ciel bleu et la mer azur ?
              <hr className="hr hr-blurry" />
              Nos villas, de très grand standing et de luxe avec piscine, peuvent devenir votre villa pour 240 000 € tout inclus avec les actes notariés et les taxes.
              Contactez-nous et nous vous mettrons en relation avec notre service PROMOTION IMMOBILIERE et notre SERVICE JURIDIQUE afin de vous proposer nos solutions CLEFS EN MAINS, pour votre nouvel villa sur son terrain avec un délai de 9 mois.
              <hr className="hr hr-blurry" />
              Plusieurs formules possibles en achat comptant à la livraison de la villa ou en Location avec Option d'Achat (LOA)
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
                Close
              </MDBBtn>              
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal> */}
    
    </div>
  )
}

export default SlideAcceuil