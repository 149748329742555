import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";


ReactGA.initialize("G-TML3P1GC1N");
// Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: window.location.pathname, });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <Provider store={store}>
    <HelmetProvider>
    <App />
    </HelmetProvider>
  </Provider>
    </BrowserRouter>
  </React.StrictMode>
);


