import axios from "axios"
import { ADD_PRIX, FAIL_PRIX, GET_PRIX, LOAD_PRIX } from "../actionTypes/actionTypes"




export const addTarifs=(newPrix)=> async(dispatch)=>   
{   dispatch({type:LOAD_PRIX}) // appel load

    try {
        let  result =await axios.post("/api/tarifs/ajoutPrix",newPrix)  // axios appel api  result.data <= token ,data ,newuser,msg 
        dispatch({type:ADD_PRIX,payload:result.data})
        console.log(result)
    } catch (error) {
            dispatch({type:FAIL_PRIX,payload:error})
    }

}


export const getTarifs = () => async (dispatch) => {
    dispatch({ type: LOAD_PRIX });

    try {
        let result = await axios.get("/api/tarifs/getPrix");
        dispatch({ type: GET_PRIX, payload: result.data });
        console.log(result);
    } catch (error) {
        dispatch({ type: FAIL_PRIX, payload: error });
    }
};