import React from 'react'
import NavbarLogout from '../../components/NavbarLogout'
import { MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBContainer, MDBNavbar } from 'mdb-react-ui-kit'
import { useSelector } from 'react-redux';

const Dashbord = () => {
  const user = useSelector(state => state.userReducer.user);



  return (
    <div>
                  <header>
                            <MDBNavbar expand='lg' light bgColor='white' sticky>
                              <MDBContainer fluid style={{justifyContent:'center'}}>
                                
                                {/* Importation de narbar component  */}
                                <NavbarLogout/>

                              </MDBContainer>
                            </MDBNavbar>


                             {/* Contenu de la page contactez-nous en dissous en ferme avec </header */}
                             
                                  <MDBCardBody>
                                    <MDBCardTitle style={{display:'flex',justifyContent:'center', paddingTop:'50px'}}>Administration Site</MDBCardTitle>

                                    <MDBCardText style={{display:'flex',justifyContent:'center'}}>
                                      <small className='text-muted'>Bienvenu, vous êtes connecté avec le compte "{user?.typeaccount}" </small>
                                    </MDBCardText>
                                    <div style={{display:'flex',justifyContent:'center'}}>
                                    <MDBCardImage src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_500/v1716292131/photo%20administration%20site/dasbord_ikl9c5.png' alt='...' />
                                    </div>
                                  </MDBCardBody>
                                  
                                
                  </header>
    </div>
  )
}

export default Dashbord