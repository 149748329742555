import React from 'react'
import Navbar from '../components/Navbar'
import BookReglement from '../components/Book/BookReglement'
import Footer from '../components/Footer'
import { MDBContainer, MDBNavbar } from 'mdb-react-ui-kit'
import { Helmet } from 'react-helmet-async'

const Reglement = () => {
  return (
    <div>
            <Helmet>
              <title>Règlement</title>
              <meta name="description" content="Règlement"/>
            </Helmet>
      <header>
      <MDBNavbar expand='lg' light bgColor='white' sticky>
        <MDBContainer fluid style={{justifyContent:'center'}}>
          
          {/* Importation de narbar component */}
          <Navbar/>

        </MDBContainer>
      </MDBNavbar>

      {/* Importation des component */}
      
      
      <BookReglement/>
      <Footer/>
      

    </header>
      
    </div>
  )
}

export default Reglement