import React from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'


import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';






const RTKSearch = () => {





  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715775991/rock/vueface_xphjk9.jpg',
      alt: 'Test alt 1',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/2.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715775995/rock/vuf_wdcamh.jpg',
      alt: 'Test alt 2',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/3.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715775992/rock/salljardin_ah1vwh.jpg',
      alt: 'Test alt 3',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715775991/rock/piscin_rwoye6.jpg',
      alt: 'Test alt 4',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641281/rock/slon_cuinse_jqedoh.jpg',
      alt: 'Test alt 5',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332935/rock/entrerr_u7q4ac.jpg',
      alt: 'Test alt 6',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641004/rock/entrer_ci6sya.jpg',
      alt: 'Test alt 7',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714640990/rock/cuisine_qjigy5.jpg',
      alt: 'Test alt 8',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641020/rock/piscine1_wazt3o.jpg',
      alt: 'Test alt 9',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641065/rock/deco11_wjyvaq.jpg',
      alt: 'Test alt 10',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641112/rock/deco_1_zh9cbn.jpg',
      alt: 'Test alt 11',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332938/rock/chamb_jitixx.jpg',
      alt: 'Test alt 12',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/suite1_sjrtcl.jpg',
      alt: 'Test alt 13',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332933/rock/cham_opwoty.jpg',
      alt: 'Test alt 14',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332933/rock/ch_hptk8q.jpg',
      alt: 'Test alt 15',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/sb2_bno5zw.jpg',
      alt: 'Test alt 16',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/sb1_fm5hwm.jpg',
      alt: 'Test alt 17',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/sb_vqxvjh.jpg',
      alt: 'Test alt 18',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332937/rock/sol_cuis_fdjgx4.jpg',
      alt: 'Test alt 19',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332934/rock/meuble_jard_a6okfo.jpg',
      alt: 'Test alt 20',
    },

    
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};


 
  return (
    <div >
       

                 
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>

                            {/* gallerie component elle entour que l'image */}
                            <LightGallery
                                onInit={onInit}
                                speed={100}
                                plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                            >

                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711962832/vue_piscine2_og0cxd.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>

                                  {carouselPhotos.map((image, index) => {
                                return (
                                  
                                    <a href={image.src} key={index} >
          
                                    </a>
        
                                )
                            })}

                          </LightGallery>


                            <MDBCardBody>
                              <MDBCardTitle>ROCK THE KASBAH</MDBCardTitle>
                              <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 1850 € / Semaine </MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de cette villa en cliquant sur la photo
                              </MDBCardText>
                              <div style={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                              <MDBBtn color='success'>Disponible</MDBBtn>
                              <MDBBtn className='mx-2' color='info'><Link to='/rock'>Réserver</Link></MDBBtn>
                              <MDBBtnGroup shadow='0'>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="users" /> 8 Pax
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="bed" /> 4 Chambres
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="chart-area" /> 300m²
                                </MDBBtn>
                              </MDBBtnGroup>
                              </div>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      


                  
    </div>
  )
}

export default RTKSearch