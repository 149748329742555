import { MDBBtn, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBInput, MDBInputGroup, MDBModal, MDBModalBody, MDBModalContent, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'


const GeneraterPrix = () => {

  const [basicModal, setBasicModal] = useState(false);
  const [nbjour, setNbjour] = useState(0)
  const [villa, setVilla] = useState("")
  const [saison, setSaison] = useState("")
  const [totalfinal, setTotalFinal] = useState()

  

  const toggleOpen = () => setBasicModal(!basicModal);

  useEffect(() => {
    CalculePrix();
  }, );

  const CalculePrix = () => {
    // Définir les tarifs par saison et par type de service
    const tarifs = {
      Azale: {
        Haute_Saison: 1800,
        Moyenne_Saison: 1400,
        Basse_Saison: 1100
      },
      Bougainvilliers: {
        Haute_Saison: 1470,
        Moyenne_Saison: 1470,
        Basse_Saison: 1470
      },
      Casablanca: {
        Haute_Saison: 1470,
        Moyenne_Saison: 1200,
        Basse_Saison: 900
      },
      RTK: {
        Haute_Saison: 2900,
        Moyenne_Saison: 2400,
        Basse_Saison: 1850
      }
    };
  
    // Vérifier que le type de villa et la saison sont sélectionnés
    if (villa && saison) {
      // Vérifier que le nombre de jours est un nombre valide
      const nbJourNumber = parseInt(nbjour);
      if (!isNaN(nbJourNumber)) {
        // Récupérer le tarif par jour en fonction de la saison et du type de villa
        const tarifParJour = tarifs[villa][saison];
        let nouveauTotal;
  
        // Appliquer des réductions ou majorations en fonction du nombre de jours
        if (nbJourNumber === 4) {
          nouveauTotal = tarifParJour * 0.65;
        } else if (nbJourNumber === 5) {
          nouveauTotal = tarifParJour * 0.75;
        } else if (nbJourNumber === 6) {
          nouveauTotal = tarifParJour * 0.85;
        } else if (nbJourNumber === 7) {
          nouveauTotal = tarifParJour * 1;
        } else if (nbJourNumber >= 8 && nbJourNumber <= 14) {
          nouveauTotal = tarifParJour * 1.8;
        } else if (nbJourNumber >= 15 && nbJourNumber <= 21) {
          nouveauTotal = tarifParJour * 2.6;
        } else if (nbJourNumber >= 22 && nbJourNumber <= 28) {
          nouveauTotal = tarifParJour * 3.3;
        } else {
          // Gérer le cas où le nombre de jours n'est pas pris en charge
          console.error("Le nombre de jours n'est pas pris en charge.");
          return;
        }
        setTotalFinal(nouveauTotal);
      } else {
        // Gérer le cas où le nombre de jours n'est pas un nombre valide
        console.error("Le nombre de jours n'est pas valide.");
      }
    } else {
      // Gérer le cas où le type de villa ou la saison n'est pas sélectionné
      console.error("Veuillez sélectionner le type de villa et la saison.");
    }
  };
  
  

  return (
    <div>

<MDBBtn className='me-2' onClick={toggleOpen}>Calculer prix</MDBBtn>
      <MDBModal open={basicModal} onClose={() => setBasicModal(false)} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Simulateur de prix</MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody style={{display:'flex',justifyContent:'space-around',flexWrap:'wrap',gap:'15px'}}>
            <MDBBtn color='warning' disabled>Comment calculer votre tarif pour des sejours differents de 7 nuits ?</MDBBtn>
            
            <MDBBtn outline rounded className='mx-2' color='warning' disabled>calcule pour 4 nuits</MDBBtn>
            <MDBBtn outline rounded className='mx-2' color='info' disabled>Tarif 7 nuits x 0.65</MDBBtn>

            <MDBBtn outline rounded className='mx-2' color='warning' disabled>calcule pour 5 nuits</MDBBtn>
            <MDBBtn outline rounded className='mx-2' color='info' disabled>Tarif 7 nuits x 0.75</MDBBtn>

            <MDBBtn outline rounded className='mx-2' color='warning' disabled>calcule pour 6 nuits</MDBBtn>
            <MDBBtn outline rounded className='mx-2' color='info' disabled>Tarif 7 nuits x 0.87</MDBBtn>

            <MDBBtn outline rounded className='mx-2' color='warning' disabled>calcule pour 8 à 14 nuits</MDBBtn>
            <MDBBtn outline rounded className='mx-2' color='info' disabled>Tarif 7 nuits x 1.8</MDBBtn>

            <MDBBtn outline rounded className='mx-2' color='warning' disabled>calcule pour 15 à 21 nuits</MDBBtn>
            <MDBBtn outline rounded className='mx-2' color='info' disabled>Tarif 7 nuits x 2.6</MDBBtn>

            <MDBBtn outline rounded className='mx-2' color='warning' disabled>calcule pour 22 à 28 nuits</MDBBtn>
            <MDBBtn outline rounded className='mx-2' color='info' disabled>Tarif 7 nuits x 3.3</MDBBtn>

            <MDBBtn outline rounded className='mx-2' color='warning' disabled>Pour toutes réservations supérieures à 28 jours, veuillez nous contacter. </MDBBtn>
            </MDBModalBody>

            <hr className="hr hr-blurry" />
            
            <MDBModalBody>
                <MDBInputGroup className='mb-3' style={{display:'flex', justifyContent:'space-around', flexWrap:'wrap', gap:'15px'}}>
                    
                    <MDBDropdown>
                      <MDBDropdownToggle >Choisisez votre villa: {villa}</MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem onClick={()=>setVilla("RTK")}>Rock The Kasbah</MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={()=>setVilla("Azale")}>Azalée</MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={()=>setVilla("Casablanca")}>Casablanca</MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={()=>setVilla("Bougainvilliers")}>Bougainvilliers</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                    <MDBDropdown>
                      <MDBDropdownToggle >Choisisez La Saison: {saison}</MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem onClick={()=>setSaison("Haute_Saison")}>Haute Saison</MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={()=>setSaison("Moyenne_Saison")}>Moyenne Saison</MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem onClick={()=>setSaison("Basse_Saison")}>Basse Saison</MDBDropdownItem>                       
                      </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBInputGroup>

                    <MDBInput label='Nombre des jours (Min 4 jours, sauf Juillet/Aout Min 7 jours)' wrapperClass='mb-4' type='number' onChange={(e)=>setNbjour(e.target.value)} min={4} max={28}/>
                    
                    <MDBInput label='Votre total en euros (€)' wrapperClass='mb-4' type='number' value={totalfinal} disabled />
                    
            </MDBModalBody>
              
            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
                Close
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>



       
    </div>
  )
}

export default GeneraterPrix