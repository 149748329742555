import { ADD_PRIX, FAIL_PRIX, GET_PRIX, LOAD_PRIX } from "../actionTypes/actionTypes"



//initialisation
const initialState ={  
    prix: null,
    load: false,
    error: null
}
//pure function
const prixReducer =(state=initialState,{type,payload})=>{
    switch (type) {
        case LOAD_PRIX:
            return{...state,load:true}
        case ADD_PRIX:
            return{...state,prix:payload.newPrix,load:false }   
        case GET_PRIX:
                return { ...state, prix: payload.tarifs, load: false };
        case FAIL_PRIX:
            return{...state,error:payload}     
        default:
            return state
    }
}
export default prixReducer