import React from 'react'
import './book.css'
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'
import image from '../../images/logodjerba.png'


const Book = () => {
  return (
    <div>
      <MDBCard alignment='center'>
      <MDBCardBody>
      <div id="wrapper">
    <div id="container">

        <section class="open-book">
            <header>   
            </header>
            <article>
                <h2 class="chapter-title">Eden Resort</h2>
                <p>
                Bienvenus
                Eden Resort, Votre réference  pour  des vacances extraordinaires en Tunisie
                quand  terre et mer se rencontrent pour créer des souvenirs inoubliables.
                Les portes de l'exceptionnel s’ouvrent à vous
                Séjours novateurs pour découvrir d'une manière tout à fait singulière la beauté unique de ce pays.

                </p>
                <hr className="hr hr-blurry" />
                <img src={image} alt='' />
                <p>               
                Djerba l’ile au sable d’or si chère à Homère ou Flaubert , inscrite au patrimoine mondial de l’UNESCO.
                </p>
                
                <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">LES JOURS HEUREUX A DJERBA</a></strong></dt>
                <hr className="hr hr-blurry" />
                
                <p>
                Au cœur du quartier le plus prestigieux de Djerba (Tezdaïne) toutes nos villas de grand confort et spacieuses  sont arborées, sans vis-à-vis situées à 1 km des plus belles plages de Djerba  et de la Lagune. 
                </p>
                
                <p>
                Tranquillité, calme, sérénité  pour un séjour reposant et en toute intimité.
                </p>
                <hr className="hr hr-blurry" />
                <dt><strong><mark class="blue">DJERBA AUTREMENT :</mark></strong></dt>
                <p>
                3 villas , authentiques et  djerbiennes.
                </p>
                <dd>
                <p>
                <strong>Bougainvilliers (6 Pax) environ 200 m²</strong>
                </p>
                <p>
                <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_150/v1711546809/bougainvillier/piscine_lu63zy.jpg' alt='' style={{borderRadius:'60px'}}/>
                </p>
                <p>
                villa composée
                </p>
                    <ul>
                    <li>                  
                    1  Suite  parentale avec salle de bain, et dressing
                    </li>
                    <li>                  
                    1 Chambre avec grand lit 
                    </li>
                    <li>                  
                    1 Chambre avec 2 lits 1 personne
                    </li>
                    <li>                  
                    2 Salles de bain séparées
                    </li>
                    <li>                  
                    Cuisine parfaitement équipée
                    </li>
                    <li>                  
                    Cuisine extérieure, BBQ
                    </li>
                    <li>                  
                    Plusieurs salons intérieurs et extérieurs avec vue mer 
                    </li>
                    <li>                  
                    De nombreuses terrasses
                    </li>
                    <li>                  
                    Une grande piscine 
                    </li>
                    <li>                  
                    Transats 
                    </li>
                    <li>                  
                    Douche extérieure et WC extérieur
                    </li>
                    <li>                  
                    Un magnifique jardin clos de murs 
                    </li>
                    <li>                  
                    Pour  des vacances inoubliables
                    </li>
                    </ul>

                    <p>
                    <strong>Azalée (8 Pax) environ 400 m²</strong>
                    </p>
                    <p>
                    <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_150/v1711363871/azal%C3%A9e/vue_de_face_grand_piscine_tj5nbw.jpg' alt='' style={{borderRadius:'60px'}}/>
                    </p>
                    <p>
                    villa composée
                    </p>
                    <ul>
                    <li>
                    Cuisine de plus de 30 m²
                    </li> 
                    <li>
                    BBQ
                    </li> 
                    <li>
                    Ses salons
                    </li> 
                    <li>
                    Terrasses
                    </li> 
                    <li>
                    Terrasse toit vue mer avec salon de massage 
                    </li> 
                    <li>
                    Deux piscines (adultes et enfants) 
                    </li> 
                    <li>
                    Douche extérieure et WC extérieur
                    </li> 
                    <li>
                    Transats
                    </li> 
                    <li>
                    Magnifique jardin arboré et clos de murs, sans vis-à-vis
                    </li> 
                    <li>
                    Vous passerez des vacances exceptionnelles. Un ressourcement bénéfique….
                    </li>
                    </ul>

                    <p>
                    <strong>Casablanca (6 Pax) environ 500 m²</strong>
                    </p>
                    <p>
                    <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_100,w_150/v1711547478/jasmin/piscine3_djscu8.jpg' alt='' style={{borderRadius:'60px'}}/>
                    </p>
                    <p>
                    villa composée 1er étage 
                    </p>
                    <ul>
                    <li>
                    Suite parentale avec douche vue mer et terrasse 
                    </li> 
                    <li>
                    1  Chambre grand lit vue sur piscine 
                    </li> 
                    <li>
                    1 Chambre avec 2 lits superposés 
                    </li> 
                    <li>
                    1 Salle de bain,  baignoire et wc  vue piscine 
                    </li> 
                    <li>
                    Deux salons intérieurs, avec coupoles  
                    </li> 
                    <li>
                    2 Terrasses extérieures vue mer et piscine 
                    </li> 
                    <p>
                    villa composée rez de chaussée 
                    </p>
                    <li>
                    Salle à manger,  intérieure 
                    </li> 
                    <li>
                    Salle à manger exterieure 
                    </li> 
                    <li>
                    Salon exterieur
                    </li> 
                    <li>
                    Cuisine équipée, 
                    </li>
                    <li>
                    Terrasses de plus de 300 m² 
                    </li>
                    <li>
                    Piscine et son magnifique jardin arboré et à l’abri des regards  
                    </li>
                    <li>
                    Vous passerez des vacances de rêve dans un parc d’un hectare au calme. A l’ombre des oliviers centenaires  
                    </li>
                    </ul>
                    </dd>

                    <hr className="hr hr-blurry" />
                    <dt><strong><mark class="blue">DJERBA EDEN RESORT LUXE :</mark></strong></dt>
                    <p>
                    2 villas  ultra-luxueuses et logotisées D’Art Tralala et Rock The Kasba 
                    </p>
                    <dd>
                    <p>
                    <strong>D’Art Tralala (2 Pax) Uniquement en chambre d'hotes. Villa Privatisée rien que pour vous (environ 150 m²)
                    </strong>
                    </p>
                    <p>
                    <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_100,w_150/v1712657987/tralala/vue_piscine_soir_lotvrg.jpg' alt='' style={{borderRadius:'60px'}}/>
                    </p>
                    <p>
                    villa composée rez de jardin et piscine  
                    </p>
                    <ul>
                    <li>                  
                    Suite parentale avec un grand lit de 180 cm
                    </li>
                    <li>                  
                    Salle de bain
                    </li>
                    <li>                  
                    WC privatifs
                    </li>
                    <li>                  
                    Grandes baies vitrées donnant sur piscine et jardin arboré 
                    </li>
                    <li>                  
                    Cuisine équipée ultra moderne 
                    </li>
                    <li>                  
                    Salon aux volumes généreux et à la décoration originale et créative 
                    </li>
                    <li>                  
                    Piscine à débordement 
                    </li>
                    <li>                  
                    Terrasse aménagée
                    </li>
                    <li>                  
                    Transats 
                    </li>
                    <li>                  
                    Parasols  
                    </li>
                    <li>                  
                    Salon abrité 
                    </li>
                    <li>                  
                    Salle à manger extérieure et BBQ 
                    </li>
                    <li>                  
                    Le grand luxe au calme avec une prestation haut de gamme
                    </li>
                    <li>                  
                    Villa privatisée rien que pour vous 
                    </li>
                    </ul>

                    <p>
                    <strong>Rock The Kasbah (8 Pax) environ 200 m²</strong>
                    </p>
                    <p>
                    <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_100,w_150/v1715775992/rock/salljardin_ah1vwh.jpg' alt='' style={{borderRadius:'60px'}}/>
                    </p>
                    <p>
                    The must in Djerba  villa composée rez de jardin et piscine 
                    </p>
                    <ul>
                    <li>
                    1  Suite parentales donnant sur piscine avec salle de bain, wc privatifs, dressings
                    </li> 
                    <li>
                    1 Suite parentale donnant sur jardin avec salle de bain, wc privatifs, dressings
                    </li> 
                    <li>
                    1 Chambre avec lit de 180  donnant sur piscine et larges baies vitrées 
                    </li> 
                    <li>
                    1 Chambre avec deux lits de 120 
                    </li> 
                    <li>
                    1 Salle de bain avec WC 
                    </li> 
                    <li>
                    WC indépendant  
                    </li> 
                    <li>
                    Une grande cuisine, ultra moderne et équipée
                    </li> 
                    <li>
                    Un salon aux volumes spectaculaires et à la décoration originale  
                    </li> 
                    <li>
                    Une piscine à débordement et tout le confort pour un séjour d’exception. 
                    </li> 
                    <li>
                    Accessible aussi en chambre d’hôtes.(hors juillet et aout)
                    </li>
                    </ul>
                    
                    
                    </dd>

                    {/* on elimine bateau suite demande catherine */}

                    {/* <hr className="hr hr-blurry" />
                    <dt><strong><mark class="blue">DJERBA EDEN RESORT BATEAU :</mark></strong></dt>
                    
                    <dd>
                    <p>
                    <strong>Rita (6 Pax) environ 16 m</strong>
                    </p>
                    <p>
                    <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_100,w_150/v1712915662/rita/2_p8of2o.jpg' alt='' style={{borderRadius:'60px'}}/>
                    </p>
                    <ul>
                    <li>
                    <strong>Notre yacht RITA de 16 mètres</strong> (deux cabines premium )amarré au port de la Marina Djerba selon vos besoins.(pour une nuit, une journée, une croisiére ,,,,)
                    </li>                                  
                    </ul>
                    </dd> */}

                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">Equipements</a></strong></dt>
                    <p>
                    Hors du temps mais reliées, toutes nos villas sont équipées de :
                    </p>
                    <dd>
                    <ul>
                    <li>
                    Connexion internet
                    </li>
                    <li>
                    Connexion satellite pour accéder aux différentes chaînes internationales.
                    </li>
                    <li>
                    TV
                    </li>
                    <p>
                    Equipement cuisine :
                    </p>
                    <li>
                    Lave vaisselle
                    </li>
                    <li>
                    Lave linge
                    </li>
                    <li>
                    Gazinière 
                    </li>
                    <li>
                    Four
                    </li>
                    <li>
                    Micro ondes
                    </li>
                    <li>
                    Cafetière capsules
                    </li>
                    <li>
                    Bouilloire
                    </li>
                    <li>
                    Frigo
                    </li>
                    <li>
                    Congélateur
                    </li>
                    <li>
                    Table à repasser 
                    </li>
                    <li>
                    Fer à repasser 
                    </li>
                    <li>
                    Climatisation
                    </li>
                    <p>
                    Equipement Exterieur :
                    </p>
                    <li>
                    Réserve d’eau
                    </li>
                    <li>
                    BBQ
                    </li>
                    <li>
                    Transats
                    </li>
                    <li>
                    Parasols
                    </li>
                    <li>
                    Coussins
                    </li>
                    <li>
                    Tables
                    </li>
                    <li>
                    Salons
                    </li>
                    <li>
                    Douche 
                    </li>
                    <li>
                    Wc
                    </li>
                    <li>
                    Parking 
                    </li>
                    <li>
                    Portail électrique 
                    </li>
                    </ul>
                    </dd>

                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">CONCIERGERIE 24H/24</a></strong></dt>
                    <p>
                    Eden Resort
                    </p>
                    <p>
                    Pour un séjour VIP ,
                    </p>
                    <p>
                    Uniquement  partager des moments uniques, laissez vous porter et accompagner en toute tranquillité durant votre séjour
                    </p>
                    <p>
                    Chaque séjour est conçu avec passion pour vous offrir les souvenirs d’une vie. 
                    </p>
                    <hr className="hr hr-blurry" />
                    <p>
                    <mark>vous êtes uniques, votre séjour sera unique</mark>
                    </p>
                    <p>
                    <mark>rappelez -vous ….,  vous êtes en vacances !</mark>
                    </p>
                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">Nos services et prestations 5 étoiles (certaines en OPTION)</a></strong></dt>
                    <dd>
                    <ul>
                    <li>
                    conciergerie accessible 7j/7 et 24h/24 en trois langues (arabe, anglais et français) pour votre sérénité et pour répondre à vos demandes .
                    </li>
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">Restauration</a></strong></dt>
                    <dd>
                    <ul>
                    <li>
                    cuisinière à domicile et service traiteur
                    </li>
                    <li>
                    chef à domicile pour repas, apéritifs dînatoires, brunch , anniversaires,
                    </li>
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">ENTRETIEN</a></strong></dt>
                    <dd>
                    <ul>
                    <li>
                    pressing
                    </li>
                    <li>
                    femme de ménage
                    </li>
                    <li>
                    courses
                    </li>
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">Bien être</a></strong></dt>
                    <dd>
                    <ul>
                    <li>
                    massages à domicile
                    </li>
                    <li>
                    professeurs de gymnastique ou d’aquagym.
                    </li>
                    <li>
                    sophrologie
                    </li>
                    <li>
                    yoga
                    </li>
                    <li>
                    ...et toutes demandes spécifiques
                    </li>
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">Activités sportives</a></strong></dt>
                    <dd>
                    <ul>
                    <li>
                    marche
                    </li>
                    <li>
                    vélo
                    </li>
                    <li>
                    kite surf
                    </li>
                    <li>
                    jet ski
                    </li>
                    <li>
                    parachute ascensionnel
                    </li>
                    <li>
                    golf
                    </li>
                    <li>
                    équitation
                    </li>
                    <li>
                    ...et toutes demandes spécifiques
                    </li>
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                    <dt><strong><a href="/">Excursions et ballades</a></strong></dt>
                    <dd>
                    <ul>
                    <li>
                    Marchés Midoun et Houmt Souk
                    </li>
                    <li>
                    mosquées souterraines
                    </li>
                    <li>
                    tour de l’ile
                    </li>
                    <li>
                    caléches
                    </li>
                    <li>
                    dromadaires
                    </li>
                    <li>
                    cheval
                    </li>
                    <li>
                    ile aux flamands
                    </li>
                    <li>
                    ...et toutes demandes spécifiques
                    </li>
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                    <p><strong>
                    Notre objectif est de vous faciliter la vie. Veuillez nous contacter pour toute demande. 
                    </strong></p>
                    <p><strong>
                    Nous vous répondrons par retour de mail. 
                    </strong></p>
                
                {/* <ul>
                    <li>
                        "What if highlighting the text actually looked <mark>like a highlighter</mark>?"
                        <ul>
                            <li>"What if I wanted it to be <mark class="pink">pink</mark>?"</li>
                            <li>"<mark class="blue">Blue</mark>?"</li>
                            <li>"<mark class="green">Green</mark>?"</li>
                            <li>"<mark class="orange">Orange</mark>?"</li>
                        </ul>
                    </li>
                    <li>"What if I found <s>a typo</s><sup>an error in the content</sup> and wanted to make a correction?"</li>
                    <li>"What if I was viewing this on mobile&mdash;"</li>
                </ul> */}
                {/* <p>
                Eden Resort vous invite à découvrir nos services VIP pendant votre séjour dans une villa privée avec les prestations d’un hôtel 5 étoiles. Nous avons sélectionné pour vous l'espace, le calme et le confort, avec une conciergerie accessible 7j/7 et 24h/24 en trois langues (arabe, anglais et français) pour votre sérénité. Une connexion internet à haut débit et une connexion satellite pour accéder aux différentes chaînes internationales sont à votre disposition.
                </p>
                <p>
                <mark>Certaines de nos villas sont disponibles en CHAMBRE D’HÔTES, de 2 à 6 personnes, avec petit déjeuner, en moyenne et basse saison et pour un minimum de deux nuits. Il s’agit des villas Casablanca, Tralala et Rock The Kasbah.</mark>
                </p>
                <p>
                Notre conciergerie 7j/7 et 24h/24 est à votre disposition durant votre séjour pour répondre à toutes vos demandes, excursions terrestres avec nos partenaires professionnels et reconnus ou maritimes sur notre yacht RITA de 16 mètres avec deux cabines premium car, souvenez-vous ….,  vous êtes en vacances !
                </p>
                <p>
                Etre en vacances, c’est aussi la possibilité d’avoir nos cuisinières à domicile ou nos masseuses, nos professeurs de gymnastique ou d’aquagym, no limit pour votre bien-être.
                </p>
                <p>
                Notre objectif est de vous faciliter la vie. Veuillez nous contacter pour toute demande. Nous vous répondrons par retour de mail.
                </p>
                <hr className="hr hr-blurry" />
                <dt><strong><a href=".">Nos Villas</a></strong></dt>
                <p>Nos 5 villas sont référencées en deux thématiques:</p>
                    <dd>
                    <ul>
                    <li>
                      <mark class="blue">DJERBA AUTREMENT :</mark> 3 villas authentiques, éthiques et de style djerbien
                    </li>
                    <li>
                    <mark class="blue">DJERBA EDEN RESORT LUXE :</mark> 2 villas décorées et aménagées par Rock the Kasbah, le designer ultra tendance et Dar Tralala, le magasin spécialisé en artisanat tunisien et en up recycling à Houmt-Souk. Vivre Djerba en mode palace.
                    </li>                 
                    </ul>
                    </dd>
                <p><strong>
                Toutes nos villas sont équipées du linge de maison nécessaires (bain, nuit et repas), d’une connexion internet Adsl ou 4 G, de la télévision par satellite (chaînes arabes et/ou françaises), d’un service de conciergerie 7j/7, d’un assistant dédié pendant votre séjour, d’un jardinier, d’un pisciniste et vous avez accès, en option, à une multitude de services pour une réussite parfaite de vos vacances.
                </strong></p>
                <hr className="hr hr-blurry" />
                <dt><strong><mark class="blue">DJERBA AUTREMENT :</mark></strong></dt>
                <dd>
                    <ul>
                    <li>
                    <strong>Villa Bougainvilliers (6 Pax) :</strong> Avec une surface de plus de 180 m², cette villa est composée de 2 suites dont une parentale avec salle de bain, une chambre avec 2 lits 1 personne, 2 salles de bain séparées, une cuisine parfaitement équipée, plusieurs salons intérieurs et extérieurs, de nombreuses terrasses, une grande piscine et un magnifique jardin clos de murs où vous passerez des vacances inoubliables.
                    </li>
                    <li>
                    <strong>Villa Azalée (8 Pax) :</strong> Avec ses 4 suites parentales avec salle de bain privative, sa cuisine de plus de 30 m², ses salons, ses terrasses, ses deux piscines (adultes et enfants) et son magnifique jardin, vous passerez des vacances exceptionnelles. Un ressourcement bénéfique….
                    </li> 
                    <li>
                    <strong>Villa Casablanca (8 Pax) :</strong> Avec sa suite parentale, ses deux chambres avec salles de bains, ses deux salons intérieurs, salle à manger, cuisine équipée, ses 5 salons extérieurs, ses terrasses de plus de 300 m², sa piscine et son magnifique jardin, vous passerez des vacances de rêve dans un parc d’un hectare au calme.
                    </li>                
                    </ul>
                    </dd>
                    <hr className="hr hr-blurry" />
                <dt><strong><mark class="blue">DJERBA EDEN RESORT LUXE :</mark></strong></dt>
                <dd>
                    <ul>
                    <li>
                    <strong>Villa Tralala (2 Pax) UNIQUEMENT EN CHAMBRE D’HOTES :</strong> Suite parentale avec un grand lit de 180 cm et salle de bain- WC privatifs, cuisine et salon majestueux, piscine et terrasse de délassement. Le grand luxe au calme avec une prestation haut de gamme.
                    </li>
                    <li>
                    <strong>Villa Rock The Kasbah (8 Pax) :</strong> The must in Djerba avec deux suites parentales, deux chambres (1 avec lit de 180 et 1 avec deux lits de 120), 3 salles de bain avec WC, une grande cuisine, un salon exceptionnel, une piscine à débordement et tout le confort pour un séjour d’exception.
                    Accessible aussi en chambre d’hôtes.
                    </li>                                  
                    </ul>
                    </dd>
                <hr className="hr hr-blurry" /> */}
                
            </article>
            <footer>
                <ol id="page-numbers">
                    <li>1</li>
                    <li>2</li>
                </ol>
            </footer>
        </section>

    </div>
</div>
</MDBCardBody>
</MDBCard>
    </div>
  )
}

export default Book