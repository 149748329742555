import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBInput, MDBNavbar, MDBRow } from 'mdb-react-ui-kit'
import Footer from '../components/Footer'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

const ContactezNous = () => {
  const [name, setName]= useState("")
  const [email, setEmail]= useState("")
  const [objec, setobjec]= useState("")
  const [telephone, setTelephone]= useState("")
  const [messg, setMessg]= useState("")
  const navigate =useNavigate()
  

  const handleSubmit = (event) => {

    try {
      event.preventDefault(); // Empêcher le rafraîchissement de la page

     // Vérifier 
      if (!name) {
        toast.error("Veuillez ajouter vote nom.");
        return;
      }
     // Vérifier l'email
     if (!email) {
      toast.error("Veuillez remplir votre email.");
      return;
    }
     // Vérifier 
     if (!objec) {
      toast.error("Veuillez ajouter l'objetde votre demande.");
      return;
    }
     // Vérifier le téléphone
     if (!telephone) {
      toast.error("Veuillez remplir votre téléphone.");
      return;
    }
      // Vérifier 
      if (!messg) {
        toast.error("Veuillez remplir votre message.");
        return;
      }
  
    let dataSend = {
      name:name,
      email:email,
      objec:objec,
      telephone:telephone,
      messg:messg
    }
    
    axios.post('/api/email/sendEmail', dataSend)

    toast.success("Votre demande a été envoyée avec succès.");

                        // Naviguer après un court délai
                        setTimeout(() => {
                          navigate("/");
                        }, 5000); // 3000 millisecondes = 3 secondes
                                    
              } catch (error) {
                // Handle error
                console.error(error);
              }
   
  };


  return (
    <div>
                  <Helmet>
                    <title>Contactez-nous</title>
                    <meta name="description" content="Contactez-nous"/>
                  </Helmet>
      {/* Container pour les toasts */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

          <header>
                <MDBNavbar expand='lg' light bgColor='white' sticky>
                  <MDBContainer fluid style={{justifyContent:'center'}}>
                    
                    {/* Importation de narbar component */}
                    <Navbar/>

                  </MDBContainer>
                </MDBNavbar>

                {/* Contenu de la page contactez-nous en dissous en ferme avec </header */}
                
 

      <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{display:'flex',justifyContent:'center', paddingTop:'100px'}}>
      <MDBCol>
        <MDBCard className='h-100'>
          
          <MDBCardBody >
            <MDBCardTitle style={{display:'flex',justifyContent:'center'}}>Contactez-nous</MDBCardTitle>
            <MDBCardText style={{display:'flex',justifyContent:'center'}}>
            Notre objectif est de vous faciliter la vie. <br/>Veuillez nous contacter pour toute demande. <br/>Nous vous répondrons dès que possible
            </MDBCardText>
            <MDBCardText>
              <form style={{paddingTop:'100px'}} onSubmit={handleSubmit}>
                <MDBInput name='name' type='text' wrapperClass='mb-4' label='Votre Prénom et Nom' onChange={(e)=>setName(e.target.value)}/>
                <MDBInput name='email' type='email' id='form4Example2' wrapperClass='mb-4' label='Votre E-mail' onChange={(e)=>setEmail(e.target.value)}/>
                <MDBInput name='objec' type='text' wrapperClass='mb-4' label='Objet' onChange={(e)=>setobjec(e.target.value)}/>
                <MDBInput name='telephone' type='number' wrapperClass='mb-4' label='Téléphone: Code pays - Numéro ' onChange={(e)=>setTelephone(e.target.value)}/>
                <MDBInput name='messg' wrapperClass='mb-4' textarea id='form4Example3' rows={4} label='Votre message' onChange={(e)=>setMessg(e.target.value)}/>

                

                <MDBBtn type='submit' className='mb-4' block>
                  Envoyer
                </MDBBtn>
              </form>
            </MDBCardText>
          </MDBCardBody>
          
        </MDBCard>
      </MDBCol>

      <MDBCol>
        <MDBCard className='h-100'>
          
          <MDBCardBody>
            <MDBCardTitle style={{display:'flex',justifyContent:'center'}}>Adresse</MDBCardTitle>
            <MDBCardText>
            Centre Meninx Bureau 6 4116 Midoun
            <hr className="hr hr-blurry" />
            CONTACTS <br></br> contact@edenresort.fr
            <hr className="hr hr-blurry" />
            TÉLÉPHONE FRANCAIS <br></br> 00 33 1 86 47 13 19
            <hr className="hr hr-blurry" />
            TÉLÉPHONE TUNISIEN <br></br> 00 216 70 02 65 99
            <hr className="hr hr-blurry" />
            </MDBCardText>
            
            
                <div class="ratio ratio-1x1">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d293.01764163791927!2d10.996975334199817!3d33.80931472860586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13aa97c416b6f4a9%3A0xa0d49e97b06a062b!2sMeninx%20Medical%20Center!5e0!3m2!1sfr!2stn!4v1711435492857!5m2!1sfr!2stn" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='maps'>
                </iframe>
                </div>
            
          </MDBCardBody>
          
        </MDBCard>
      </MDBCol>
      
      
    </MDBRow>


    <Footer/>
      
    </header>
    </div>
  )
}

export default ContactezNous