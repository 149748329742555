import axios from "axios"
import { ADD_RESA, FAIL_RESA, LOAD_RESA, UPDATE_RESA } from "../actionTypes/actionTypes"



export const addresarvation=(newResa)=> async(dispatch)=>   
{   dispatch({type:LOAD_RESA}) // appel load

    try {
        let  result =await axios.post("/api/booking/confirmed",newResa)  // axios appel api  result.data <= token ,data ,newuser,msg 
        dispatch({type:ADD_RESA,payload:result.data})
        console.log(result)
    } catch (error) {
            dispatch({type:FAIL_RESA,payload:error})
    }

}

// Ajoutez cette action pour mettre à jour une réservation
export const updateReservation = (idresa, formData) => async (dispatch) => {
    dispatch({ type: LOAD_RESA });

    try {
        let result = await axios.put(`/api/booking/updateResa/${idresa}`, formData);
        dispatch({ type: UPDATE_RESA, payload: { updatedResa: result.data } });
        console.log(result);
    } catch (error) {
        dispatch({ type: FAIL_RESA, payload: error });
    }
};