import React, { useState } from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'
import Navbar from '../../components/Navbar';

import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';


import Footer from '../../components/Footer';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConfigProvider, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const disabledDate = (current) => {
  
  // Can not select days before today and today and other date
  return  (current && current < dayjs().endOf('day'))
  || (dayjs('2024:04:01', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:04:30', 'YYYY:MM:DD')  >= current)
  || (dayjs('2024:07:18', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:07:25', 'YYYY:MM:DD')  >= current)
  || (dayjs('2024:07:27', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:08:04', 'YYYY:MM:DD')  >= current)
  || (dayjs('2024:08:07', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:08:25', 'YYYY:MM:DD')  >= current)
  || (dayjs('2024:09:27', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:11:10', 'YYYY:MM:DD')  >= current)
  || (dayjs('2024:12:26', 'YYYY:MM:DD')  <= current  &&  dayjs('2025:01:16', 'YYYY:MM:DD')  >= current)
  || (dayjs('2025:04:19', 'YYYY:MM:DD')  <= current  &&  dayjs('2025:05:03', 'YYYY:MM:DD')  >= current)
};


const Rock = () => {

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [price, setPrice] = useState(0);
  const [codepromo,setCodepro]=useState("")
  const [prenom,setPrenom]=useState("")
  const [nom,setNom]=useState("")
  const [nbpersonne,setNbpersonne]=useState("")
  const [adresse,setAdresse]=useState("")
  const [email,setEmail]=useState("")
  const [telephone,setTelephone]=useState("")
  const [commentaire,setCommentaire]=useState("")
  const [checkedtype, setCheckedType] = useState(false)
  const [checkedopt1, setCheckedopt1] = useState(false)
  const [checkedopt2, setCheckedopt2] = useState(false)
  const [checkedopt3, setCheckedopt3] = useState(false)
  const [checkedopt4, setCheckedopt4] = useState(false)
  const [checkedopt5, setCheckedopt5] = useState(false)
  const [checkedopt6, setCheckedopt6] = useState(false)
  const [checkedopt7, setCheckedopt7] = useState(false)
  const [checkedopt8, setCheckedopt8] = useState(false)
  const [checkedopt9, setCheckedopt9] = useState(false)
  const [checkedopt10, setCheckedopt10] = useState(false)
  const [checkedopt11, setCheckedopt11] = useState(false)
  const [checkedopt12, setCheckedopt12] = useState(false)
  const [checkedopt13, setCheckedopt13] = useState(false)
  const [checkedopt14, setCheckedopt14] = useState(false)
  const navigate =useNavigate()


  function handleDateChange(dates) {
    if (dates && dates.length === 2) {
        const [startDate, endDate] = dates;
        setFrom(startDate);
        setTo(endDate);

        const getSeasonPrice = (date) => {
            if (
                ((dayjs('2024-01-16').isBefore(date) || dayjs('2024-01-16').isSame(date, 'day')) &&
                (dayjs('2024-03-31').isAfter(date) || dayjs('2024-03-31').isSame(date, 'day'))) ||
                ((dayjs('2024-11-01').isBefore(date) || dayjs('2024-11-01').isSame(date, 'day')) &&
                (dayjs('2024-12-14').isAfter(date) || dayjs('2024-12-14').isSame(date, 'day')))
            ) {
                return 1850; // Base saison
            } else if (
                ((dayjs('2024-01-01').isBefore(date) || dayjs('2024-01-01').isSame(date, 'day')) &&
                (dayjs('2024-01-15').isAfter(date) || dayjs('2024-01-15').isSame(date, 'day'))) ||
                ((dayjs('2024-04-01').isBefore(date) || dayjs('2024-04-01').isSame(date, 'day')) &&
                (dayjs('2024-06-15').isAfter(date) || dayjs('2024-06-15').isSame(date, 'day'))) ||
                ((dayjs('2024-09-15').isBefore(date) || dayjs('2024-09-15').isSame(date, 'day')) &&
                (dayjs('2024-10-31').isAfter(date) || dayjs('2024-10-31').isSame(date, 'day'))) ||
                ((dayjs('2024-12-15').isBefore(date) || dayjs('2024-12-15').isSame(date, 'day')) &&
                (dayjs('2024-12-31').isAfter(date) || dayjs('2024-12-31').isSame(date, 'day')))
            ) {
                return 2400; // Moyenne saison
            } else if (
                (dayjs('2024-06-16').isBefore(date) || dayjs('2024-06-16').isSame(date, 'day')) &&
                (dayjs('2024-09-14').isAfter(date) || dayjs('2024-09-14').isSame(date, 'day'))
            ) {
                return 2900; // Haute saison
            }
            return 1850; // Default to base saison
        };

        const seasonPrice = getSeasonPrice(startDate);
        const totalNights = endDate.diff(startDate, 'days');
        let totalPrice = seasonPrice;

        if (totalNights >= 4 && totalNights <= 6) {
            totalPrice *= totalNights === 4 ? 0.65 : totalNights === 5 ? 0.75 : 0.87;
        } else if (totalNights === 7) {
            totalPrice *= 1;
        } else if (totalNights >= 8 && totalNights <= 14) {
            totalPrice *= 1.8;
        } else if (totalNights >= 15 && totalNights <= 21) {
            totalPrice *= 2.6;
        } else if (totalNights >= 22 && totalNights <= 28) {
            totalPrice *= 3.3;
        } else if (totalNights >= 29) {
            alert("Un devis sera préparé pour vous.");
            totalPrice = 0;
        }

        // Check if selected range is less than minimum nights based on the month
        let minimumNights = 4; // Default minimum nights (5 days with 4 nights)
        if (startDate.month() === 6 || startDate.month() === 7) { // July or August
            minimumNights = 7; // 8 days with 7 nights
        }
        if (totalNights < minimumNights) {
            alert("Veuillez sélectionner une période d'au moins " + minimumNights + " nuits.");
        }
         // Application de la réduction pour le mois de juillet et avec le code promo "R10-0724"
         if (startDate.month() === 6 && codepromo === "R10-0724") { // 6 correspond à juillet (index 0)
          totalPrice *= 0.9; // Appliquer une réduction de 10%
        }

        setPrice(totalPrice);
    } else {
        // If dates are null, reset the total price to 0
        setPrice(0);
    }
}
  
  
 
           // pour appeler useraction et dispatche login dans onclick sur le button

           const handleSubmit = (event) => {

            try {
            event.preventDefault(); // Empêcher le rafraîchissement de la page

           // Vérifier la période
            if (!from || !to) {
              toast.error("Veuillez remplir la période.");
              return;
            }

            // Vérifier le type de logement
            if (!checkedtype) {
              toast.error("Veuillez choisir le type de logement.");
              return;
            }

            // Vérifier le prénom et le nom
            if (!prenom || !nom) {
              toast.error("Veuillez remplir votre prénom et nom.");
              return;
            }

            // Vérifier nb personne
            if (!nbpersonne) {
              toast.error("Veuillez ajouter le nombre de personne.");
              return;
            }
            // Vérifier adresse
            if (!adresse) {
              toast.error("Veuillez remplir votre adresse.");
              return;
            }

            // Vérifier l'email
            if (!email) {
              toast.error("Veuillez remplir votre email.");
              return;
            }

            // Vérifier le téléphone
            if (!telephone) {
              toast.error("Veuillez remplir votre téléphone.");
              return;
            }



            let dataBookSend = {
              checktype: checkedtype,
              checkedopt1: checkedopt1,
              checkedopt2: checkedopt2,
              checkedopt3: checkedopt3,
              checkedopt4: checkedopt4,
              checkedopt5: checkedopt5,
              checkedopt6: checkedopt6,
              checkedopt7: checkedopt7,
              checkedopt8: checkedopt8,
              checkedopt9: checkedopt9,
              checkedopt10: checkedopt10,
              checkedopt11: checkedopt11,
              checkedopt12: checkedopt12,
              checkedopt13: checkedopt13,
              checkedopt14: checkedopt14,
              from: from,
              to: to,
              codepromo:codepromo,
              prenom:prenom,
              nom:nom,
              nbpersonne:nbpersonne,
              totalprix: price,
              adresse:adresse,
              email:email,
              telephone: telephone,
              commentaire: commentaire
            }
            
            
            // // Perform form submission
            
               axios.post('/api/email/sendEmailBooking', dataBookSend);
              
                  
                  toast.success("Votre demande de réservation a été envoyée avec succès.");

                      // Naviguer après un court délai
                      setTimeout(() => {
                        navigate("/nosvilla");
                      }, 5000); // 3000 millisecondes = 3 secondes
                                  
            } catch (error) {
              // Handle error
              console.error(error);
            }
           
        };



  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643471/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_2_whvlpu.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643471/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_2_whvlpu.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643459/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.02_4_xncibj.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643459/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.02_4_xncibj.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643460/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.08_1_wy4zp0.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643460/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.08_1_wy4zp0.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643463/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_3_vafs7f.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643463/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_3_vafs7f.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643456/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.04_1_nwmh34.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643456/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.04_1_nwmh34.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643454/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.10_ozklsy.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643454/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.10_ozklsy.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643458/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.03_jnygjr.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643458/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.03_jnygjr.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643470/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.05_qvqmoj.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643470/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.05_qvqmoj.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643472/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.56.30_1_rdmdhl.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643472/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.56.30_1_rdmdhl.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643467/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_butudi.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643467/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_butudi.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643462/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.06_2_qsk1hu.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643462/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.06_2_qsk1hu.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643460/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.06_1_kgvfad.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643460/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.06_1_kgvfad.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643455/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_1_a9a9vi.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643455/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_1_a9a9vi.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643455/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_2_gceerw.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643455/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_2_gceerw.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643457/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_3_cieo2a.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643457/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_3_cieo2a.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643460/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.08_yjjdyf.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643460/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.08_yjjdyf.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643465/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_crcx7r.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643465/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_crcx7r.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643463/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.06_csi3kc.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643463/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.06_csi3kc.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643465/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.07_gjgyzi.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721643465/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.07_gjgyzi.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644139/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-_cjo56s.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644139/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-_cjo56s.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644139/Rock%20avec%20mes%20modif/ext/the_rock_kasbah--2_ztn0xz.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644139/Rock%20avec%20mes%20modif/ext/the_rock_kasbah--2_ztn0xz.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644135/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-0097_mzwrrc.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644135/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-0097_mzwrrc.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644133/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-0094_fzmwyz.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644133/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-0094_fzmwyz.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644133/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-0093_tn6lfn.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644133/Rock%20avec%20mes%20modif/ext/the_rock_kasbah-0093_tn6lfn.jpg',
      alt: 'Vue extérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644308/Rock%20avec%20mes%20modif/int/the_rock_kasbah-0056_nhzvkg.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644308/Rock%20avec%20mes%20modif/int/the_rock_kasbah-0056_nhzvkg.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644300/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.51.02_1_fqp9z7.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644300/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.51.02_1_fqp9z7.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644294/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.51.01_c1snbs.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644294/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.51.01_c1snbs.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644298/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.51.01_1_mm0vov.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644298/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.51.01_1_mm0vov.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644304/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.22_1_t8qge1.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644304/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.22_1_t8qge1.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644301/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.25_u2wkok.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644301/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.25_u2wkok.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644297/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.18_p5c88i.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644297/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.18_p5c88i.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644299/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.24_1_kpstle.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721644299/Rock%20avec%20mes%20modif/int/WhatsApp_Image_2024-07-04_at_09.56.24_1_kpstle.jpg',
      alt: 'Intérieur',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646249/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.50.58_3_hdpvii.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646249/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.50.58_3_hdpvii.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646245/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.51.02_cmsnjl.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646245/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.51.02_cmsnjl.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646246/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.27_1_egucic.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646246/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.27_1_egucic.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646247/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.27_ubyq8h.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646247/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.27_ubyq8h.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646245/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.26_2_fbrwr3.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646245/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.26_2_fbrwr3.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646246/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.31_tievzw.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646246/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.31_tievzw.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646248/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.32_2_yhogzn.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646248/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.45.32_2_yhogzn.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646250/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.54.40_1_qtehyt.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646250/Rock%20avec%20mes%20modif/cuisine/WhatsApp_Image_2024-07-04_at_09.54.40_1_qtehyt.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646452/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.48_2_t6zyr0.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646452/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.48_2_t6zyr0.jpg',
      alt: 'Suite vue piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646453/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.52_erfgrs.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646453/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.52_erfgrs.jpg',
      alt: 'Suite vue piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646453/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.45.50_2_snrf7l.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646453/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.45.50_2_snrf7l.jpg',
      alt: 'Dressing suite vue piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646454/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.45.41_2_mv5ett.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646454/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.45.41_2_mv5ett.jpg',
      alt: ' SB suite vue piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646654/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.46_zt4siq.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646654/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.46_zt4siq.jpg',
      alt: 'Suite vue jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646658/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_12.58.42_2_rnsalz.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646658/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_12.58.42_2_rnsalz.jpg',
      alt: 'Suite vue jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646656/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.53_1_ihvtqo.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646656/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.53_1_ihvtqo.jpg',
      alt: 'SB suite vue jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646765/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.46_1_mcl9sr.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646765/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.46_1_mcl9sr.jpg',
      alt: 'Chambre vue piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646763/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_12.59.46_iydu0t.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646763/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_12.59.46_iydu0t.jpg',
      alt: 'Chambre vue piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646833/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.48_lmprhl.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646833/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.48_lmprhl.jpg',
      alt: 'Chambre vue jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646831/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.47_xzqwqa.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646831/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.47_xzqwqa.jpg',
      alt: 'Chambre vue jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646901/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.53_2_yybbz3.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646901/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_09.50.53_2_yybbz3.jpg',
      alt: 'SB chambres vue piscine et jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646902/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_13.01.27_xejwdx.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1721646902/Rock%20avec%20mes%20modif/suite%20%2B%20ch/WhatsApp_Image_2024-07-04_at_13.01.27_xejwdx.jpg',
      alt: 'SB chambres vue piscine et jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd3_egd2ie.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd3_egd2ie.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd2_iivciu.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd2_iivciu.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd1_x61hpv.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd1_x61hpv.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg',
      alt: 'Petit-déjenuer',
    }

    
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};


 
  return (
    <div >
       {/* Container pour les toasts */}
       <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <Navbar/>

                    <LightGallery
                        onInit={onInit}
                        speed={100}
                        plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                    >
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah-_owhnzo.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>
                            <MDBCardBody>
                              <MDBCardTitle>ROCK THE KASBAH</MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de cette villa en cliquant sur la photo
                              </MDBCardText>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      
                  {carouselPhotos.map((image, index) => {
                        return (
                          
                          <a href={image.src} key={index} data-sub-html={image.alt} data-thumb={image.thumbnail}>
                          <img src={image.thumbnail} alt={image.alt} style={{ display: 'none' }} />
                        </a>
 
                        )
                    })}

                  </LightGallery>

                  <MDBListGroupItem noBorders color='primary' className='px-3 mb-2 rounded-3' style={{margin:'50px',display:'flex',justifyContent:'center'}}>
                  Enregistrement de votre demande de réservation
                  </MDBListGroupItem>    

                  <div style={{margin:'50px',display:'flex',justifyContent:'center',flexWrap:'wrap', alignItems:'center', gap:'10px', color:'GrayText'}}>
                  Merci de saisir le code promo : 
                  <MDBInput name='codepromo' value={codepromo} wrapperClass='mb-4' id='form6Example70' label='Code Promo' type='text' onChange={(e)=>setCodepro(e.target.value) } /> 
                
                  <MDBIcon far icon="hand-point-right" /> <div style={{color:'red'}}>(En rouge dates indisponibles)</div>
                
                  {/* nous pouvons importé la component calandrer mais il faut resoudre le probleme de l'envois de props car nous voulons recupérer des valeurs depuis component */}

                  
                  {/* Configueprovider est pour personnaliser le coleur des date disable en rouge dans rangepicker */}
                  <ConfigProvider
                      theme={{
                        components: {
                          DatePicker: {
                            cellBgDisabled: 'red',
                            cellActiveWithRangeBg: 'green',
                            
                          },
                          
                        },
                      }}
                    >

                  <Space direction="vertical" size={12}>

                    <RangePicker 
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    
                    />
                    
                  </Space>
                  </ConfigProvider>
              </div>

              <hr className="hr hr-blurry" />
                  <MDBBadge color='success' light style={{margin:'10px',display:'flex',justifyContent:'center'}}>Le prix de la période sélectionnée selon la saison sans options est: {price}€</MDBBadge>
              <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                          <form style={{margin:'20px'}} onSubmit={handleSubmit}>
                            Merci de choisir le type de votre logement :
                          <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='Entièrevilla'
                                label='Villa Entière ROCK THE KASBAH '
                                value="Villa Entière ROCK THE KASBAH"
                                onChange={(e)=>setCheckedType(e.target.value)}
                              />
                              <MDBCheckbox
                                id='chambrehote'
                                wrapperClass='d-flex justify-content-center mb-4'
                                label='Chambre hôtes ROCK THE KASBAH '
                                value='Chambre hôtes ROCK THE KASBAH '
                                onChange={(e)=>setCheckedType(e.target.value)}                                
                              />
                              <MDBRow className='mb-4'>
                                <MDBCol>
                                  <MDBInput name='prenom' id='form6Example1' label='Prénom' type='text' onChange={(e)=>setPrenom(e.target.value)}/>
                                  
                                </MDBCol>
                                <MDBCol>
                                  <MDBInput name='nom' id='form6Example2' label='Nom' type='text' onChange={(e)=>setNom(e.target.value)}/>
                                </MDBCol>
                              </MDBRow>
                              

                              <MDBInput name='codepromo' wrapperClass='mb-4' id='form6Example70' label='Code Promo' type='text' onChange={(e)=>setCodepro(e.target.value) } />
                              <MDBInput name='nbpersonne' wrapperClass='mb-4' id='form6Example3' label='Nombre de personne' type='number' onChange={(e)=>setNbpersonne(e.target.value) } max={8} min={1}/> 
                              {/* <MDBBtn className='mb-4'  disabled block color='success'>
                              Le prix total de logement sans options est: {totalprix}€
                              </MDBBtn>                           */}
                              <MDBInput name='adresse' wrapperClass='mb-4' id='form6Example4' label='Adresse (N°, Rue, Ville, Pays)' type='text' onChange={(e)=>setAdresse(e.target.value)}/>
                              <MDBInput name='email' wrapperClass='mb-4' type='email' id='form6Example5' label='Email' onChange={(e)=>setEmail(e.target.value)}/>
                              <MDBInput name='telephone' wrapperClass='mb-4' type='tel' id='form6Example6' label='Télephone ' onChange={(e)=>setTelephone(e.target.value)}/>

                              <MDBInput name='commentaire' wrapperClass='mb-4' textarea id='form6Example7' rows={4} label='Commentaires ou demandes particuliéres' onChange={(e)=>setCommentaire(e.target.value)}/>

                              Options choisies: 
                                  {/* nous pouvons importé la component optiontarifs mais il faut resoudre le probleme de l'envois de props car nous voulons recupérer des valeurs depuis component */}
                                  
                                  <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',margin:'15px'}}>
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example1'
                                label='Accueil Aéroport et transfert villa (50€)'
                                value='Accueil Aéroport et transfert villa (50€)'
                                onChange={(e)=>setCheckedopt1(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example2'
                                label='Femme de ménage 4 heures (39€)'
                                value='Femme de ménage 4 heures (39€)'
                                onChange={(e)=>setCheckedopt2(e.target.value)} 
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example3'
                                label='Cuisinière à domicile  par repas et hors produits 4 heures (39€)'
                                value='Cuisinière à domicile  par repas et hors produits 4 heures (39€)'
                                onChange={(e)=>setCheckedopt3(e.target.value)} 
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example4'
                                label='Petit déjeuner chambre hôtes par personne (9€) '
                                value='Petit déjeuner chambre hôtes par personne (9€) '
                                onChange={(e)=>setCheckedopt4(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example5'
                                label='Service de courses à domicile pour alimentation et boissons, hors produits (29€)'
                                value='Service de courses à domicile pour alimentation et boissons, hors produits (29€)'
                                onChange={(e)=>setCheckedopt5(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example6'
                                label='Serviette de piscine  avec chaussons (par personne) offerts en fin de séjour (25€)'
                                value='Serviette de piscine  avec chaussons (par personne) offerts en fin de séjour (25€)'
                                onChange={(e)=>setCheckedopt6(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example7'
                                label='Sac de plage avec serviette ( par personne) offerts en fin de séjour (35€)'
                                value='Sac de plage avec serviette ( par personne) offerts en fin de séjour (35€)'
                                onChange={(e)=>setCheckedopt7(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example8'
                                label='Service pressing sur demande avec prise en charge dans votre villa, hors prestation du pressing (15€)'
                                value='Service pressing sur demande avec prise en charge dans votre villa, hors prestation du pressing (15€)'
                                onChange={(e)=>setCheckedopt8(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example9'
                                label='Massage à domicile 60 minutes (39€)'
                                value='Massage à domicile 60 minutes (39€)'
                                onChange={(e)=>setCheckedopt9(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example10'
                                label='Voiture de location ( 5 personnes) pour  7 jours (250€ à 400€)'
                                value='Voiture de location ( 5 personnes) pour  7 jours (250€ à 400€)'
                                onChange={(e)=>setCheckedopt10(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example11'
                                label='Chauffeur personnel pour location voiture et par jour 8 heures (70€)'
                                value='Chauffeur personnel pour location voiture et par jour 8 heures (70€)'
                                onChange={(e)=>setCheckedopt11(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example12'
                                label='Baby-sitting de 4 heures (par tranche de 3 enfants) (40€)'
                                value='Baby-sitting de 4 heures (par tranche de 3 enfants) (40€)'
                                onChange={(e)=>setCheckedopt12(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example13'
                                label='Peignoirs individualisés offerts en fin de séjour (35€)'
                                value='Peignoirs individualisés offerts en fin de séjour (35€)'
                                onChange={(e)=>setCheckedopt13(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example14'
                                label='Organisation activités : équitation, dromadaire,  calèche, jet-ski, kyte-surf, balnéothérapie, sortie en mer, excursions, etc. (Sur devis)'
                                value='Organisation activités : équitation, dromadaire,  calèche, jet-ski, kyte-surf, balnéothérapie, sortie en mer, excursions, etc. (Sur devis)'
                                onChange={(e)=>setCheckedopt14(e.target.value)}
                              />
                            </div>
                              
                              <MDBBtn className='mb-4' type='submit' block>
                                Enoyez votre demande de réservation
                              </MDBBtn>
                            </form>

                            <MDBCardBody>

                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                  <Footer/>
    </div>
  )
}

export default Rock