import React from 'react'
import image from '../images/notfound.gif'

const Notfound = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <h1>404 - Page Not Found</h1>
      
      <img src={image} alt="404" style={{ width: '100%', maxWidth: '1000px', height: 'auto' }} />
    </div>
  )
}

export default Notfound