import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'
import React from 'react'

const CarouselRTK = () => {
  return (
    <div>
      <MDBCarousel showControls fade >
      <MDBCarouselItem itemId={1} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah--2_og8c8j.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={2}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah-_owhnzo.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah--3_dmo1hr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={4}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007071/rock/the_rock_kasbah-0097_gpijhn.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={5}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah-0094_jywzgc.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={6}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah-0096_qn5x03.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={7}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah-0093_cn7j3r.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={8}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720007070/rock/the_rock_kasbah-0056_ujlcaf.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={9}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/suite1_sjrtcl.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={10}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332933/rock/cham_opwoty.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={11}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332933/rock/ch_hptk8q.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={12}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/sb2_bno5zw.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={13}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/sb1_fm5hwm.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={14}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332936/rock/sb_vqxvjh.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={15}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332937/rock/sol_cuis_fdjgx4.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={16}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332934/rock/meuble_jard_a6okfo.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={17}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714640990/rock/cuisine_qjigy5.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={18} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715775991/rock/vueface_xphjk9.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={19}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715775995/rock/vuf_wdcamh.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={20}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721643471/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.11_2_whvlpu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={21}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721643467/Rock%20avec%20mes%20modif/ext/WhatsApp_Image_2024-07-04_at_09.51.09_butudi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={22}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641281/rock/slon_cuinse_jqedoh.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={23}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641065/rock/deco11_wjyvaq.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={24}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1714641112/rock/deco_1_zh9cbn.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={25}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1715332938/rock/chamb_jitixx.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={26}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={27}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={28}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd1_x61hpv.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={29}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd3_egd2ie.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={30}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={31}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd2_iivciu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            
          </MDBCarousel>
    </div>
  )
}

export default CarouselRTK