import React, { useState } from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
  MDBTypography,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

import { MdBedroomParent, MdBedroomChild  } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { FiUserCheck } from "react-icons/fi";
import CarouselAzale from './CarouselImage/CarouselAzale';
import CarouselBougainv from './CarouselImage/CarouselBougainv';
import CarouselCasa from './CarouselImage/CarouselCasa';
import GeneraterPrix from './GenerateurPrix/GeneraterPrix';
// import image from '../images/promocode.jpg'




const VillasDjerbien = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModal1, setBasicModal1] = useState(false);
  const [basicModal2, setBasicModal2] = useState(false);
  
  

  const toggleOpen = () => setBasicModal(!basicModal);
  const toggleOpen1 = () => setBasicModal1(!basicModal1);
  const toggleOpen2 = () => setBasicModal2(!basicModal2);
  
  const keyframesStyleVD = `
  @keyframes blinkingVD {
    0% { color: blue; }
    50% { color: aliceblue; }
    75% { color: lightskyblue; }
    100% { color: blue; }
  }
  @keyframes vibratingVD {
    0%, 100% { transform: translate(0); }
    25% { transform: translate(-2px, 2px); }
    50% { transform: translate(2px, -2px); }
    75% { transform: translate(-2px, -2px); }
  }
`;

const TextStyleVD = {
  animation: 'blinkingVD 10.5s infinite'
};
// const CodePromoVD = {
//   animation: 'blinkingVD 1.5s infinite, vibratingVD 0.3s infinite'
// };
 

   
  return (
    <div>
            <style>
              {keyframesStyleVD}
            </style>
      <MDBTypography tag='div' className='display-2 pb-3 mb-3 border-bottom' style={{padding:'70px',justifyContent:'center',display:'flex', ...TextStyleVD}}>DJERBA AUTREMENT </MDBTypography>
      <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{paddingLeft:'10px', paddingRight:'10px'}}>
      <MDBCol>
        <MDBCard className='h-100'  >
          
          {/* importation carousel azale depuis galerie azale */}
          <CarouselAzale/>
              

          <MDBCardBody>
            <MDBCardTitle>Villa Azalée 
              {/* <Link to='/azalee'><img src={image} alt='' style={{width:'100px', height:'100px', ...CodePromoVD}}/></Link>  */}
              </MDBCardTitle>
            {/* <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 19€ /<FiUserCheck /> </MDBCardTitle> */}
            <MDBCardTitle><FaUserAlt />=8  <MdBedroomParent />=3 <MdBedroomChild />=2 </MDBCardTitle>
            
            <MDBCardText>
            Avec ses 4 suites parentales avec salle de bain privative, sa cuisine de plus de 30 m², ses salons, ses terrasses, ses deux piscines (adultes et enfants) et son magnifique jardin, vous passerez des vacances exceptionnelles. Un ressourcement bénéfique….
            </MDBCardText>
            <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
            <Link to='/azalee'><MDBBtn className='me-2' >Dates disponibles et / ou réserver</MDBBtn></Link>
            {/* importation generateur prix */}
            <GeneraterPrix/>
            
            <MDBBtn onClick={toggleOpen} className='me-2' color='secondary'>Détails</MDBBtn>
            </div>
            <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Azalée 
                <MDBCardTitle style={{color:'gray',fontSize:'25px'}}>
                
                <FaUserAlt />=8  <MdBedroomParent />=3 <MdBedroomChild />=2
                </MDBCardTitle>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody> Villa de 400 m² dans un parc de 2 000 m2 et plus de 300 m2 de terrasses arborées, la villa Azalées est idéale pour partager des moments entre famille ou amis en toute quiétude et sérénité.
            <hr className="hr hr-blurry" />
            A votre dispositions deux piscines, sans vis-à-vis, dont une dédiée aux enfants à l’ombre d’un palmier majestueux
            Les nombreuses terrasses ombragées ou ensoleillées sont aménagées avec transats, parasols, tables et fauteuils confortables pour profiter du calme et de la douceur de vivre de Djerba à chaque saison. Invitation au farniente ou au bronzage loin de la foule et en toute intimité.
            <hr className="hr hr-blurry" />
            Pour vos repas en famille ou entre amis un coin BBQ et une grande table ombragée sous une paillote devant la piscine vous permettront de profiter de bons repas faits maison ou réalisés par notre cuisinière (sur demande) La cuisine, gigantesque, est entièrement équipée avec un agréable coin salle à manger et une porte arrière donnant sur une terrasse. Plusieurs espaces, dans la propriété, vous permettent de profiter de la détente et du calme en toute intimité.
            <hr className="hr hr-blurry" />
            Au rez-de-chaussée vous trouvez une suite à 2 lits simples avec salle de bain, un double salon spacieux et une salle à manger supplémentaire donnant sur plusieurs terrasses aménagées et sur la piscine.
            A l’étage se trouvent trois suites parentales dont une avec terrasse.
            <hr className="hr hr-blurry" />
            Vous avez également accès à une grande terrasse sur le toit avec espace massage et vue sur la mer et la campagne djerbienne.
            Notre unique souhait : votre satisfaction et un séjour inoubliable dans le calme et le confort absolu.
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn color='secondary'><Link to='/azalee'>Consulter les dates disponibles et / ou réserver</Link></MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          
          {/* importation carousel azale depuis galerie azale */}
          <CarouselBougainv/>


          <MDBCardBody>
            <MDBCardTitle>Villa Bougainvilliers 
              {/* <Link to='/bougainvillier'><img src={image} alt='' style={{width:'100px', height:'100px', ...CodePromoVD}}/></Link> */}
              </MDBCardTitle>
            {/* <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 45€ /<FiUserCheck /> </MDBCardTitle> */}
            <MDBCardTitle><FaUserAlt />=6  <MdBedroomParent />=2 <MdBedroomChild />=2</MDBCardTitle>
            
            <MDBCardText>Avec une surface de plus de 180 m², cette villa est composée de 2 suites dont une parentale avec salle de bain, une chambre avec 2 lits d’ 1 personne, 2 salles de bain séparées, une cuisine parfaitement équipée, plusieurs salons intérieurs et extérieurs, de nombreuses terrasses, une grande piscine et un magnifique jardin clos de murs où vous passerez des vacances inoubliables.
            </MDBCardText>
            <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
            <Link to='/bougainvillier'><MDBBtn className='me-2' >Dates disponibles et / ou réserver</MDBBtn></Link>
            {/* importation generateur prix */}
            <GeneraterPrix/>
            
            <MDBBtn onClick={toggleOpen1} className='me-2' color='secondary'>Détails</MDBBtn>
            </div>
            <MDBModal open={basicModal1} setOpen={setBasicModal1} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Bougainvilliers
              <MDBCardTitle style={{color:'gray',fontSize:'25px'}}>
              <FaUserAlt />=6  <MdBedroomParent />=2 <MdBedroomChild />=2
                </MDBCardTitle>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen1}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>Votre villa Bougainvilliers a été choisie pour son architecture typique adaptée à des vacances tranquilles et située dans le quartier de Tezdaïne à seulement 1 kilomètre des plus belles plages de Djerba.
            <hr className="hr hr-blurry" />
              A votre disposition, une piscine privée totalement équipée avec transats, parasols, tables et fauteuils.
              Une cuisine entièrement équipée vous attend pour mijoter vos spécialités culinaires, mais vous pouvez également bénéficier d’un cuisinier si vous le souhaitez.
              <hr className="hr hr-blurry" />
              Un vaste séjour, typiquement meublé, vous offre un confort absolu pour vos moments de détente et de convivialité.
              Différentes terrasses ombragées ou ensoleillées en fonction de vos envies vous invitent au farniente ou au bronzage
              Votre villa Bougainvilliers, au charme particulier, pour vous permettre de vivre des vacances inoubliables.

            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen1}>
                Close
              </MDBBtn>
              <MDBBtn color='secondary'><Link to='/bougainvillier'>Consulter les dates disponibles et / ou réserver</Link></MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      <MDBCol>
        <MDBCard className='h-100'>
          
          {/* importation carousel azale depuis galerie azale */}
          <CarouselCasa/>

          <MDBCardBody>
            <MDBCardTitle>Villa Casablanca 
              {/* <Link to='/jasmin'><img src={image} alt='' style={{width:'100px', height:'100px', ...CodePromoVD}}/></Link> */}
              </MDBCardTitle>
            {/* <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 26€ /<FiUserCheck /> </MDBCardTitle> */}
            <MDBCardTitle><FaUserAlt />=8  <MdBedroomParent />=2 <MdBedroomChild />=4</MDBCardTitle>
            <MDBCardText>
            Avec sa suite parentale, ses deux chambres avec salles de bains, sa chambre avec deux lits superposés pour enfants (90x190), ses deux salons intérieurs, salle à manger, cuisine équipée, ses 4 salons extérieurs, ses terrasses de plus de 300 m², sa piscine et son magnifique jardin, vous passerez des vacances de rêve dans un parc d’un hectare au calme.
            </MDBCardText>
            <div style={{display:'flex',flexWrap:'wrap',gap:'10px'}}>
            <Link to='/jasmin'><MDBBtn className='me-2' >Dates disponibles et / ou réserver</MDBBtn></Link>
            {/* importation generateur prix */}
            <GeneraterPrix/>
            
            <MDBBtn onClick={toggleOpen2} className='me-2' color='secondary'>Détails</MDBBtn>
            </div>
            <MDBModal open={basicModal2} setOpen={setBasicModal2} tabIndex='-1'>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Casablanca
              <MDBCardTitle style={{color:'gray',fontSize:'25px'}}>
              <FaUserAlt />=8  <MdBedroomParent />=2 <MdBedroomChild />=4
                </MDBCardTitle>
              </MDBModalTitle>
              <MDBBtn className='btn-close' color='none' onClick={toggleOpen2}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>            
            A Tezdaïne, au cœur d’une palmeraie sauvage et clôturée, à environ 1 km des plus belles plages de Djerba, se situe Casablanca  votre villa de luxe pour les amoureux….
            <hr className="hr hr-blurry" />
            Trois chambres avec un grand lit de 160 cm, une chambre avec 2 lits superposés de 90 cm, 2 salles de bains, deux cabines de douche indépendantes et 3 WC sont à votre disposition pour votre plus grand confort.
            La cuisine est entièrement équipée, ouvre sur une terrasse pour bénéficier d’une magnifique vue sur le coucher de soleil et d’une piscine sans vis-à-vis avec plusieurs coins salons et paillote.
            <hr className="hr hr-blurry" />
            Deux salles à manger typiquement aménagés vous attendent pour des soirées entre amis.
            Cinq terrasses de plus de 300 m² sont à votre disposition : deux aux rez-de-chaussée, 2 au premier étage et une sur le toit avec une vue exceptionnelle sur la mer et la campagne djerbienne.
            <hr className="hr hr-blurry" />
            A votre disposition, une piscine privée totalement équipée avec transats, parasols, tables et fauteuils, salle à manger couverte, salons et paillote en bois et chaumes dans un magnifique écrin de verdure. Tout votre séjour s’organisera autour de cette piscine, à n’en pas douter, une des plus agréables de Djerba. Notre cuisine entièrement équipée vous attend pour mijoter vos spécialités culinaires, mais vous pouvez également bénéficier d’un cuisinier si vous le souhaitez.
            <hr className="hr hr-blurry" />
            Le jardin d’un hectare permet de bénéficier d’un calme absolu, de la tranquillité et d’un charme hors du commun.
            <hr className="hr hr-blurry" />
            Le luxe ultime dans plus de 400 m² de luxe et volupté…
            Casablanca : une idée du luxe pour des vacances de pur bonheur.
            <hr className="hr hr-blurry" />
            Accessible aussi en chambre d’hôtes avec petit-déjeuner pour 2 adultes (Chambre Jardin-SDB -WC ou Chambre Mer SDB -WC) ou 2 adultes et 2 enfants (Chambre Mer SDB-WC et Chambre Enfants) ou 4 adultes (Chambre Mer SDB -WC et Chambre Piscine) ou 4 adultes et deux enfants (Chambre Mer SDB-WC, Chambre Piscine et Chambre Enfants)
            

            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color='secondary' onClick={toggleOpen2}>
                Close
              </MDBBtn>
              <MDBBtn color='secondary'><Link to='/jasmin'>Consulter les dates disponibles et / ou réserver</Link></MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
      
    </MDBRow>
    </div>
  )
}

export default VillasDjerbien