import React from 'react'
import './book.css'
import { MDBCard, MDBCardBody} from 'mdb-react-ui-kit'

const BookCCM = () => {
  return (
    <div>
      <MDBCard alignment='center'>
      <MDBCardBody>
      <div id="wrapper">
    <div id="container">

        <section class="open-book">
            <header>
                
            </header>
            <article>
                <h2 class="chapter-title">Comment réserver EDEN RESORT ?</h2>
                <p>
                Personnalisez entièrement votre location de vacances.
                </p>
                
                <hr className="hr hr-blurry" />
                    
                    <dd>
                    <ol>
                    <li>
                    Choisissez votre villa ou notre Yacht RITA parmi notre sélection dans ''Nos villas et bateaux '' ainsi que le mode de réservation choisie : location ou chambre d’hôtes.
                    </li>
                    <li>
                    Choisissez les options souhaitées.
                    </li>
                    <li>
                    Choisissez vos dates de séjour dans le calendrier en haut selon les disponibilités.
                    </li>
                    <li>
                    Remplissez le formulaire le plus précisément possible et envoyez-le.
                    </li>  
                    <li>
                    Nous vous répondrons dans les 24 heures par mail ou par téléphone.
                    </li>                
                </ol>
                    </dd>
                <hr className="hr hr-blurry" />
                
            </article>
            <footer>
                <ol id="page-numbers">
                    <li>1</li>
                    <li>2</li>
                </ol>
            </footer>
        </section>

    </div>
</div>
</MDBCardBody>
</MDBCard>
    </div>
  )
}

export default BookCCM