import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'
import React from 'react'

const CarouselBougainv = () => {
  return (
    <div>
       <MDBCarousel showControls fade >
            <MDBCarouselItem itemId={1} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546809/bougainvillier/piscine_lu63zy.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={2}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546807/bougainvillier/piscine_vao4hk.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546805/bougainvillier/salons_nyrxit.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={4}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546802/bougainvillier/salon_t7cfbl.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={5}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546799/bougainvillier/cuisine_zddxws.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={6}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546806/bougainvillier/cuisine_uygpei.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={7}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546792/bougainvillier/chambre_2_koyste.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={8}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546796/bougainvillier/chambre2_squmzj.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={9}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546798/bougainvillier/chambre_3_dvmw5e.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={10}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546794/bougainvillier/chambre_1_hrsfw2.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={11}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711546803/bougainvillier/parking_trqlup.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={12}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={13}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={14}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd1_x61hpv.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={15}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd3_egd2ie.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={16}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={17}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd2_iivciu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
          </MDBCarousel>
    </div>
  )
}

export default CarouselBougainv