import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'
import React from 'react'

const CarouselCasa = () => {
  return (
    <div>
      <MDBCarousel showControls fade >
            <MDBCarouselItem itemId={1} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142534/jasmin/DSC_0063_ajn37u.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={2}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142531/jasmin/DSC_0074_vfb5fr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142532/jasmin/DSC_0100_g5vq68.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={4}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142530/jasmin/DSC_0179_x8v7fe.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={5}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142531/jasmin/DSC_0156_xn3vta.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={6}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142527/jasmin/DSC_0223_hjhz1u.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={7}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142526/jasmin/DSC_0061_pfyz8l.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={8}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142524/jasmin/DSC_0053_n2sbt2.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={9}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142520/jasmin/DSC_0202_a0k1u0.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={10}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142527/jasmin/DSC_0205_fimjkv.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={11}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142524/jasmin/DSC_0151_rpton7.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={12} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142523/jasmin/DSC_0146_gnuqzk.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={13}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142519/jasmin/DSC_0152_wptyhx.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={14}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142527/jasmin/DSC_0214_hg6vxh.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={15}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142519/jasmin/DSC_0238_pziodl.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={16}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142520/jasmin/DSC_0239_kj6s5d.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={17}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142519/jasmin/DSC_0226_fu00ex.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={20} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547478/jasmin/piscine3_djscu8.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={21}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547422/jasmin/piscine_enfant_luaecn.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={22}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547416/jasmin/piscine_enfant2_tn0d3i.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={23}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547420/jasmin/table_terasse1_p2olwg.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={24}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547411/jasmin/payout3_om6b9c.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={25}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547424/jasmin/cuisine5_bl1wez.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={26}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547382/jasmin/cuisine1_u51hh7.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={27}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547387/jasmin/cuisine4_cyvbhu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={28}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547418/jasmin/baignoir1_neu6ud.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={29}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547396/jasmin/lavbo_blycgd.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={30}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547392/jasmin/saguia_gzrodi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={31}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={32}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={33}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd1_x61hpv.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={34}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd3_egd2ie.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={35}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={36}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd2_iivciu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
          </MDBCarousel>
    </div>
  )
}

export default CarouselCasa