
import React from 'react';
import image from '../images/accessdenied.png'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';



const AccesDenied = () => {

  const user = useSelector(state => state.userReducer.user);
  
  return (
    <div>
      
    
    <MDBCard className='mb-3'>
      <MDBCardTitle style={{color:'red', display:'flex',justifyContent:'center',paddingTop:'50px'}}>
      Ce compte "{user.typeaccount}" n'a pas le droit d'accéder à cette page.
      </MDBCardTitle>
      <Link to='/dashbord' style={{color:'red', display:'flex',justifyContent:'center',paddingTop:'50px'}}><MDBBtn >Retour</MDBBtn></Link>
 
        <MDBCardBody style={{display:'flex',justifyContent:'center'}}>
        <MDBCardImage  src={image} alt='...'  />
        </MDBCardBody>
      </MDBCard>
       
    </div>
  )
}

export default AccesDenied