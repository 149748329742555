import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'
import React from 'react'

const CarouselTralala = () => {
  return (
    <div>
      <MDBCarousel showControls fade >
            <MDBCarouselItem itemId={1} >
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657991/tralala/vue_piscine_soir3_bngnmd.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={2}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657987/tralala/vue_piscine_soir_lotvrg.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={3}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657986/tralala/piscine_soir_bpdxxp.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={4}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657990/tralala/chambre_1_suite_1_dgo1ut.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={5}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657988/tralala/chambre_1_lr836r.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={6}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657994/tralala/chambre_2_vue_piscine_mxeeqs.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={7}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657996/tralala/douche_honvlk.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={8}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1712657998/tralala/pause_caf%C3%A9_kmou5d.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={9}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={10}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={11}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd1_x61hpv.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={12}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd3_egd2ie.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={13}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={14}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd2_iivciu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            
          </MDBCarousel>
    </div>
  )
}

export default CarouselTralala