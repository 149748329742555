
import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import logo from '../images/logoER.png'

const Navbar = () => {
  const [openNavCentred, setOpenNavCentred] = useState(false);
  return (
    <div>
      <header role="banner">
  <img id="logo-main" src={logo} width="200" alt="Logo Thing main logo" />

  
</header>
<MDBNavbar expand='lg' light bgColor='light'>
      <MDBContainer fluid >
        <MDBNavbarToggler 
          type='button'
          data-target='#navbarCenteredExample'
          aria-controls='navbarCenteredExample'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenNavCentred(!openNavCentred)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openNavCentred} id='navbarCenteredExample' style={{justifyContent:'center'}}>
          <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-1' style={{gap:'20px'}} >

            <MDBNavbarItem>
              <MDBNavbarLink href='/'>
                Accueil
              </MDBNavbarLink>
            </MDBNavbarItem>
            {/* <MDBNavbarItem>
              <MDBNavbarLink href='/dispo'>Disponibilté</MDBNavbarLink>
            </MDBNavbarItem> */}
            <MDBNavbarItem>
              <MDBNavbarLink href='/nosvilla'>Nos villas</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/commentsamarche'>Comment ça marche</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/tarif'>Tarif</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/reglement'>Règlement</MDBNavbarLink>
            </MDBNavbarItem>
            {/* <MDBNavbarItem>
              <MDBNavbarLink href='/reglement'>Achat Villa</MDBNavbarLink>
            </MDBNavbarItem> */}
            <MDBNavbarItem>
              <MDBNavbarLink href='/showroom'>Villas Showroom</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/avis'>Avis</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/contact'>Contactez-nous</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/nospartenaire'>Nos Partenaires</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/connecte'>Connecter</MDBNavbarLink>
            </MDBNavbarItem>
            
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
      
    </div>
  )
}

export default Navbar