import { MDBContainer, MDBNavbar } from 'mdb-react-ui-kit'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import ShowroomRTK from '../components/Showroom/ShowroomRTK'

const VillasShowroom = () => {
  return (
    <div>
            <Helmet>
              <title>Villas Showroom</title>
              <meta name="description" content="Villas Showroom"/>
            </Helmet>
      <header>
      <MDBNavbar expand='lg' light bgColor='white' sticky>
        <MDBContainer fluid style={{justifyContent:'center'}}>
          
          {/* Importation de narbar component */}
          <Navbar/>
          
        </MDBContainer>
      </MDBNavbar>

      {/* Importation des component */}
      
      
      <ShowroomRTK/>
      <Footer/>
      

    </header>
      
    </div>
  )
}

export default VillasShowroom