import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'
import React from 'react'

const CarouselAzale = () => {
  return (
    <div>
  <MDBCarousel showControls fade>
    <MDBCarouselItem itemId={1}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696151/Azal%C3%A9%20avec%20mes%20modif/DSC_0808_onfkn0.jpg' className='d-block w-100' alt='Le lever du Soleil' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={2}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696161/Azal%C3%A9%20avec%20mes%20modif/DSC_0619_lkiv2d.jpg' className='d-block w-100' alt='Jardin' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={3}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696160/Azal%C3%A9%20avec%20mes%20modif/DSC_0725_e2zy59.jpg' className='d-block w-100' alt='Parking' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={4}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0719_n90epj.jpg' className='d-block w-100' alt='Terrasse vue jardin' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={5}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0721_xe1kpn.jpg' className='d-block w-100' alt='Entrée principale' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={6}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0708_es9cmp.jpg' className='d-block w-100' alt='Petit dejeuner piscine' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={7}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696155/Azal%C3%A9%20avec%20mes%20modif/DSC_0629_jr1nve.jpg' className='d-block w-100' alt='Porte entrée' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={8}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0565_iloyiq.jpg' className='d-block w-100' alt='Suite la fidèle' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={9}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696144/Azal%C3%A9%20avec%20mes%20modif/DSC_0559_laukrt.jpg' className='d-block w-100' alt='Douche jaccozi Suite la fidèle' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={10}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0581_tdeonu.jpg' className='d-block w-100' alt='Suite la douce' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={11}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0579_uq1pkt.jpg' className='d-block w-100' alt='Suite la douce' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={12}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696145/Azal%C3%A9%20avec%20mes%20modif/DSC_0572_dxmu8m.jpg' className='d-block w-100' alt='SB Suite la douce' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={13}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696147/Azal%C3%A9%20avec%20mes%20modif/DSC_0593_cqadfz.jpg' className='d-block w-100' alt='Suite la belle' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={14}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696146/Azal%C3%A9%20avec%20mes%20modif/DSC_0598_scqwax.jpg' className='d-block w-100' alt='Suite la belle' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={15}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696147/Azal%C3%A9%20avec%20mes%20modif/DSC_0592_pum1mo.jpg' className='d-block w-100' alt='Suite la belle' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={16}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696148/Azal%C3%A9%20avec%20mes%20modif/DSC_0588_mcjbb2.jpg' className='d-block w-100' alt='SB Suite la belle' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={17}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696159/Azal%C3%A9%20avec%20mes%20modif/DSC_0687_iwzg68.jpg' className='d-block w-100' alt='Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={18}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696158/Azal%C3%A9%20avec%20mes%20modif/DSC_0671_qvznft.jpg' className='d-block w-100' alt='Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={19}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719161423/azal%C3%A9e/DSC_0534_drsccp.jpg' className='d-block w-100' alt='SB Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={20}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696149/Azal%C3%A9%20avec%20mes%20modif/DSC_0537_asrc8w.jpg' className='d-block w-100' alt='SB Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={21}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696149/Azal%C3%A9%20avec%20mes%20modif/DSC_0538_ytjxpn.jpg' className='d-block w-100' alt='SB Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={22}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696154/Azal%C3%A9%20avec%20mes%20modif/DSC_0622_qsjy9q.jpg' className='d-block w-100' alt='SB Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={23}>
      <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1720696154/Azal%C3%A9%20avec%20mes%20modif/DSC_0556_jmz3wb.jpg' className='d-block w-100' alt='SB Chambre RDC' />
    </MDBCarouselItem>
    <MDBCarouselItem itemId={24}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={25}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={26}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd1_x61hpv.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={27}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd3_egd2ie.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={28}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
            <MDBCarouselItem itemId={29}>
              <img src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1721639048/Petit%20dej/ptd2_iivciu.jpg' className='d-block w-100' alt='...' />
            </MDBCarouselItem>
    
  </MDBCarousel>
</div>

  )
}

export default CarouselAzale