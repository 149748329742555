import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBInput, MDBNavbar, MDBRow } from 'mdb-react-ui-kit'


import Footer from '../components/Footer'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../redux/actions/userActions'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'



const Connecter = () => {
    
  const [user,setUser]=useState({})
  const dispatch=useDispatch()
  const navigate =useNavigate()
  const findUser=useSelector(state=>state.userReducer.user)
     
  // on recupere le contenu des champs input dans le form
  const handleUser=(e)=>
      { 
      setUser({...user,[e.target.name]:e.target.value})
      
      }

      // pour appeler useraction et dispatche login dans onclick sur le button

      const handleSubmit = async (event) => {

          event.preventDefault();
          await dispatch(login(user))

      };

      // pour faire la controle et naviguer a la page demande 
      useEffect(()=>{
  
        if(findUser!=null){
          
          navigate("/dashbord")
          
      };
    },)
  
  return (
    <div>
                  <Helmet>
                    <title>Contacter</title>
                    <meta name="description" content="Contacter"/>
                  </Helmet>

     
          <header>
                <MDBNavbar expand='lg' light bgColor='white' sticky>
                  <MDBContainer fluid style={{justifyContent:'center'}}>
                    
                     {/* Importation de narbar component  */}
                    <Navbar/>

                  </MDBContainer>
                </MDBNavbar>

                {/* Contenu de la page contactez-nous en dissous en ferme avec </header */}
                
 

      <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{display:'flex',justifyContent:'center', paddingTop:'100px'}}>
      <MDBCol>
        <MDBCard className='h-100'>
          
          <MDBCardBody >
            <MDBCardTitle style={{display:'flex',justifyContent:'center'}}>Connexion</MDBCardTitle>
            
            <MDBCardText>
              <form style={{paddingTop:'50px'}}  onSubmit={handleSubmit}>
                <MDBInput name='email' type='email' wrapperClass='mb-4' label='Votre E-mail' onChange={(e)=>handleUser(e)}/>
                <MDBInput name='password' type='password' wrapperClass='mb-4' label='Mot de passe' onChange={(e)=>handleUser(e)}/>
                
                <MDBBtn className='mb-4' onClick={handleSubmit}>
                Connecter
                </MDBBtn>
                
              </form>
              
            </MDBCardText>
          </MDBCardBody>
          
        </MDBCard>
      </MDBCol>

      
      
      
    </MDBRow>


    <Footer/>
      
    </header>
    </div>
  )
}

export default Connecter