import React from 'react'
import './book.css'
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit'

const BookReglement = () => {
  return (
    <div>
      <MDBCard alignment='center'>
      <MDBCardBody>
      <div id="wrapper">
    <div id="container">

        <section class="open-book">
            <header>
                
            </header>
            <article>
                <h2 class="chapter-title">Règlement</h2>
                <p>
                En raison de la situation privilégiée de nos villas à l'écart des hôtels, night-clubs et autres sources de pollution sonore, nous exigeons de nos clients un respect strict de ce règlement :
                </p>
                
                <hr className="hr hr-blurry" />
                    
                    <dd>
                    <ol>
                    <li>
                    Pas de musique en extérieur.
                    </li>
                    <li>
                    Veiller au respect de vos voisins, en particulier après 22 heures et au long de la journée en particulier autour des piscines.
                    </li>
                    <li>
                    Les animaux de compagnie, hélas, ne peuvent être acceptés.
                    </li>
                                    
                </ol>
                    </dd>
                <hr className="hr hr-blurry" />
                
            </article>
            <footer>
                <ol id="page-numbers">
                    <li>1</li>
                    <li>2</li>
                </ol>
            </footer>
        </section>

    </div>
</div>
</MDBCardBody>
</MDBCard>
    </div>
  )
}

export default BookReglement