
import React from 'react';
import Footer from '../components/Footer';
import SlideAcceuil from '../components/SlideAcceuil';
import { Helmet } from 'react-helmet-async';




const Acceuil = () => {
  
  return (
    <div>
      <Helmet>
        <title>Acceuil</title>
        <meta name="description" content="Page d'acceuil"/>
      </Helmet>
      
      <SlideAcceuil/>
      <Footer/>
      
    </div>
  )
}

export default Acceuil