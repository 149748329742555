import React from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'


import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';






const CasablancaSearch = () => {





  



  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1719142534/jasmin/DSC_0063_ajn37u.jpg',
      alt: 'Test alt 39',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142531/jasmin/DSC_0074_vfb5fr.jpg',
      alt: 'Test alt 38',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142532/jasmin/DSC_0100_g5vq68.jpg',
      alt: 'Test alt 37',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142530/jasmin/DSC_0179_x8v7fe.jpg',
      alt: 'Test alt 36',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142531/jasmin/DSC_0156_xn3vta.jpg',
      alt: 'Test alt 35',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142527/jasmin/DSC_0205_fimjkv.jpg',
      alt: 'Test alt 34',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142526/jasmin/DSC_0061_pfyz8l.jpg',
      alt: 'Test alt 33',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142527/jasmin/DSC_0223_hjhz1u.jpg',
      alt: 'Test alt 32',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142524/jasmin/DSC_0053_n2sbt2.jpg',
      alt: 'Test alt 31',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142520/jasmin/DSC_0202_a0k1u0.jpg',
      alt: 'Test alt 30',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142527/jasmin/DSC_0214_hg6vxh.jpg',
      alt: 'Test alt 29',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142524/jasmin/DSC_0151_rpton7.jpg',
      alt: 'Test alt 28',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142523/jasmin/DSC_0146_gnuqzk.jpg',
      alt: 'Test alt 27',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142519/jasmin/DSC_0238_pziodl.jpg',
      alt: 'Test alt 26',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142520/jasmin/DSC_0239_kj6s5d.jpg',
      alt: 'Test alt 25',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1719142519/jasmin/DSC_0226_fu00ex.jpg',
      alt: 'Test alt 24',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547424/jasmin/cuisine5_bl1wez.jpg',
      alt: 'Test alt 1',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/2.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547422/jasmin/piscine_enfant_luaecn.jpg',
      alt: 'Test alt 2',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/3.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547420/jasmin/table_terasse1_p2olwg.jpg',
      alt: 'Test alt 3',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547418/jasmin/baignoir1_neu6ud.jpg',
      alt: 'Test alt 4',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547416/jasmin/piscine_enfant2_tn0d3i.jpg',
      alt: 'Test alt 5',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547413/jasmin/payout1_wfnl2o.jpg',
      alt: 'Test alt 6',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547409/jasmin/salle_de_bain_fqdq1p.jpg',
      alt: 'Test alt 7',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547407/jasmin/payout2_ovfuza.jpg',
      alt: 'Test alt 8',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547405/jasmin/terrasse1_f3dojj.jpg',
      alt: 'Test alt 9',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547402/jasmin/coin_odnsqa.jpg',
      alt: 'Test alt 10',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547400/jasmin/piscine2_rslxob.jpg',
      alt: 'Test alt 11',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547398/jasmin/chaise_long_qrfcvr.jpg',
      alt: 'Test alt 12',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547396/jasmin/lavbo_blycgd.jpg',
      alt: 'Test alt 13',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547394/jasmin/terrase_vdtcfy.jpg',
      alt: 'Test alt 14',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547392/jasmin/saguia_gzrodi.jpg',
      alt: 'Test alt 15',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547390/jasmin/wc1_rdhlxo.jpg',
      alt: 'Test alt 16',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547400/jasmin/piscine2_rslxob.jpg',
      alt: 'Test alt 17',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547387/jasmin/cuisine4_cyvbhu.jpg',
      alt: 'Test alt 18',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547385/jasmin/lagoun2_gwa8qz.jpg',
      alt: 'Test alt 19',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711547383/jasmin/cuisine3_amqay8.jpg',
      alt: 'Test alt 20',
    },
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};


 
  return (
    <div >
      


                  
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>

                            {/* gallerie component elle entour que l'image */}
                            <LightGallery
                                onInit={onInit}
                                speed={100}
                                plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                            >

                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/v1711547478/jasmin/piscine3_djscu8.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>

                                  {carouselPhotos.map((image, index) => {
                                return (
                                  
                                    <a href={image.src} key={index} >
          
                                    </a>
        
                                )
                            })}

                          </LightGallery>


                            <MDBCardBody>
                              <MDBCardTitle>Villa Casablanca</MDBCardTitle>
                              <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 900 € / Semaine </MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de cette villa en cliquant sur la photo
                              </MDBCardText>
                              <div style={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                              <MDBBtn color='success'>Disponible</MDBBtn>
                              <MDBBtn className='mx-2' color='info'><Link to='/jasmin'>Réserver</Link></MDBBtn>
                              <MDBBtnGroup shadow='0'>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="users" /> 8 Pax
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="bed" /> 4 Chambres
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="chart-area" /> 400m²
                                </MDBBtn>
                              </MDBBtnGroup>
                              </div>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      


                  
                 
    </div>
  )
}

export default CasablancaSearch