export const LOGIN_USER= "LOGIN_USER"
export const REGISTER_USER= "REGISTER_USER"
export const LOG_OUT= "LOG_OUT"
export const LOAD_USER= "LOAD_USER"  
export const FAIL_USER= "FAIL_USER"   // ERRUR
export const CURRENT_USER= "CURRENT_USER"

export const ADD_RESA= "ADD_RESA"
export const GET_RESA="GET_RESA"
export const LOAD_RESA= "LOAD_RESA"  
export const FAIL_RESA= "FAIL_RESA" 

export const ADD_CALENDRESA= "ADD_CALENDRESA"
export const GET_CALENDRESA="GET_CALENDRESA"
export const LOAD_CALENDRESA= "LOAD_CALENDRESA"  
export const FAIL_CALENDRESA= "FAIL_CALENDRESA" 
export const UPDATE_RESA = 'UPDATE_RESA'

export const ADD_PRIX= "ADD_PRIX"
export const GET_PRIX="GET_PRIX"
export const LOAD_PRIX= "LOAD_PRIX"  
export const FAIL_PRIX= "FAIL_PRIX" 




