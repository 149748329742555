import React, { useState } from 'react'
import { MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBInput, MDBListGroup, MDBListGroupItem, MDBNavbar, MDBRow } from 'mdb-react-ui-kit'
import NavbarLogout from '../../components/NavbarLogout'
import { useDispatch } from 'react-redux'
import { addTarifs } from '../../redux/actions/prixActions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";


const Tarifs = () => {
  const [V_Tarif, setV_Tarif] = useState('')
  const [TarifVAHS, setTarifVAHS] = useState('')
  const [TarifVAMS, setTarifVAMS] = useState('')
  const [TarifVABS, setTarifVABS] = useState('')
  const [TarifVCHS, setTarifVCHS] = useState('')
  const [TarifVCMS, setTarifVCMS] = useState('')
  const [TarifVCBS, setTarifVCBS] = useState('')
  const [TarifVBHS, setTarifVBHS] = useState('')
  const [TarifVBMS, setTarifVBMS] = useState('')
  const [TarifVBBS, setTarifVBBS] = useState('')
  const [TarifVRTKHS, setTarifVRTKHS] = useState('')
  const [TarifVRTKMS, setTarifVRTKMS] = useState('')
  const [TarifVRTKBS, setTarifVRTKBS] = useState('')
  const [TarifCHTHS, setTarifCHTHS] = useState('')
  const [TarifCHTMS, setTarifCHTMS] = useState('')
  const [TarifCHTBS, setTarifCHTBS] = useState('')
  const [TarifCHRTKHS_SF, setTarifCHRTKHS_SF] = useState('')
  const [TarifCHRTKHS_VP, setTarifCHRTKHS_VP] = useState('')
  const [TarifCHRTKHS_VJ, setTarifCHRTKHS_VJ] = useState('')
  const [TarifCHRTKMS_SF, setTarifCHRTKMS_SF] = useState('')
  const [TarifCHRTKMS_VP, setTarifCHRTKMS_VP] = useState('')
  const [TarifCHRTKMS_VJ, setTarifCHRTKMS_VJ] = useState('')
  const [TarifCHRTKBS_SF, setTarifCHRTKBS_SF] = useState('')
  const [TarifCHRTKBS_VP, setTarifCHRTKBS_VP] = useState('')
  const [TarifCHRTKBS_VJ, setTarifCHRTKBS_VJ] = useState('')
  // const [TarifBHS_S, setTarifBHS_S] = useState('')
  // const [TarifBHS_12H, setTarifBHS_12H] = useState('')
  // const [TarifBHS_36H, setTarifBHS_36H] = useState('')
  // const [TarifBMS_SAS, setTarifBMS_SAS] = useState('')
  // const [TarifBMS_SSS, setTarifBMS_SSS] = useState('')
  // const [TarifBMS_12H, setTarifBMS_12H] = useState('')
  // const [TarifBMS_36H, setTarifBMS_36H] = useState('')
  // const [TarifBBS_SAS, setTarifBBS_SAS] = useState('')
  // const [TarifBBS_SSS, setTarifBBS_SSS] = useState('')
  // const [TarifBBS_12H, setTarifBBS_12H] = useState('')
  // const [TarifBBS_36H, setTarifBBS_36H] = useState('')
  const dispatch=useDispatch()
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      if (!V_Tarif) {
        toast.error("Veuillez mettre la version de prix pour la MAJ.");
        return;
      }

      const FormData = {
        V_Tarif: V_Tarif,
        TarifVAHS: TarifVAHS,
        TarifVAMS: TarifVAMS,
        TarifVABS: TarifVABS,
        TarifVCHS: TarifVCHS,
        TarifVCMS: TarifVCMS,
        TarifVCBS: TarifVCBS,
        TarifVBHS: TarifVBHS,
        TarifVBMS: TarifVBMS,
        TarifVBBS: TarifVBBS,
        TarifVRTKHS: TarifVRTKHS,
        TarifVRTKMS: TarifVRTKMS,
        TarifVRTKBS: TarifVRTKBS,
        TarifCHTHS: TarifCHTHS,
        TarifCHTMS: TarifCHTMS,
        TarifCHTBS: TarifCHTBS,
        TarifCHRTKHS_SF: TarifCHRTKHS_SF,
        TarifCHRTKHS_VP: TarifCHRTKHS_VP,
        TarifCHRTKHS_VJ: TarifCHRTKHS_VJ,
        TarifCHRTKMS_SF: TarifCHRTKMS_SF,
        TarifCHRTKMS_VP: TarifCHRTKMS_VP,
        TarifCHRTKMS_VJ: TarifCHRTKMS_VJ,
        TarifCHRTKBS_SF: TarifCHRTKBS_SF,
        TarifCHRTKBS_VP: TarifCHRTKBS_VP,
        TarifCHRTKBS_VJ: TarifCHRTKBS_VJ,
        // TarifBHS_S: TarifBHS_S,
        // TarifBHS_12H: TarifBHS_12H,
        // TarifBHS_36H: TarifBHS_36H,
        // TarifBMS_SAS: TarifBMS_SAS,
        // TarifBMS_SSS: TarifBMS_SSS,
        // TarifBMS_12H: TarifBMS_12H,
        // TarifBMS_36H: TarifBMS_36H,
        // TarifBBS_SAS: TarifBBS_SAS,
        // TarifBBS_SSS: TarifBBS_SSS,
        // TarifBBS_12H: TarifBBS_12H,
        // TarifBBS_36H: TarifBBS_36H
      }
      await dispatch(addTarifs(FormData))

      toast.success("Vos prix ont étés enregistrer et mises à jour avec succès.");

      // Naviguer après un court délai
      setTimeout(() => {
        navigate('/tarif');
      }, 3000); // 3000 millisecondes = 3 secondes
      
    } catch (error) {
      console.error(error);
    }
    
    
    // Ajouter la logique pour sauvegarder les tarifs, par exemple envoyer les données à un serveur.
  }

  return (
    <div>
      {/* Container pour les toasts */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <header>
        <MDBNavbar expand='lg' light bgColor='white' sticky>
          <MDBContainer fluid style={{ justifyContent: 'center' }}>
            <NavbarLogout />
          </MDBContainer>
        </MDBNavbar>
        <MDBCard alignment='center'>
          <MDBCardBody>
          <MDBCardTitle>Version Tarif </MDBCardTitle>
            <MDBRow className='mb-4'>
              <MDBCol>
                <MDBInput name='V_Tarif' id='form10Example40' label='Version Tarif' type='String' value={V_Tarif} onChange={(e) => setV_Tarif(e.target.value)} />
              </MDBCol>
            </MDBRow >
            <MDBCardTitle style={{backgroundColor:'red'}}>Tarif Villas </MDBCardTitle>
             
            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', gap:'20px',padding:'10px'}}>
            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_300,w_400/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Azalée</MDBCardTitle>
                <MDBCardText>
                  Mettre à jour les prix.
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifVAHS' id='form10Example1' label='Prix Haut Saison' type='number' value={TarifVAHS} onChange={(e) => setTarifVAHS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVAMS' id='form10Example2' label='Prix Moyen Saison' type='number' value={TarifVAMS} onChange={(e) => setTarifVAMS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVABS' id='form10Example3' label='Prix Bas Saison' type='number' value={TarifVABS} onChange={(e) => setTarifVABS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1711547478/jasmin/piscine3_djscu8.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Casablanca</MDBCardTitle>
                <MDBCardText>
                  Mettre à jour les prix.
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifVCHS' id='form10Example4' label='Prix Haut Saison' type='number' value={TarifVCHS} onChange={(e) => setTarifVCHS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVCMS' id='form10Example5' label='Prix Moyen Saison' type='number' value={TarifVCMS} onChange={(e) => setTarifVCMS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVCBS' id='form10Example6' label='Prix Bas Saison' type='number' value={TarifVCBS} onChange={(e) => setTarifVCBS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_300,w_400/v1711546809/bougainvillier/piscine_lu63zy.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Bougainvilliers</MDBCardTitle>
                <MDBCardText>
                  Mettre à jour les prix.
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifVBHS' id='form10Example7' label='Prix Haut Saison' type='number' value={TarifVBHS} onChange={(e) => setTarifVBHS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVBMS' id='form10Example8' label='Prix Moyen Saison' type='number' value={TarifVBMS} onChange={(e) => setTarifVBMS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVBBS' id='form10Example9' label='Prix Bas Saison' type='number' value={TarifVBBS} onChange={(e) => setTarifVBBS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_300,w_400/v1715775992/rock/salljardin_ah1vwh.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rock The Kasbah</MDBCardTitle>
                <MDBCardText>
                  Mettre à jour les prix.
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifVRTKHS' id='form10Example10' label='Prix Haut Saison' value={TarifVRTKHS} onChange={(e) => setTarifVRTKHS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVRTKMS' id='form10Example11' label='Prix Moyen Saison' value={TarifVRTKMS} onChange={(e) => setTarifVRTKMS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifVRTKBS' id='form10Example12' label='Prix Bas Saison' value={TarifVRTKBS} onChange={(e) => setTarifVRTKBS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>
            </div>


            <MDBCardTitle style={{backgroundColor:'red'}}>Tarif chambres d'hôtes </MDBCardTitle>

            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', gap:'20px',padding:'10px'}}>
            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1712657994/tralala/chambre_2_vue_piscine_mxeeqs.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Tralala</MDBCardTitle>
                <MDBCardText>
                  Mettre à jour les prix.
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifCHTHS' id='form10Example13' label='Prix Haut Saison' type='number' value={TarifCHTHS} onChange={(e) => setTarifCHTHS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHTMS' id='form10Example14' label='Prix Moyen Saison' type='number' value={TarifCHTMS} onChange={(e) => setTarifCHTMS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHTBS' id='form10Example15' label='Prix Bas Saison' type='number' value={TarifCHTBS} onChange={(e) => setTarifCHTBS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1715332933/rock/cham_opwoty.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rock The Kasbah</MDBCardTitle>
                <MDBCardText>
                Prix (4 pax + PD dans une suite familiale)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifCHRTKHS_SF' id='form10Example16' label='Prix Haut Saison' type='number' value={TarifCHRTKHS_SF} onChange={(e) => setTarifCHRTKHS_SF(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHRTKMS_SF' id='form10Example19' label='Prix Moyen Saison' type='number' value={TarifCHRTKMS_SF} onChange={(e) => setTarifCHRTKMS_SF(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHRTKBS_SF' id='form10Example22' label='Prix Bas Saison' type='number' value={TarifCHRTKBS_SF} onChange={(e) => setTarifCHRTKBS_SF(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1715332936/rock/suite1_sjrtcl.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rock The Kasbah</MDBCardTitle>
                <MDBCardText>
                Prix (2 pax + PD dans une suite vue piscine)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifCHRTKHS_VP' id='form10Example17' label='Prix Haut Saison' type='number' value={TarifCHRTKHS_VP} onChange={(e) => setTarifCHRTKHS_VP(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHRTKMS_VP' id='form10Example20' label='Prix Moyen Saison' type='number' value={TarifCHRTKMS_VP} onChange={(e) => setTarifCHRTKMS_VP(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHRTKBS_VP' id='form10Example23' label='Prix Bas Saison' type='number' value={TarifCHRTKBS_VP} onChange={(e) => setTarifCHRTKBS_VP(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_300,w_400/v1715332933/rock/ch_hptk8q.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rock The Kasbah</MDBCardTitle>
                <MDBCardText>
                Prix (2 pax + PD dans une suite vue jardin)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifCHRTKHS_VJ' id='form10Example18' label='Prix Haut Saison' type='number' value={TarifCHRTKHS_VJ} onChange={(e) => setTarifCHRTKHS_VJ(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHRTKMS_VJ' id='form10Example21' label='Prix Moyen Saison' type='number' value={TarifCHRTKMS_VJ} onChange={(e) => setTarifCHRTKMS_VJ(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifCHRTKBS_VJ' id='form10Example24' label='Prix Bas Saison' type='number' value={TarifCHRTKBS_VJ} onChange={(e) => setTarifCHRTKBS_VJ(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            </div>


            {/* <MDBCardTitle style={{backgroundColor:'red'}}>Tarif Bateau </MDBCardTitle>

            <div style={{display:'flex', flexWrap:'wrap', justifyContent:'center', gap:'20px',padding:'10px'}}>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1712915662/rita/2_p8of2o.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rita</MDBCardTitle>
                <MDBCardText>
                Prix (8 Pax - Avec skipper par 12H)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifBHS_12H' id='form10Example26' label='Prix Haut Saison' type='number' value={TarifBHS_12H} onChange={(e) => setTarifBHS_12H(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBMS_12H' id='form10Example30' label='Prix Moyen Saison' type='number' value={TarifBMS_12H} onChange={(e) => setTarifBMS_12H(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBBS_12H' id='form10Example34' label='Prix Bas Saison' type='number' value={TarifBBS_12H} onChange={(e) => setTarifBBS_12H(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1712915662/rita/2_p8of2o.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rita</MDBCardTitle>
                <MDBCardText>
                Prix (8 Pax - Avec skipper par 36H)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifBHS_36H' id='form10Example27' label='Prix Haut Saison' type='number' value={TarifBHS_36H} onChange={(e) => setTarifBHS_36H(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBMS_36H' id='form10Example31' label='Prix Moyen Saison' type='number' value={TarifBMS_36H} onChange={(e) => setTarifBMS_36H(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBBS_36H' id='form10Example35' label='Prix Bas Saison' type='number' value={TarifBBS_36H} onChange={(e) => setTarifBBS_36H(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1712915662/rita/2_p8of2o.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rita</MDBCardTitle>
                <MDBCardText>
                Prix (6 Pax - Avec skipper par semaine)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifBHS_S' id='form10Example25' label='Prix Haut Saison' type='number' value={TarifBHS_S} onChange={(e) => setTarifBHS_S(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBMS_SAS' id='form10Example28' label='Prix Moyen Saison' type='number' value={TarifBMS_SAS} onChange={(e) => setTarifBMS_SAS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBBS_SAS' id='form10Example32' label='Prix Bas Saison' type='number' value={TarifBBS_SAS} onChange={(e) => setTarifBBS_SAS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            <MDBCard>
              <MDBCardImage position='top' alt='...' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,w_400/v1712915662/rita/2_p8of2o.jpg' />
              <MDBCardBody>
                <MDBCardTitle>Rita</MDBCardTitle>
                <MDBCardText>
                Prix (6 Pax - Sans navigation et sans skipper par semaine)
                </MDBCardText>
              </MDBCardBody>
              <MDBListGroup flush>
                <MDBListGroupItem><MDBInput name='TarifBMS_SSS' id='form10Example29' label='Prix Moyen Saison' type='number' value={TarifBMS_SSS} onChange={(e) => setTarifBMS_SSS(e.target.value)} /></MDBListGroupItem>
                <MDBListGroupItem><MDBInput name='TarifBBS_SSS' id='form10Example33' label='Prix Bas Saison' type='number' value={TarifBBS_SSS} onChange={(e) => setTarifBBS_SSS(e.target.value)} /></MDBListGroupItem>
              </MDBListGroup>
            </MDBCard>

            </div> */}

            <hr />
            <MDBBtn onClick={handleSubmit}>Enregistrer</MDBBtn>
          </MDBCardBody>
        </MDBCard>
      </header>
    </div>
  )
}

export default Tarifs
