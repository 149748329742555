import React from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'


import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';







const BougainvillierSearch = () => {



       

  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546810/bougainvillier/exteriere_spk3kj.jpg',
      alt: 'Test alt 1',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/2.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546809/bougainvillier/piscine_lu63zy.jpg',
      alt: 'Test alt 2',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/3.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546807/bougainvillier/piscine_vao4hk.jpg',
      alt: 'Test alt 3',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546806/bougainvillier/cuisine_uygpei.jpg',
      alt: 'Test alt 4',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546805/bougainvillier/salons_nyrxit.jpg',
      alt: 'Test alt 5',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546803/bougainvillier/parking_trqlup.jpg',
      alt: 'Test alt 6',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546802/bougainvillier/salon_t7cfbl.jpg',
      alt: 'Test alt 7',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546801/bougainvillier/entr%C3%A9_e5yvfn.jpg',
      alt: 'Test alt 8',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546799/bougainvillier/cuisine_zddxws.jpg',
      alt: 'Test alt 9',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546798/bougainvillier/chambre_3_dvmw5e.jpg',
      alt: 'Test alt 10',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546797/bougainvillier/escalier_jsuhq1.jpg',
      alt: 'Test alt 11',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546796/bougainvillier/chambre2_squmzj.jpg',
      alt: 'Test alt 12',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546795/bougainvillier/chambre3_mfldap.jpg',
      alt: 'Test alt 13',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546792/bougainvillier/chambre_2_koyste.jpg',
      alt: 'Test alt 14',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1711546794/bougainvillier/chambre_1_hrsfw2.jpg',
      alt: 'Test alt 15',
    },
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};

 
 
  return (
    <div >
      

      

                   
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>

                            {/* gallerie component elle entour que l'image */}
                            <LightGallery
                                onInit={onInit}
                                speed={100}
                                plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                            >

                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246541/piscine_rdgisj.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>

                                  {carouselPhotos.map((image, index) => {
                                return (
                                  
                                    <a href={image.src} key={index} >
          
                                    </a>
        
                                )
                            })}

                          </LightGallery>


                            <MDBCardBody>
                              <MDBCardTitle>Villa Bougainvilliers</MDBCardTitle>
                              <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 1470 € / Semaine </MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de cette villa en cliquant sur la photo
                              </MDBCardText>
                              <div style={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                              <MDBBtn color='success'>Disponible</MDBBtn>
                              <MDBBtn className='mx-2' color='info'><Link to='/bougainvillier'>Réserver</Link></MDBBtn>
                              <MDBBtnGroup shadow='0'>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="users" /> 6 Pax
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="bed" /> 3 Chambres
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="chart-area" /> 200m²
                                </MDBBtn>
                              </MDBBtnGroup>
                              </div>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      
                    

                  
                 
    </div>
  )
}

export default BougainvillierSearch