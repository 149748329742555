import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../components/Footer';
import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow, MDBInput, MDBNavbar, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBCardFooter, MDBTextArea } from 'mdb-react-ui-kit';
import Navbar from '../components/Navbar';
import { Helmet } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Avis = () => {
  const [formData, setFormData] = useState({
    code: '',
    nomprenom: '',
    email: '',
    telephone: '',
    villa: '',
    avis: '',
    score: 0
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [selectedVilla, setSelectedVilla] = useState('Votre villa de séjour');
  const [avisData, setAvisData] = useState([]);

  const styles = {
    avisContainer: {
      marginTop: '20px',
      display: 'flex',
    },
    formContainer: {
      width: '100%',
      maxWidth: '400px',
      position: 'sticky',
      top: '20px',
    },
    cardsContainer: {
      flex: 1,
      marginLeft: '20px',
    },
    stars: {
      display: 'flex',
      gap: '5px'
    },
    cardFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  };

  const codesecretavis = "C24-Avis";

  const fetchAvisData = async () => {
    try {
      const response = await axios.get('/api/avis/getavis');
      setAvisData(response.data.notreAvis || []);
    } catch (error) {
      toast.error('Une erreur est survenue lors de la récupération des avis.');
    }
  };

  useEffect(() => {
    fetchAvisData();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleVillaChange = (villa) => {
    setFormData({
      ...formData,
      villa
    });
    setSelectedVilla(villa);
  };

  const handleStarChange = (e) => {
    setFormData({
      ...formData,
      score: parseInt(e.target.value, 10)
    });
  };

  const validateForm = () => {
    if (formData.code !== codesecretavis) {
      toast.error('Le code est incorrect.');
      return;
    }
    
    if (!formData.nomprenom || !formData.email || !formData.telephone || !formData.villa || !formData.avis || formData.score === 0) {
      toast.error("Tous les champs doivent être remplis.");
      return false;
    }
    setError('');
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      const { code, ...dataToSend } = formData;
      await axios.post('/api/avis/addavis', dataToSend);
      setFormData({
        code: '',
        nomprenom: '',
        email: '',
        telephone: '',
        villa: '',
        avis: '',
        score: 0
      });
      setSelectedVilla('Sélectionner une villa');
      toast.success('Votre avis a été enregistré avec succès.');
      fetchAvisData();
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const villaImages = {
    'Azalée': 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_900,w_1500/v1720696151/Azal%C3%A9%20avec%20mes%20modif/DSC_0808_onfkn0.jpg',
    'Bougainvillier': 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_900,w_1500/v1710246541/piscine_rdgisj.jpg',
    'Casablanca': 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_900,w_1500/v1711547478/jasmin/piscine3_djscu8.jpg',
    'Rock The Kasbah': 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_900,w_1500/v1720007070/rock/the_rock_kasbah-_owhnzo.jpg',
    'Tralala': 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_900,w_1500/v1710249805/vue_piscine_soir2_krr8q4.jpg'
  };

  const renderStars = (score) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <span key={i} style={{ color: i <= score ? '#FFD700' : '#E0E0E0', fontSize: '20px' }}>
          &#9733;
        </span>
      );
    }
    return stars;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const optionsDate = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const optionsTime = { hour: '2-digit', minute: '2-digit' };
    const dayOfWeek = date.toLocaleDateString('fr-FR', { weekday: 'long' });
    const formattedDate = date.toLocaleDateString('fr-FR', optionsDate);
    const formattedTime = date.toLocaleTimeString('fr-FR', optionsTime);
    
    return `${formattedDate} ${dayOfWeek} ${formattedTime}`;
  };
  
  

  return (
    <div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <Helmet>
        <title>Avis</title>
        <meta name="description" content="Page des avis" />
      </Helmet>

      <header>
        <MDBNavbar expand='lg' light bgColor='white' sticky>
          <MDBContainer fluid style={{ justifyContent: 'center', display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
            <Navbar />
          </MDBContainer>
        </MDBNavbar>
      </header>

      <MDBContainer style={styles.avisContainer}>
        <MDBRow>
          <MDBCol md='4' style={styles.formContainer}>
            <form style={styles.formContainer} onSubmit={handleSubmit}>
              <MDBInput type='text' id='form4Example6' wrapperClass='mb-4' label='Code' name='code' value={formData.code} onChange={handleChange} />
              <MDBInput type='text' id='form4Example1' wrapperClass='mb-4' label='Nom et prénom' name='nomprenom' value={formData.nomprenom} onChange={handleChange} />
              <MDBInput type='text' id='form4Example2' wrapperClass='mb-4' label='Adresse email' name='email' value={formData.email} onChange={handleChange} />
              <MDBInput type='number' id='form4Example5' wrapperClass='mb-4' label='Téléphone' name='telephone' value={formData.telephone} onChange={handleChange} />
              
              <div className="mb-4">
                <label htmlFor="villaDropdown" className="form-label">Villa</label>
                <MDBDropdown id="villaDropdown" className="form-control p-0">
                  <MDBDropdownToggle className="w-100 text-start">{selectedVilla}</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {['Rock The Kasbah', 'Azalée', 'Casablanca', 'Bougainvillier', 'Tralala'].map((villa) => (
                      <MDBDropdownItem name='villa' key={villa} onClick={() => handleVillaChange(villa)}>{villa}</MDBDropdownItem>
                    ))}
                  </MDBDropdownMenu>
                </MDBDropdown>
              </div>

              <MDBTextArea id='form4Example3' wrapperClass='mb-4' rows={6} label='Avis' name='avis' value={formData.avis} onChange={handleChange} maxLength={200} />
    
              
              <div className="mb-4">
                <label className="form-label">Score</label>
                <div style={styles.stars}>
                  {[1, 2, 3, 4, 5].map((value) => (
                    <label key={value} style={{ color: value <= formData.score ? '#FFD700' : '#E0E0E0', fontSize: '20px' }}>
                      <input type="radio" name="score" value={value} checked={formData.score === value} onChange={handleStarChange} style={{ display: 'none' }} />
                      &#9733;
                    </label>
                  ))}
                </div>
              </div>
              
              {error && <p style={{ color: 'red' }}>{error}</p>}
              {success && <p style={{ color: 'green' }}>{success}</p>}
              <MDBBtn type='submit' className='mb-4' block>
                Enregistrer avis
              </MDBBtn>
            </form>
          </MDBCol>

          <MDBCol md='8' style={styles.cardsContainer}>
            <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
              {avisData.map((avis) => (
                <MDBCol key={avis._id}>
                  <MDBCard className='mb-4' >
                    <MDBCardImage src={villaImages[avis.villa]} position='top' alt={avis.villa} />
                    <MDBCardBody>
                    <div className='d-inline-flex position-relative'>
                      <MDBBadge className='position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle'>
                        <span className='visually-hidden'>New alerts</span>
                      </MDBBadge>
                      <img
                        className='rounded-4 shadow-4'
                        src='https://img.freepik.com/vecteurs-premium/voyageur-logo-template-design-embleme-vecteur-design-concept-symbole-creatif-icone_316488-972.jpg'
                        alt='Avatar'
                        style={{ width: '50px', height: '50px' }}
                      />
                    </div>                    
                      <MDBCardTitle>{avis.nomprenom}</MDBCardTitle>
                      <MDBCardText>{avis.avis}</MDBCardText>
                    </MDBCardBody>
                    <MDBCardFooter style={styles.cardFooter}>
                      <div>{avis.villa}</div>
                      <div>{renderStars(avis.score)}</div>
                    </MDBCardFooter>
                    <MDBCardFooter>
                    {formatDate(avis.createdAt)}
                    </MDBCardFooter>
                  </MDBCard>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <Footer />
    </div>
  );
};

export default Avis;
