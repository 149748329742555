import React, { useState } from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'
import Navbar from '../../components/Navbar';

import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';



import Footer from '../../components/Footer';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConfigProvider, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const disabledDate = (current) => {
  
  // Can not select days before today and today and other date
  return  (current && current < dayjs().endOf('day'))
    
};


const Rita = () => {

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [price, setPrice] = useState(0);
  // const [totalprix, setTotalPrix] = useState(0)
  const [prenom,setPrenom]=useState("")
  const [nom,setNom]=useState("")
  const [nbpersonne,setNbpersonne]=useState("")
  const [adresse,setAdresse]=useState("")
  const [email,setEmail]=useState("")
  const [telephone,setTelephone]=useState("")
  const [commentaire,setCommentaire]=useState("")
  const [checkedtype, setCheckedType] = useState(false)
  const [checkedopt1, setCheckedopt1] = useState(false)
  const [checkedopt2, setCheckedopt2] = useState(false)
  const [checkedopt3, setCheckedopt3] = useState(false)
  const [checkedopt4, setCheckedopt4] = useState(false)
  const [checkedopt5, setCheckedopt5] = useState(false)
  const [checkedopt6, setCheckedopt6] = useState(false)
  const [checkedopt7, setCheckedopt7] = useState(false)
  const [checkedopt8, setCheckedopt8] = useState(false)
  const [checkedopt9, setCheckedopt9] = useState(false)
  const [checkedopt10, setCheckedopt10] = useState(false)
  const [checkedopt11, setCheckedopt11] = useState(false)
  const [checkedopt12, setCheckedopt12] = useState(false)
  const [checkedopt13, setCheckedopt13] = useState(false)
  const [checkedopt14, setCheckedopt14] = useState(false)
  const navigate =useNavigate()

  function handleDateChange(dates) {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      setFrom(startDate);
      setTo(endDate);
      let totalPrice = 0;
  
      const checkAndAddPrice = (startDate, endDate, pricePerNight) => {
        let currentDate = startDate.clone();
        while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
          if (
            // Base saison
            ((dayjs('2024-01-16').isBefore(currentDate) || dayjs('2024-01-16').isSame(currentDate, 'day')) &&
            (dayjs('2024-03-31').isAfter(currentDate) || dayjs('2024-03-31').isSame(currentDate, 'day'))) ||
            ((dayjs('2024-11-01').isBefore(currentDate) || dayjs('2024-11-01').isSame(currentDate, 'day')) &&
            (dayjs('2024-12-14').isAfter(currentDate) || dayjs('2024-12-14').isSame(currentDate, 'day')))
          ) {
            totalPrice += ((pricePerNight.base)*(basePeron.NumberBasePersonne));
          } else if (
            // Moyenne saison
            ((dayjs('2024-01-01').isBefore(currentDate) || dayjs('2024-01-01').isSame(currentDate, 'day')) &&
            (dayjs('2024-01-15').isAfter(currentDate) || dayjs('2024-01-15').isSame(currentDate, 'day'))) ||
            ((dayjs('2024-04-01').isBefore(currentDate) || dayjs('2024-04-01').isSame(currentDate, 'day')) &&
            (dayjs('2024-06-15').isAfter(currentDate) || dayjs('2024-06-15').isSame(currentDate, 'day'))) ||
            ((dayjs('2024-09-15').isBefore(currentDate) || dayjs('2024-09-15').isSame(currentDate, 'day')) &&
            (dayjs('2024-10-31').isAfter(currentDate) || dayjs('2024-10-31').isSame(currentDate, 'day'))) ||
            ((dayjs('2024-12-15').isBefore(currentDate) || dayjs('2024-12-15').isSame(currentDate, 'day')) &&
            (dayjs('2024-12-31').isAfter(currentDate) || dayjs('2024-12-31').isSame(currentDate, 'day')))
          ) {
            totalPrice += ((pricePerNight.medium)*(basePeron.NumberBasePersonne));
          } else if (
            // Haute saison
            (dayjs('2024-06-16').isBefore(currentDate) || dayjs('2024-06-16').isSame(currentDate, 'day')) &&
            (dayjs('2024-09-14').isAfter(currentDate) || dayjs('2024-09-14').isSame(currentDate, 'day'))
          ) {
            totalPrice += ((pricePerNight.high)*(basePeron.NumberBasePersonne));
          }
          currentDate = currentDate.add(1, 'day');
        }
        
      };
  
      // Define your price per night for each season
      const pricePerNight = {
        base: 107,
        medium: 130,
        high: 202
      };

      const basePeron = {
        NumberBasePersonne: 6,
        
      };
  
      // Call function to calculate total price
      checkAndAddPrice(startDate, endDate, pricePerNight);
  
      setPrice(totalPrice);
      
      
    } else {
      // Si les dates sont null, réinitialiser le prix total à 0
      setPrice(0);
      
    }
  }

  // function handleNumPersonsChange(event) {
  //   let value = parseInt(event.target.value);
    
  //   // Si la valeur est inférieure à 0, la définir à 0
  //   if (isNaN(value) || value < 0) {
  //     value = 0;
  //   }
  
  //   const additionalPrice = price;
  //   const aux = additionalPrice * value;
  //   setTotalPrix(aux);
    
  // }
  
 
           // pour appeler useraction et dispatche login dans onclick sur le button

           const handleSubmit = (event) => {

            try {
            event.preventDefault(); // Empêcher le rafraîchissement de la page

           // Vérifier la période
            if (!from || !to) {
              toast.error("Veuillez remplir la période.");
              return;
            }

            // Vérifier le type de logement
            if (!checkedtype) {
              toast.error("Veuillez choisir le type de logement.");
              return;
            }

            // Vérifier le prénom et le nom
            if (!prenom || !nom) {
              toast.error("Veuillez remplir votre prénom et nom.");
              return;
            }

            // Vérifier nb personne
            if (!nbpersonne) {
              toast.error("Veuillez ajouter le nombre de personne.");
              return;
            }
            // Vérifier adresse
            if (!adresse) {
              toast.error("Veuillez remplir votre adresse.");
              return;
            }

            // Vérifier l'email
            if (!email) {
              toast.error("Veuillez remplir votre email.");
              return;
            }

            // Vérifier le téléphone
            if (!telephone) {
              toast.error("Veuillez remplir votre téléphone.");
              return;
            }



            let dataBookSend = {
              checktype: checkedtype,
              checkedopt1: checkedopt1,
              checkedopt2: checkedopt2,
              checkedopt3: checkedopt3,
              checkedopt4: checkedopt4,
              checkedopt5: checkedopt5,
              checkedopt6: checkedopt6,
              checkedopt7: checkedopt7,
              checkedopt8: checkedopt8,
              checkedopt9: checkedopt9,
              checkedopt10: checkedopt10,
              checkedopt11: checkedopt11,
              checkedopt12: checkedopt12,
              checkedopt13: checkedopt13,
              checkedopt14: checkedopt14,
              from: from,
              to: to,
              prenom:prenom,
              nom:nom,
              nbpersonne:nbpersonne,
              totalprix: price,
              adresse:adresse,
              email:email,
              telephone: telephone,
              commentaire: commentaire
            }
            
            
            // // Perform form submission
            
               axios.post('/api/email/sendEmailBooking', dataBookSend);
              
                  
                  toast.success("Votre demande de réservation a été envoyée avec succès.");

                      // Naviguer après un court délai
                      setTimeout(() => {
                        navigate("/nosvilla");
                      }, 5000); // 3000 millisecondes = 3 secondes
                                  
            } catch (error) {
              // Handle error
              console.error(error);
            }
           
        };



  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915694/rita/WhatsApp_Image_2023-12-08_at_09.16.23_x6hboe.jpg',
      alt: 'Test alt 1',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/2.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915662/rita/2_p8of2o.jpg',
      alt: 'Test alt 2',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/3.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915665/rita/1_tymqer.jpg',
      alt: 'Test alt 3',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915650/rita/WhatsApp_Image_2023-12-08_at_09.14.02_d6y28g.jpg',
      alt: 'Test alt 4',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915691/rita/WhatsApp_Image_2023-12-06_at_17.26.47_c93kwn.jpg',
      alt: 'Test alt 5',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915676/rita/WhatsApp_Image_2023-12-06_at_17.42.36_fnfpbe.jpg',
      alt: 'Test alt 6',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915679/rita/WhatsApp_Image_2023-12-06_at_17.41.52_nkzpmr.jpg',
      alt: 'Test alt 7',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712916170/rita/6_vhqazq.jpg',
      alt: 'Test alt 8',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712916161/rita/8_jctfaz.jpg',
      alt: 'Test alt 9',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712916163/rita/7_rdjy0f.jpg',
      alt: 'Test alt 10',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712916166/rita/5_itrs3v.jpg',
      alt: 'Test alt 11',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915688/rita/WhatsApp_Image_2023-12-06_at_17.36.16_odj4iy.jpg',
      alt: 'Test alt 12',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712915685/rita/WhatsApp_Image_2023-12-06_at_17.30.04_y2fgvu.jpg',
      alt: 'Test alt 13',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712916183/rita/12_a66j7s.jpg',
      alt: 'Test alt 14',
    },
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};

  
 
  return (
    <div >
      {/* Container pour les toasts */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <Navbar/>

                    <LightGallery
                        onInit={onInit}
                        speed={100}
                        plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                    >
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710329378/2_jrwjid.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>
                            <MDBCardBody>
                              <MDBCardTitle>TRAWLER RITA</MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de ce bateau en cliquant sur la photo
                              </MDBCardText>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      
                    {carouselPhotos.map((image, index) => {
                        return (
                          
                            <a href={image.src} key={index} >
  
                            </a>
 
                        )
                    })}

                  </LightGallery>

                  <MDBListGroupItem noBorders color='primary' className='px-3 mb-2 rounded-3' style={{margin:'50px',display:'flex',justifyContent:'center'}}>
                  Enregistrement de votre demande de réservation
                  </MDBListGroupItem>    

                  <div style={{margin:'50px',display:'flex',justifyContent:'center',alignItems:'center', gap:'10px', color:'GrayText'}}>
                
                  <MDBIcon far icon="hand-point-right" /> <div style={{color:'red'}}>(En rouge dates indisponibles)</div>
                
                  {/* nous pouvons importé la component calandrer mais il faut resoudre le probleme de l'envois de props car nous voulons recupérer des valeurs depuis component */}

                  
                  {/* Configueprovider est pour personnaliser le coleur des date disable en rouge dans rangepicker */}
                  <ConfigProvider
                      theme={{
                        components: {
                          DatePicker: {
                            cellBgDisabled: 'red',
                            cellActiveWithRangeBg: 'green',
                            
                          },
                          
                        },
                      }}
                    >

                  <Space direction="vertical" size={12}>

                    <RangePicker 
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    
                    />
                    
                  </Space>
                  </ConfigProvider>
              </div>

              <hr className="hr hr-blurry" />
                  <MDBBadge color='success' light style={{margin:'10px',display:'flex',justifyContent:'center'}}>Le prix de la période sélectionnée selon la saison sans options est: {price}€</MDBBadge>
              <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                          <form style={{margin:'20px'}} onSubmit={handleSubmit}>
                            Merci de choisir le type de votre demande :
                          <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='Excursion'
                                label='Excursion TRAWLER RITA '
                                value="Excursion TRAWLER RITA"
                                onChange={(e)=>setCheckedType(e.target.value)}
                              />
                              <MDBCheckbox
                                id='Logement'
                                wrapperClass='d-flex justify-content-center mb-4'
                                label='Logement sans excursion TRAWLER RITA '
                                value='Logement sans excursion TRAWLER RITA  '
                                onChange={(e)=>setCheckedType(e.target.value)}                                
                              />
                               <MDBCheckbox
                                id='Dîner'
                                wrapperClass='d-flex justify-content-center mb-4'
                                label='Petit déjeuner, Déjeuner ou Dîner sans logement sans excursion TRAWLER RITA '
                                value='Dîner sans logement sans excursion TRAWLER RITA  '
                                onChange={(e)=>setCheckedType(e.target.value)}                                
                              />
                              <MDBRow className='mb-4'>
                                <MDBCol>
                                  <MDBInput name='prenom' id='form6Example1' label='Prénom' type='text' onChange={(e)=>setPrenom(e.target.value)}/>
                                  
                                </MDBCol>
                                <MDBCol>
                                  <MDBInput name='nom' id='form6Example2' label='Nom' type='text' onChange={(e)=>setNom(e.target.value)}/>
                                </MDBCol>
                              </MDBRow>
                              

                              <MDBInput name='nbpersonne' wrapperClass='mb-4' id='form6Example3' label='Nombre de personne' type='number' onChange={(e)=>setNbpersonne(e.target.value) } max={6} min={1}/> 
                              {/* <MDBBtn className='mb-4'  disabled block color='success'>
                              Le prix total de logement sans options est: {totalprix}€
                              </MDBBtn>                           */}
                              <MDBInput name='adresse' wrapperClass='mb-4' id='form6Example4' label='Adresse (N°, Rue, Ville, Pays)' type='text' onChange={(e)=>setAdresse(e.target.value)}/>
                              <MDBInput name='email' wrapperClass='mb-4' type='email' id='form6Example5' label='Email' onChange={(e)=>setEmail(e.target.value)}/>
                              <MDBInput name='telephone' wrapperClass='mb-4' type='tel' id='form6Example6' label='Télephone ' onChange={(e)=>setTelephone(e.target.value)}/>

                              <MDBInput name='commentaire' wrapperClass='mb-4' textarea id='form6Example7' rows={4} label='Commentaires ou demandes particuliéres' onChange={(e)=>setCommentaire(e.target.value)}/>

                              Options choisies: 
                                  {/* nous pouvons importé la component optiontarifs mais il faut resoudre le probleme de l'envois de props car nous voulons recupérer des valeurs depuis component */}
                                  
                                  <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',margin:'15px'}}>
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example1'
                                label='Accueil Aéroport et transfert villa (50€)'
                                value='Accueil Aéroport et transfert villa (50€)'
                                onChange={(e)=>setCheckedopt1(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example2'
                                label='Femme de ménage 4 heures (39€)'
                                value='Femme de ménage 4 heures (39€)'
                                onChange={(e)=>setCheckedopt2(e.target.value)} 
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example3'
                                label='Cuisinière à domicile  par repas et hors produits 4 heures (39€)'
                                value='Cuisinière à domicile  par repas et hors produits 4 heures (39€)'
                                onChange={(e)=>setCheckedopt3(e.target.value)} 
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example4'
                                label='Petit déjeuner chambre hôtes par personne (9€) '
                                value='Petit déjeuner chambre hôtes par personne (9€) '
                                onChange={(e)=>setCheckedopt4(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example5'
                                label='Service de courses à domicile pour alimentation et boissons, hors produits (29€)'
                                value='Service de courses à domicile pour alimentation et boissons, hors produits (29€)'
                                onChange={(e)=>setCheckedopt5(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example6'
                                label='Serviette de piscine  avec chaussons (par personne) offerts en fin de séjour (25€)'
                                value='Serviette de piscine  avec chaussons (par personne) offerts en fin de séjour (25€)'
                                onChange={(e)=>setCheckedopt6(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example7'
                                label='Sac de plage avec serviette ( par personne) offerts en fin de séjour (35€)'
                                value='Sac de plage avec serviette ( par personne) offerts en fin de séjour (35€)'
                                onChange={(e)=>setCheckedopt7(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example8'
                                label='Service pressing sur demande avec prise en charge dans votre villa, hors prestation du pressing (15€)'
                                value='Service pressing sur demande avec prise en charge dans votre villa, hors prestation du pressing (15€)'
                                onChange={(e)=>setCheckedopt8(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example9'
                                label='Massage à domicile 60 minutes (39€)'
                                value='Massage à domicile 60 minutes (39€)'
                                onChange={(e)=>setCheckedopt9(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example10'
                                label='Voiture de location ( 5 personnes) pour  7 jours (250€ à 400€)'
                                value='Voiture de location ( 5 personnes) pour  7 jours (250€ à 400€)'
                                onChange={(e)=>setCheckedopt10(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example11'
                                label='Chauffeur personnel pour location voiture et par jour 8 heures (70€)'
                                value='Chauffeur personnel pour location voiture et par jour 8 heures (70€)'
                                onChange={(e)=>setCheckedopt11(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example12'
                                label='Baby-sitting de 4 heures (par tranche de 3 enfants) (40€)'
                                value='Baby-sitting de 4 heures (par tranche de 3 enfants) (40€)'
                                onChange={(e)=>setCheckedopt12(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example13'
                                label='Peignoirs individualisés offerts en fin de séjour (35€)'
                                value='Peignoirs individualisés offerts en fin de séjour (35€)'
                                onChange={(e)=>setCheckedopt13(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example14'
                                label='Organisation activités : équitation, dromadaire,  calèche, jet-ski, kyte-surf, balnéothérapie, sortie en mer, excursions, etc. (Sur devis)'
                                value='Organisation activités : équitation, dromadaire,  calèche, jet-ski, kyte-surf, balnéothérapie, sortie en mer, excursions, etc. (Sur devis)'
                                onChange={(e)=>setCheckedopt14(e.target.value)}
                              />
                            </div>
                              
                              <MDBBtn className='mb-4' type='submit' block>
                                Enoyez votre demande de réservation
                              </MDBBtn>
                            </form>

                            <MDBCardBody>

                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                  <Footer/>
    </div>
  )
}

export default Rita