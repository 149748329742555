import { ADD_RESA, FAIL_RESA, GET_RESA, LOAD_RESA, UPDATE_RESA } from "../actionTypes/actionTypes"


//initialisation
const initialState ={  
    resa: null,
    load: false,
    error: null
}
//pure function
const resaReducer =(state=initialState,{type,payload})=>{
    switch (type) {
        case LOAD_RESA:
            return{...state,load:true}
        case ADD_RESA:
            return{...state,resa:payload.newResa,load:false }
        case GET_RESA:
            return{...state,resa:payload.reservation,load:false}   
        case UPDATE_RESA: 
            return {
                ...state,
                resa: state.resa.map(reservation =>
                    reservation.idresa === payload.updatedResa.idresa
                        ? payload.updatedResa
                        : reservation
                ),
                load: false
            } 
        case FAIL_RESA:
            return{...state,error:payload}     
        default:
            return state
    }
}
export default resaReducer