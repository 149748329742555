import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { MDBBtn, MDBCard, MDBCardBody, MDBContainer, MDBIcon, MDBInput, MDBNavbar, MDBSwitch, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import NavbarLogout from '../../components/NavbarLogout';
import { useDispatch } from 'react-redux';
import { addResaCalend} from '../../redux/actions/calendActions';
import axios from 'axios';
import CalendrierAzale from './CalendrierAzale';
import CalendrierRTK from './ClendrierRTK';
import CalendrierCasablanca from './CalendrierCasablanca';
import CalendrierBougainvillier from './CalendrierBouganvillier';


const localizer = momentLocalizer(moment);

function Calendrier() {
   
  //pour recupere et filtrer calender
    const [eventsAzale, setEventsAzale] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [newEvent, setNewEvent] = useState('');
    const [blocked, setBlocked] = useState(false)
    // usestate pour incrimente le calendrie dans DB
    const [resaCalend,setResaCalend]=useState({})
    const dispatch=useDispatch()
    

    const [iconsActive, setIconsActive] = useState('tab1');
      
    const handleIconsClick = (value) => {
      if (value === iconsActive) {
        return;
      }
  
      setIconsActive(value);
    };
    
    useEffect(() => {
      // Fonction pour récupérer les événements depuis le backend
      const fetchEvents = async () => {
        try {
          const response = await axios.get('/api/calender/getallresacalend');
          const eventsData = response.data.events;
          
          // Transformer les données d'événements si nécessaire (par exemple, convertir les dates au format JavaScript)
          const formattedEvents = eventsData
            .filter(event => event.villa === "Tralala") // Filtrer les événements pour les villas nommées "azalé"
            .map(event => ({
              start: new Date(event.start),
              end: new Date(event.end),
              title: event.title,
              blocked: event.blocked,
              backgroundColor: event.blocked ? 'red' : null,
              borderColor: event.blocked ? 'red' : null,
              textColor: event.blocked ? 'white' : null
            }));
          
          // Mettre à jour l'état avec les événements récupérés depuis le backend
          setEventsAzale(formattedEvents);
        } catch (error) {
          console.error('Erreur lors de la récupération des événements :', error);
        }
      };
    
      // Appeler la fonction pour récupérer les événements au chargement du composant
      fetchEvents();
    }, []);
    
    const handelResaCalendTralala=(e)=>
      { 
        const { name, value, type, checked } = e.target;

          // Ajouter la propriété 'villa' avec la valeur 'chambre hote tralala'
          const updatedResaCalend = {
            ...resaCalend,
            [name]: type === 'checkbox' ? checked : value,
            villa: 'Tralala'
          };

          setResaCalend(updatedResaCalend);
      
      }
    


    const handleSubmit = async (event) => {

        event.preventDefault();
        await dispatch(addResaCalend(resaCalend))

    };
    const ajouterEvenementIntervalle = () => {
        const datesInRange = getDatesInRange(startDate, endDate);
        const newEvents = datesInRange.map(date => ({
            start: date,
            end: date,
            title: newEvent
        }));
        setEventsAzale(prevEvents => [...prevEvents, ...newEvents]);
        console.log(newEvents)
        setNewEvent('');
    };

    const getDatesInRange = (start, end) => {
        const dates = [];
        const currentDate = new Date(start);
        while (currentDate <= end) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };

    return (
        <div className="calendrier-container">

                  <header>
                            <MDBNavbar expand='lg' light bgColor='white' sticky>
                              <MDBContainer fluid style={{justifyContent:'center'}}>
                                
                                {/* Importation de narbar component  */}
                                <NavbarLogout/>

                              </MDBContainer>
                            </MDBNavbar>

                            {/* Contenu de la page contactez-nous en dissous en ferme avec </header */}

                            <MDBTabs className='mb-3' style={{padding:'20px'}}>
        <MDBTabsItem>
          <MDBTabsLink onClick={(e) => handleIconsClick('tab1')} active={iconsActive === 'tab1'}>
            <MDBIcon fas icon='calendar-alt' className='me-2' /> Calendrier Tralala
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={(e) => handleIconsClick('tab2')} active={iconsActive === 'tab2'}>
          <MDBIcon fas icon='calendar-alt' className='me-2' /> Calendrier Rock The Kasbah
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab3')} active={iconsActive === 'tab3'}>
          <MDBIcon fas icon='calendar-alt' className='me-2' /> Calendrier Azalée
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab4')} active={iconsActive === 'tab4'}>
          <MDBIcon fas icon='calendar-alt' className='me-2' /> Calendrier Casablanca
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick('tab5')} active={iconsActive === 'tab5'}>
          <MDBIcon fas icon='calendar-alt' className='me-2' /> Calendrier Bougainvilliers
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
      <MDBTabsContent>
        <MDBTabsPane open={iconsActive === 'tab1'}>

        <MDBCard alignment='center'>
                              
                              <MDBCardBody>
                              <form  onSubmit={handleSubmit}>
                              <MDBInput name='start' className='mb-4' type='date' value={startDate.toISOString().split('T')[0]} onChange={(e) => {setStartDate(new Date(e.target.value)); handelResaCalendTralala(e)}} label='Date de début :' />

                              <MDBInput name='end' className='mb-4' type='date' value={endDate.toISOString().split('T')[0]} onChange={(e) => {setEndDate(new Date(e.target.value)); handelResaCalendTralala(e)}} label='Date de fin :' />
                              

                              <MDBInput name='title' className='mb-4' type='text' value={newEvent} onChange={(e) => {setNewEvent(e.target.value) ; handelResaCalendTralala(e)}} label='Détail Réservation Confirmé || Periode A Bloquer(Villa, Client etc...) :' />
                              Vous devez l'activer si vous voulez bloque une periode
                              <MDBSwitch name='blocked' type="checkbox" label='' checked={blocked} onChange={(e) => {setBlocked(e.target.checked) ; handelResaCalendTralala(e)}} />

                              <hr className="hr hr-blurry" />

                              <MDBBtn onClick={(e)=>{ajouterEvenementIntervalle();  handleSubmit(e)}} >
                              Ajouter Détail Réservation
                              </MDBBtn>
                            </form>
                              </MDBCardBody>
                              
                            </MDBCard>
                            <div className="calendar-wrapper" style={{padding:'5px',margin:'5px'}}>
                              <Calendar
                                  localizer={localizer}
                                  events={eventsAzale}
                                  startAccessor="start"
                                  endAccessor="end"
                                  style={{ height: 500 }}
                              />
                          </div>
                  
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab2'}>
          <CalendrierRTK/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab3'}>
        <CalendrierAzale/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab4'}>
          <CalendrierCasablanca/>
        </MDBTabsPane>
        <MDBTabsPane open={iconsActive === 'tab5'}>
          <CalendrierBougainvillier/>
        </MDBTabsPane>
      </MDBTabsContent>

                            
                            

                            
      
                            

                          

    
     </header>
        </div>
    );
}

export default Calendrier;
