import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Footer = () => {
  return (
    <div>
      <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center  p-4 border-bottom'>
        

        <div>
          <a href='https://www.facebook.com/edenresort.fr/' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://www.google.com/search?q=edenresort.fr&sca_esv=e2999e5afe90a665&sxsrf=ADLYWIKCgC2jhy7MuLiuuTtQoiWPOuS7xw%3A1719499427619&source=hp&ei=o3p9Zo39I66ckdUPmu-ukAE&iflsig=AL9hbdgAAAAAZn2Isy4aPjbpjZEUau1Cb03PefRmGAdw&ved=0ahUKEwjN2srEgvyGAxUuTqQEHZq3CxIQ4dUDCA0&uact=5&oq=edenresort.fr&gs_lp=Egdnd3Mtd2l6Ig1lZGVucmVzb3J0LmZyMgQQIxgnMgkQABiABBgTGA0yCBAAGBMYDRgeMggQABgTGA0YHkjGMlDHDFibL3ABeACQAQCYAYwBoAHaC6oBBDIuMTG4AQPIAQD4AQGYAg6gApAMqAIKwgIHECMYJxjqAsICChAjGIAEGCcYigXCAggQLhiABBixA8ICDhAuGIAEGLEDGIMBGIoFwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAAGIAEwgIOEAAYgAQYsQMYgwEYigXCAg4QLhiABBixAxjRAxjHAcICBRAuGIAEwgIREC4YgAQYxwEYmAUYmQUYrwHCAggQABiABBjLAcICBxAAGIAEGArCAgoQABiABBgKGMsBwgITEC4YgAQYxwEYmAUYmQUYChivAcICBhAAGA0YHsICBhAAGAoYHpgDBZIHBDIuMTKgB5mOAQ&sclient=gws-wiz' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          {/* <a href='https://www.instagram.com/villas_eden.djerba/' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a> */}
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                EEDEN Resort
              </h6>
              <p>
              Bienvenue chez Eden Resort, votre passerelle vers des aventures extraordinaires en Tunisie où terre et mer se rencontrent pour créer des souvenirs inoubliables. 
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Villas</h6>
              <p>
                <a href='/azalee' className='text-reset'>
                  Azalée
                </a>
              </p>
              <p>
                <a href='/bougainvillier' className='text-reset'>
                  Bougainvilliers
                </a>
              </p>
              <p>
                <a href='/jasmin' className='text-reset'>
                  Casablanca
                </a>
              </p>
              <p>
                <a href='/rock' className='text-reset'>
                  Rock The Kasbah
                </a>
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Chambres D'hotes</h6>
              <p>
                <a href='/tralala' className='text-reset'>
                Dar Tralala
                </a>
              </p>
              <p>
                <a href='/rock' className='text-reset'>
                  Rock The Kasbah
                </a>
              </p>
              <p>
                <a href='/jasmin' className='text-reset'>
                  Casablanca
                </a>
              </p>
              </MDBCol>

              {/* on elimine bateau suite demande catherine */}

            {/* <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Bateau</h6>
              <p>
                <a href='/rita' className='text-reset'>
                  RITA
                </a>
              </p>
             
            </MDBCol> */}

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                Centre Meninx Midoun
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                contact@edenresort.fr
              </p>
              <p>
              <MDBIcon icon="phone" className="me-3"/> + 216 70 026 599
              </p>
              <p>
                <MDBIcon fas icon="mobile-alt" className="me-3" /> + 33 01 86 47 13 19
              </p>
              <p>
                <MDBIcon fas icon="mobile-alt" className="me-3" /> + 33 03 29 27 03 75
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
      Copyright 2024 @ EdenResort.fr. Tous droits réservés.
      </div>
    </MDBFooter>
    </div>
  )
}

export default Footer