import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import moment from 'moment';

const ToutResevation = () => {
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await axios.get('/api/booking/get_resa');
        if (response.data && Array.isArray(response.data.reservation)) {
          // Formater les dates et soldacompt avant de les stocker dans le state
          const formattedReservations = response.data.reservation.map(reservation => {
            const formattedSousAcompte = reservation.sousAcompte.map(sousAcompte => ({
              ...sousAcompte,
                // Formater la date datacompt si nécessaire
                datacompt: sousAcompte.datacompt ? moment(sousAcompte.datacompt).format('DD/MM/YYYY') : sousAcompte.datacompt,
                // Arrondir le acompt à deux chiffres après la virgule
                acompt: sousAcompte.acompt ? sousAcompte.acompt.toFixed(2) : sousAcompte.acompt,
                // Arrondir le soldacompt à deux chiffres après la virgule si nécessaire
                soldacompt: sousAcompte.soldacompt ? sousAcompte.soldacompt.toFixed(2) : sousAcompte.soldacompt,
              }));

              const formattedSousOC = reservation.sousOC.map(sousOC => ({
                ...sousOC,
                // Formater la date datOC si nécessaire
                datOC: sousOC.datOC ? moment(sousOC.datOC).format('DD/MM/YYYY') : sousOC.datOC,
                // Arrondir le payemenOC à deux chiffres après la virgule si nécessaire
                payemenOC: sousOC.payemenOC ? sousOC.payemenOC.toFixed(2) : sousOC.payemenOC,
                // Arrondir le montantOC à deux chiffres après la virgule si nécessaire
                montantOC: sousOC.montantOC ? sousOC.montantOC.toFixed(2) : sousOC.montantOC,
                // Arrondir le soldOC à deux chiffres après la virgule si nécessaire
                soldOC: sousOC.soldOC ? sousOC.soldOC.toFixed(2) : sousOC.soldOC,
              }));
              


            return {
              ...reservation,
              dateR: moment(reservation.dateR).format('DD/MM/YYYY'), // Formater la date de réservation
              dateA: moment(reservation.dateA).format('DD/MM/YYYY'), // Formater la date d'arrivée
              dateD: moment(reservation.dateD).format('DD/MM/YYYY'), // Formater la date de départ
              dateRVT: moment(reservation.dateRVT).format('DD/MM/YYYY'), // Formater la date de virement final
              dateEC: moment(reservation.dateEC).format('DD/MM/YYYY'), // Formater la date de envois contrat
              dateRC: moment(reservation.dateRC).format('DD/MM/YYYY'), // Formater la date de recevoir contrat
              totalL : reservation.totalL.toFixed(2),
              Comsion : reservation.Comsion.toFixed(2),
              TACommi : reservation.TACommi.toFixed(2),
              sousAcompte: formattedSousAcompte,
              sousOC : formattedSousOC,
            };
          });
          setReservations(formattedReservations);
        } else {
          console.error('Data received is not in the expected format:', response.data);
        }
      } catch (error) {
        console.error('Error fetching reservations:', error);
      }
    };

     // Vérifiez si les réservations ont déjà été chargées avant de charger à nouveau
     if (reservations.length === 0) {
      fetchReservations();
    }
  }, [reservations]);

  return (
    <div>
      <MDBTable striped>
        <MDBTableHead dark>
          <tr>
            <th scope='col'>Index</th>
            <th scope='col'>ID/Fac</th>
            <th scope='col'>Villa</th>
            <th scope='col'>Locateur</th>
            <th scope='col'>Nationnalité</th>
            <th scope='col'>NB Personne</th>
            <th scope='col'>Plateforme</th>
            <th scope='col'>Date Resa</th>
            <th scope='col'>Date Arriver</th>
            <th scope='col'>Date Départ</th>
            <th scope='col'>NB Nuits</th>
            <th scope='col'>Code</th>
            <th scope='col'>Total €</th>
            <th scope='col'>Commission €</th>
            <th scope='col'>Total Final €</th>
            <th scope='col'>Virement Final</th>
            <th scope='col'>Détails</th>
            <th scope='col'>des</th>
            <th scope='col'>acomptes</th>
            <th scope='col'>faites</th>
            <th scope='col'>par</th>
            <th scope='col'>client</th>
            <th scope='col'>Etat dossier</th>
            <th scope='col'>Détails </th>
            <th scope='col'>des</th>
            <th scope='col'>options</th>
            <th scope='col'>choisis</th>
            <th scope='col'></th>
            <th scope='col'></th>
            <th scope='col'>Date Enovois Contrat</th>
            <th scope='col'>Date Recevoir Contrat</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {reservations.map((reservation, index) => (
            <tr key={index}>
              <th scope='row'>{index + 1}</th>
              <td>{reservation.idresa}</td>
              <td>{reservation.villa}</td>
              <td>{reservation.nomL}</td>
              <td>{reservation.nat}</td>
              <td>{reservation.nbP}</td>
              <td>{reservation.siteR}</td>
              <td>{reservation.dateR}</td>
              <td>{reservation.dateA}</td>
              <td>{reservation.dateD}</td>
              <td>{reservation.nbN}</td>
              <td>{reservation.codL}</td>
              <td>{reservation.totalL}</td>
              <td>{reservation.Comsion}</td>
              <td>{reservation.TACommi}</td>
              <td>{reservation.dateRVT}</td>
              {/* Render sousAcompte in separate cells */}
              <td colSpan={6}>
                {reservation.sousAcompte.map((sousAcompte, i) => (
                  <div key={i}>
                    <strong>Acompte €:</strong> {sousAcompte.acompt}, <strong>BQE:</strong> {sousAcompte.bqacompt}, <strong>Date:</strong> {sousAcompte.datacompt}, <strong>Solde:</strong> {sousAcompte.soldacompt}
                  </div>
                ))}
              </td>
              <td>{reservation.dossiefini}</td>
              {/* Render all sousOC entries in one cell */}
              <td colSpan={6}>
                {reservation.sousOC.map((sousOC, i) => (
                  <div key={i}>
                    <strong>OC:</strong> {sousOC.OC}, <strong>Montant €:</strong> {sousOC.montantOC}, <strong>Date:</strong> {sousOC.datOC}, <strong>Payement:</strong> {sousOC.payemenOC}, <strong>BQE:</strong> {sousOC.bqOC}, <strong>Solde €:</strong> {sousOC.soldOC}
                  </div>
                ))}
              </td>
              <td>{reservation.dateEC}</td>
              <td>{reservation.dateRC}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

export default ToutResevation;

