import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardFooter, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import React from 'react'
import image from '../../images/loading.jpg'

const ShowroomRTK = () => {
  return (

    <div className='container'> 
      <div className='row' style={{ gap: '10px', paddingTop:'20px' }}> 
        <div className='col-xl-3'> {/* Pour les grands écrans, divise en 2 colonnes */}
          <MDBCard className='mb-3'>
            <MDBCardImage position='top' src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_600,w_800/v1715775992/rock/salljardin_ah1vwh.jpg' alt='...' />
            <MDBCardBody>
              <MDBCardTitle>Rock The Kasbah</MDBCardTitle>
              <MDBCardTitle>Villa architecte ... tout est à vendre</MDBCardTitle>
              <MDBCardText>
                Décoration intérieure et extérieure unique par créateurs et designers. Décoration, objets, meubles... Si la maison vous plaît, vous pouvez l'acheter.
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </div>

        <div className='col-xl-7'>
          <MDBRow>
            <MDBCol xl={6} className='mb-4'>
              <MDBCard>
                <MDBCardBody>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={image}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                        className='rounded-circle'
                      />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>Transats</p>
                        <MDBBadge pill color='success' light>
                          Disponible
                        </MDBBadge>
                      </div>
                    </div>
                  </div>
                  <MDBCardFooter background='light' border='0' className='p-2 d-flex justify-content-around'>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                      <MDBIcon fas icon='phone' /> + 216 70 026 599
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
      
            <MDBCol xl={6} className='mb-4'>
              <MDBCard>
                <MDBCardBody>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={image}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                        className='rounded-circle'
                      />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>Parasols</p>
                        <MDBBadge pill color='success' light>
                          Disponible
                        </MDBBadge>
                      </div>
                    </div>
                  </div>
                  <MDBCardFooter background='light' border='0' className='p-2 d-flex justify-content-around'>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                      <MDBIcon fas icon='phone' /> + 216 70 026 599
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

      <MDBCol xl={6} className='mb-4'>
              <MDBCard>
                <MDBCardBody>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={image}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                        className='rounded-circle'
                      />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>Serviette</p>
                        <MDBBadge pill color='success' light>
                          Disponible
                        </MDBBadge>
                      </div>
                    </div>
                  </div>
                  <MDBCardFooter background='light' border='0' className='p-2 d-flex justify-content-around'>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                    commandez
                    </MDBBtn>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                      <MDBIcon fas icon='phone' /> + 216 70026599
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

      <MDBCol xl={6} className='mb-4'>
              <MDBCard>
                <MDBCardBody>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={image}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                        className='rounded-circle'
                      />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>Matlas</p>
                        <MDBBadge pill color='success' light>
                          Disponible
                        </MDBBadge>
                      </div>
                    </div>
                  </div>
                  <MDBCardFooter background='light' border='0' className='p-2 d-flex justify-content-around'>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                    commandez
                    </MDBBtn>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                      <MDBIcon fas icon='phone' /> + 216 70026599
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

      <MDBCol xl={6} className='mb-4'>
              <MDBCard>
                <MDBCardBody>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={image}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                        className='rounded-circle'
                      />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>Décoration intérieure</p>
                        <MDBBadge pill color='success' light>
                          Disponible
                        </MDBBadge>
                      </div>
                    </div>
                  </div>
                  <MDBCardFooter background='light' border='0' className='p-2 d-flex justify-content-around'>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                    commandez
                    </MDBBtn>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                      <MDBIcon fas icon='phone' /> + 216 70026599
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>

      <MDBCol xl={6} className='mb-4'>
              <MDBCard>
                <MDBCardBody>
                  <div className='d-flex justify-content-between align-items-center'>
                    <div className='d-flex align-items-center'>
                      <img
                        src={image}
                        alt=''
                        style={{ width: '100px', height: '100px' }}
                        className='rounded-circle'
                      />
                      <div className='ms-3'>
                        <p className='fw-bold mb-1'>Décoration extérieure</p>
                        <MDBBadge pill color='success' light>
                          Disponible
                        </MDBBadge>
                      </div>
                    </div>
                  </div>
                  <MDBCardFooter background='light' border='0' className='p-2 d-flex justify-content-around'>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                    commandez
                    </MDBBtn>
                    <MDBBtn color='link' rippleColor='primary' className='text-reset m-0'>
                      <MDBIcon fas icon='phone' /> + 216 70026599
                    </MDBBtn>
                  </MDBCardFooter>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
 
            </MDBRow>
                </div>
              </div>
            </div>

    

    
  )
}

export default ShowroomRTK