import React from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'


import { MDBBtn, MDBBtnGroup, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';







const TralalaSearch = () => {




  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657998/tralala/pause_caf%C3%A9_kmou5d.jpg',
      alt: 'Test alt 1',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/2.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657994/tralala/chambre_2_vue_piscine_mxeeqs.jpg',
      alt: 'Test alt 2',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/3.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657996/tralala/douche_honvlk.jpg',
      alt: 'Test alt 3',
    },
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657990/tralala/chambre_1_suite_1_dgo1ut.jpg',
      alt: 'Test alt 4',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657988/tralala/chambre_1_lr836r.jpg',
      alt: 'Test alt 5',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657987/tralala/vue_piscine_soir_lotvrg.jpg',
      alt: 'Test alt 6',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657991/tralala/vue_piscine_soir3_bngnmd.jpg',
      alt: 'Test alt 7',
    },
    
    {
      thumbnail: 'https://mdbcdn.b-cdn.net/img/Photos/Thumbnails/Slides/4.webp',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1712657986/tralala/piscine_soir_bpdxxp.jpg',
      alt: 'Test alt 8',
    },
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};


 
  return (
    <div >
      

                   
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>

                             {/* gallerie component elle entour que l'image */}
                             <LightGallery
                                onInit={onInit}
                                speed={100}
                                plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                            >

                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710249805/vue_piscine_soir2_krr8q4.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>

                                  {carouselPhotos.map((image, index) => {
                                return (
                                  
                                    <a href={image.src} key={index} >
          
                                    </a>
        
                                )
                            })}

                          </LightGallery>


                            <MDBCardBody>
                              <MDBCardTitle>Chambre d'hôte Tralala</MDBCardTitle>
                              <MDBCardTitle style={{fontSize:'15px',color:'red'}}>Apartir de 220 € / Nuit </MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de cette villa en cliquant sur la photo
                              </MDBCardText>
                              <div style={{display:'flex', gap:'10px', flexWrap:'wrap'}}>
                              <MDBBtn color='success'>Disponible</MDBBtn>
                              <MDBBtn className='mx-2' color='info'><Link to='/tralala'>Réserver</Link></MDBBtn>
                              <MDBBtnGroup shadow='0'>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="users" /> 2 Pax
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="bed" /> 1 Chambres
                                </MDBBtn>
                                <MDBBtn color='secondary'>
                                <MDBIcon fas icon="chart-area" /> 300m²
                                </MDBBtn>
                              </MDBBtnGroup>
                              </div>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      


                  
    </div>
  )
}

export default TralalaSearch