
import { Route, Routes } from 'react-router-dom';
import './App.css';
import Acceuil from './pages/Acceuil';
import NosVillas from './pages/NosVillas';
import CommentcaMarch from './pages/CommentcaMarch';
import ContactezNous from './pages/ContactezNous';
import Tarif from './pages/Tarif';
import Reglement from './pages/Reglement';
import Azale from './pages/Villas/Azale';
import Jasmin from './pages/Villas/Jasmin';
import Tralala from './pages/Villas/Tralala';
import Rock from './pages/Villas/Rock';
import Bougainvilliers from './pages/Villas/Bougainvilliers';
import Rita from './pages/Bateau/Rita';
import Connecter from './pages/Connecter';
import { useDispatch, useSelector} from 'react-redux';
import { useEffect } from 'react';
import { current } from './redux/actions/userActions';
import ResertionAjout from './pages/Admin/ResertionAjout';
import TravauxAlert from './components/TravauxAlert';
import Calendrier from './pages/Admin/Calendrier';
import Notfound from './components/Notfound';
import AccesDenied from './components/AccesDenied';
import Dashbord from './pages/Admin/Dashbord';
import Tarifs from './pages/Admin/Tarifs';
import VillasShowroom from './pages/VillasShowroom';
import Avis from './pages/Avis';
import Partenariat from './pages/Partenariat';




// import Spinner from './components/Spinner';





function App() {

  const dispatch=useDispatch()
  // Vérifie si l'utilisateur est connecté
  const user = useSelector(state => state.userReducer.user);
  // const load=useSelector((state)=>state.userReducer.load  )

  
  var token=localStorage.getItem("token")

  useEffect(() => {
    if(token){
      dispatch(current())
  
    }
  },[])

  
  

  return (
    <div className="App">
      <Routes>
        <Route>
          <Route path="/" element={<Acceuil/>} />
          <Route path="/nosvilla" element={<NosVillas />} />
          <Route path="/commentsamarche" element={<CommentcaMarch />} />
          <Route path="/tarif" element={<Tarif />} />
          <Route path="/reglement" element={<Reglement />} />
          <Route path="/contact" element={<ContactezNous />} />
          <Route path="/connecte" element={<Connecter />} />
          <Route path="/azalee" element={<Azale />} />
          <Route path="/bougainvillier" element={<Bougainvilliers />} />
          <Route path="/jasmin" element={<Jasmin />} />
          <Route path="/tralala" element={<Tralala />} />
          <Route path="/rock" element={<Rock />} />
          <Route path="/rita" element={<Rita />} />
          <Route path="/dashbord" element={<Dashbord />} />
          <Route path="/showroom" element={<VillasShowroom />} />
          <Route path="/avis" element={<Avis />} /> 
          <Route path="/nospartenaire" element={<Partenariat />} />
          
          {/* <Route path="/dispo" element={<Disponible />} /> */}
          
          
          
           {/* Utilisation de rendu conditionnel pour les routes privées */}
           {user && (
              <>
                {user.typeaccount === "Admin" && (
                  <>
                    <Route path="/resadd" element={<ResertionAjout />} />
                    <Route path="/gestionTarifs" element={<Tarifs />} />
                    <Route path="/travauxsite" element={<TravauxAlert />} />
                    <Route path="/calender" element={<Calendrier />} />
                  </>
                )}
                {user.typeaccount === "RH" && (
                  <>
                    <Route path="/resadd" element={<ResertionAjout />} />
                    <Route
                      path="/calender"
                      element={user.typeaccount === "RH" ? <AccesDenied /> : <Calendrier />}
                    />
                    <Route
                      path="/travauxsite"
                      element={user.typeaccount === "RH" ? <AccesDenied /> : <Calendrier />}
                    />
                  </>
                )}
                {user.typeaccount === "Contact" && (
                  <>
                    <Route path="/calender" element={<Calendrier />} />
                    <Route
                      path="/resadd"
                      element={user.typeaccount === "Contact" ? <AccesDenied /> : <ResertionAjout />}
                    />
                    <Route
                      path="/travauxsite"
                      element={user.typeaccount === "Contact" ? <AccesDenied /> : <Calendrier />}
                    />
                  </>
                )}
                {(user.typeaccount !== "Admin" && user.typeaccount !== "RH" && user.typeaccount !== "Contact") && (
                  <Route path="*" element={<Notfound />} />
                )}
              </>
            )}

          
        </Route>
      </Routes>
      
    </div>
  );
}

export default App;
