import React from 'react'
import Navbar from '../components/Navbar'
import BookCCM from '../components/Book/BookCCM'
import Footer from '../components/Footer'
import { MDBContainer, MDBNavbar } from 'mdb-react-ui-kit'
import { Helmet } from 'react-helmet-async'


const CommentcaMarch = () => {
  return (
    <div>

      <Helmet>
        <title>Comment ça marche</title>
        <meta name="description" content="Page comment ça marche"/>
      </Helmet>

      <header>
      <MDBNavbar expand='lg' light bgColor='white' sticky>
        <MDBContainer fluid style={{justifyContent:'center'}}>
          
          {/* Importation de narbar component */}
          <Navbar/>

        </MDBContainer>
      </MDBNavbar>

      {/* Importation des component */}
      
      <BookCCM/>
      <Footer/>
      

    </header>

    </div>
  )
}

export default CommentcaMarch