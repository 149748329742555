// src/App.js
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const Statistique = () => {
  const [reservationData, setReservationData] = useState([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await fetch('/api/booking/get_resa'); // Remplacez par l'URL de votre API
        const data = await response.json();
        setReservationData(data.reservation);
      } catch (error) {
        console.error('Erreur lors de la récupération des données de réservation:', error);
      }
    };

    fetchReservations();
  }, []);

  // Function to filter and group data by month for a specific villa
  const getChartDataForVilla = (villaName) => {
    const filteredData = reservationData.filter(reservation => reservation.villa === villaName);
    const monthlyData = {};
    filteredData.forEach(reservation => {
      const month = new Date(reservation.dateA).toLocaleString('default', { month: 'long' });
      if (!monthlyData[month]) {
        monthlyData[month] = 0;
      }
      monthlyData[month] += reservation.TACommi;
    });

    const labels = Object.keys(monthlyData);
    const data = Object.values(monthlyData);

    return {
      labels,
      datasets: [{
        label: `CA ${villaName} par mois en €`,
        data,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1
      }]
    };
  };

  // Function to filter and group data by month for a specific villa
  const getNBNuitDataForVilla = (villaName) => {
    const filteredData = reservationData.filter(reservation => reservation.villa === villaName);
    const monthlyData = {};
    filteredData.forEach(reservation => {
      const month = new Date(reservation.dateA).toLocaleString('default', { month: 'long' });
      if (!monthlyData[month]) {
        monthlyData[month] = 0;
      }
      monthlyData[month] += reservation.nbN;
    });

    const labels = Object.keys(monthlyData);
    const data = Object.values(monthlyData);

    return {
      labels,
      datasets: [{
        label: `Nombre de nuit par ${villaName}`,
        data,
        backgroundColor: 'grey',
        borderColor: 'grey',
        borderWidth: 1
      }]
    };
  };

  // Function to filter and group data by year for a specific villa
  const getYearlyChartDataForVilla = (villaName) => {
    const filteredData = reservationData.filter(reservation => reservation.villa === villaName);
    const yearlyData = {};
    filteredData.forEach(reservation => {
      const year = new Date(reservation.dateA).getFullYear();
      if (!yearlyData[year]) {
        yearlyData[year] = 0;
      }
      yearlyData[year] += reservation.TACommi;
    });

    const labels = Object.keys(yearlyData);
    const data = Object.values(yearlyData);

    return {
      labels,
      datasets: [{
        label: `CA ${villaName} par an en €`,
        data,
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
        borderColor: 'rgba(153, 102, 255, 1)',
        borderWidth: 1
      }]
    };
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  };

  const villas = ["RTK", "Azalée", "Bougainvilliers", "Casablanca", "Tralala"];

  return (
    <div className="App">
      <header className="App-header" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
        <h2 style={{ textAlign: 'center', width: '100%',color:'green' }}>Chiffre d'affaire Mensuel par Villa</h2>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
          {villas.map(villa => (
            <div key={villa} style={{ flex: '1 1 18%', maxWidth: '18%', minWidth: '250px' }}>
              <h5 style={{ textAlign: 'center',color:'green' }}>CA - {villa}</h5>
              <Bar data={getChartDataForVilla(villa)} options={options} />
            </div>
          ))}
        </div>

        <hr className="hr hr-blurry" />
        
        <h2 style={{ textAlign: 'center', width: '100%',color:'grey' }}>Nombre de nuit Mensuel par Villa</h2>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
          {villas.map(villa => (
            <div key={villa} style={{ flex: '1 1 18%', maxWidth: '18%', minWidth: '250px' }}>
              <h5 style={{ textAlign: 'center',color:'grey' }}>CA - {villa}</h5>
              <Bar data={getNBNuitDataForVilla(villa)} options={options} />
            </div>
          ))}
        </div>

        <hr className="hr hr-blurry" />

        <h1 style={{ textAlign: 'center', width: '100%',color:'magenta' }}>Chiffre d'affaire Annuel par Villa </h1>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
          {villas.map(villa => (
            <div key={villa} style={{ flex: '1 1 18%', maxWidth: '18%', minWidth: '250px' }}>
              <h4 style={{ textAlign: 'center',color:'magenta' }}>CA - {villa}</h4>
              <Bar data={getYearlyChartDataForVilla(villa)} options={options} />
            </div>
          ))}
        </div>
      </header>
    </div>
  );
}

export default Statistique;
