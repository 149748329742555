import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBIcon, MDBNavbar, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet-async'
import {  useDispatch, useSelector } from 'react-redux'
import { getTarifs } from '../redux/actions/prixActions'


const Tarif = () => {
  const dispatch = useDispatch();
  const tarifs = useSelector(state => state.prixReducer.prix);
 
  
  useEffect(() => {
    dispatch(getTarifs()); 
  },[dispatch]);



  

  return (
    <div>

      <Helmet>
        <title>Tarif</title>
        <meta name="description" content="Tarif"/>
      </Helmet>

          <header>
                <MDBNavbar expand='lg' light bgColor='white' sticky>
                  <MDBContainer fluid style={{justifyContent:'center'}}>
                    
                    {/* Importation de narbar component */}
                    <Navbar/>

                  </MDBContainer>
                </MDBNavbar>

                {/* Contenu de page Tafi en dessous en ferme avec </header> */}

               

    
      <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                          <MDBCardBody >
                              <MDBCardTitle style={{display:'flex',justifyContent:'center'}}><MDBTypography tag='h1'>Tarifs Villas Par Saison 2024</MDBTypography></MDBCardTitle>
                              <br></br>
                            <br></br>
                              <MDBCardText style={{display:'flex',justifyContent:'center'}}>
                              
                            <div>
                            <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />16 juin au 14 septembre.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />1° avril au 15 juin.
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />15 septembre au 31 octobre.
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />15 décembre au 15 janvier.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />16 janvier au 31 mars.
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />1er novembre au 14 décembre.
                            
                              </div>
                              </MDBCardText>
                              
                            </MDBCardBody>

                            <MDBCard style={{margin:'20px'}}>
                              <MDBCardTitle style={{display:'flex', justifyContent:'center'}}>Règles</MDBCardTitle>
                              <MDBCardBody>
                                
                                <MDBCardText style={{display:'flex',justifyContent:'center'}}> 
                                <div>
                                <MDBIcon far icon="calendar-alt" /><MDBTypography tag='strong'>Chambre d'Hôtes: </MDBTypography>
                                2 jours minimum.
                                <br></br>
                                <MDBIcon far icon="calendar-alt" /><MDBTypography tag='strong'>Location: </MDBTypography>
                                4 jours minimum sauf juillet et août avec 7 jours minimum.                         
                                <hr className="hr hr-blurry" />
                                <MDBIcon fas icon="money-check" /><MDBTypography tag='strong'>Caution du séjour: </MDBTypography>
                                1 800 € par chèque bancaire ou empreinte carte non encaissé.
                                </div>
                              </MDBCardText>
                                
                              </MDBCardBody>
                            </MDBCard>

                          </MDBCard>
                        </MDBCol>
                  </MDBRow>

                  <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{margin:'20px',display:'flex',flexWrap:'wrap', justifyContent: 'center'}}>
                    <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246380/vue_de_face_petit_piscine_-_Copie_yx4oey.jpg'
                          alt=''
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>Villa Azalée</MDBCardTitle>
                          <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 8 pax par semaine ( Pax suplémentaire en extra )</MDBCardTitle>
                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>                           
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVAHS} € / Semaine minimum.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVAMS} € / Semaine.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVABS} € / Semaine.
                              <hr className="hr hr-blurry" />
                              <MDBIcon fas icon="lightbulb" className='me-2 text-info' />0,15 € le Kwh APRES UN FORFAIT INCLUS DANS LA LOCATION DE 300 Kwh.
                              </div>
                              )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710246541/piscine_rdgisj.jpg'
                          alt=''
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>Villa Bougainvilliers</MDBCardTitle>
                          <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 6 pax par semaine ( Pax suplémentaire en extra )</MDBCardTitle>
                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVBHS} € / Semaine minimum.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVBMS} € / Semaine.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVBBS} € / Semaine.                           
                              </div>
                              )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711547478/jasmin/piscine3_djscu8.jpg'
                          alt='...'
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>Villa Casablanca</MDBCardTitle>
                          <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 6 pax par semaine ( Pax suplémentaire en extra )</MDBCardTitle>
                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVCHS} € / Semaine minimum.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVCMS} € / Semaine.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVCBS} € / Semaine.
                              <hr className="hr hr-blurry" />
                              <MDBIcon fas icon="lightbulb" className='me-2 text-info' />0,15 € le Kwh APRES UN FORFAIT INCLUS DANS LA LOCATION DE 300 Kwh.
                              </div>
                          )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711962832/vue_piscine2_og0cxd.jpg'
                          alt=''
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>Villa Rock The Kasbah</MDBCardTitle>
                          <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 8 pax ( Pax suplémentaire en extra )</MDBCardTitle>
                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVRTKHS} € / Semaine.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVRTKMS} € / Semaine.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifVRTKBS} € / Semaine.
                              <hr className="hr hr-blurry" />
                              <MDBIcon fas icon="lightbulb" className='me-2 text-info' />0,15 € le Kwh APRES UN FORFAIT INCLUS DANS LA LOCATION DE 500 Kwh.
                              </div>
                          )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    
                  </MDBRow>

                  <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{margin:'20px',display:'flex',flexWrap:'wrap', justifyContent: 'space-around'}}>
                    <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710249805/vue_piscine_soir2_krr8q4.jpg'
                          alt=''
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>Chambre d'hôte Tralala</MDBCardTitle>
                          <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 2 pax plus petit-déjeuner par nuit</MDBCardTitle>
                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHTHS} € / 2 jours minimum.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHTMS} € / 2 jours minimum.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHTBS} € / 2 jours minimum.
                              </div>
                          )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                    <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1711962832/vue_piscine2_og0cxd.jpg'
                          alt=''
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>Chambre d'hôte Rock The Kasbah</MDBCardTitle>

                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 4 pax plus petit-déjeuner par nuit</MDBCardTitle>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHRTKMS_SF} € / 2 jours minimum (Suite Familiale).
                            <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 2 pax plus petit-déjeuner par nuit</MDBCardTitle>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHRTKMS_VP} € / 2 jours minimum (Suite vue piscine).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHRTKMS_VJ} € / 2 jours minimum (Suite vue jardin).
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 4 pax plus petit-déjeuner par nuit</MDBCardTitle>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHRTKBS_SF} € / 2 jours minimum (Suite Familiale).
                            <hr className="hr hr-blurry" />
                          <MDBCardTitle style={{justifyContent:'center',display:'flex',fontSize:'12px',color:'skyblue'}}>Base 2 pax plus petit-déjeuner par nuit</MDBCardTitle>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHRTKBS_VP} € / 2 jours minimum (Suite vue piscine).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifCHRTKBS_VJ} € / 2 jours minimum (Suite vue jardin).
                            
                              </div>
                          )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>

                                        
                  </MDBRow>

                  {/* on elimine bateau suite demande catherine */}

                  {/* <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                          <MDBCardBody >
                              <MDBCardTitle style={{display:'flex',justifyContent:'center'}}><MDBTypography tag='h1'>Tarifs Bateau Par Saison 2024</MDBTypography></MDBCardTitle>
                              <br></br>
                            <br></br>
                              <MDBCardText style={{display:'flex',justifyContent:'center'}}>
                              
                            <div>
                            <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />01 juillet au 30 septembre.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />01 avril au 30 juin.
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />01 octobre au 31 octobre.
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />01 janvier au 30 avril.
                            <br></br>
                            <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />01 novembre au 31 décembre.
                            
                              </div>
                              </MDBCardText>
                              
                            </MDBCardBody>

                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                  <MDBRow className='row-cols-1 row-cols-md-3 g-4' style={{margin:'20px',display:'flex', justifyContent:'center'}}>
                  <MDBCol>
                      <MDBCard>
                        <MDBCardImage
                          src='https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_1300,w_1900/v1710329378/2_jrwjid.jpg'
                          alt=''
                          position='top'
                        />
                        <MDBCardBody>
                          <MDBCardTitle style={{justifyContent:'center',display:'flex'}}>TRAWLER RITA</MDBCardTitle>
                          <hr className="hr hr-blurry" />
                          <br></br>
                          <MDBCardText style={{justifyContent:'center',display:'flex'}}>

                          {tarifs && tarifs.length > 0 && ( 
                          <div>
                          <MDBIcon fas icon="sun" /><MDBTypography tag='strong'>Haute saison:</MDBTypography>
                            <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBHS_S} € / Semaine (6 Pax - Avec skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBHS_12H} € / 12 Heures maximum (8 Pax - Avec skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBHS_36H} € / 36 Heures maximum (8 Pax - Avec skipper).
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun" /><MDBTypography tag='strong'>Moyenne saison:</MDBTypography>
                            <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBMS_SAS} € / Semaine (6 Pax - Avec skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBMS_SSS} € / Semaine (6 Pax - Sans navigation et sans skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBMS_12H} € / 12 Heures maximum (8 Pax - Avec skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBMS_36H} € / 36 Heures maximum (8 Pax - Avec skipper).
                            <br></br>
                            <br></br>
                            <MDBIcon fas icon="cloud-sun-rain" /><MDBTypography tag='strong'>Basse saison:</MDBTypography>
                            <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBBS_SAS} € / Semaine (6 Pax - Avec skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBBS_SSS} € / Semaine (6 Pax - Sans navigation et sans skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBBS_12H} € / 12 Heures maximum (8 Pax - Avec skipper).
                              <br></br>
                              <MDBIcon icon='long-arrow-alt-right' className='me-2 text-info' />{tarifs[0].TarifBBS_36H} € / 36 Heures maximum (8 Pax - Avec skipper).
                              </div>
                          )}
                          </MDBCardText>
                        </MDBCardBody>
                      </MDBCard>
                    </MDBCol>
                  </MDBRow> */}

                  <Footer/>
          </header>
    </div>
  )
}

export default Tarif