import React from 'react'
import VillasDjerbien from '../components/VillasDjerbien'
import VillaLux from '../components/VillaLux'
import Footer from '../components/Footer'
import { MDBContainer, MDBNavbar } from 'mdb-react-ui-kit'
import Navbar from '../components/Navbar'
import { Helmet } from 'react-helmet-async'



const NosVillas = () => {
  
  return (
    <div>

      <Helmet>
        <title>Nos Villas</title>
        <meta name="description" content="Page nos villas"/>
      </Helmet>


       <header>
      <MDBNavbar expand='lg' light bgColor='white' sticky>
        <MDBContainer fluid style={{justifyContent:'center'}}>
          
          {/* Importation de narbar component */}
          <Navbar/>

        </MDBContainer>
      </MDBNavbar>

      {/* Importation des component */}
      
      <VillasDjerbien/>
      <VillaLux/>
      <Footer/>
      

    </header>
    
       
    </div>
  )
}

export default NosVillas