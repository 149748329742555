import React, { useState } from 'react';
import LightGallery from 'lightgallery/react';


// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-video.css'


// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgAutoplay from 'lightgallery/plugins/autoplay'
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import lgRotate from 'lightgallery/plugins/rotate';
import lgVideo from 'lightgallery/plugins/video'
import Navbar from '../../components/Navbar';

import { MDBBadge, MDBBtn, MDBCard, MDBCardBody, MDBCardImage, MDBCardOverlay, MDBCardText, MDBCardTitle, MDBCheckbox, MDBCol, MDBIcon, MDBInput, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';

import Footer from '../../components/Footer';

import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { ConfigProvider, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useNavigate } from 'react-router-dom';
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const disabledDate = (current) => {
  
  // Can not select days before today and today and other date
  return  (current && current < dayjs().endOf('day'))
  || (dayjs('2024:04:17', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:06:06', 'YYYY:MM:DD')  >= current)
   || (dayjs('2024:06:18', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:06:30', 'YYYY:MM:DD')  >= current) 
   || (dayjs('2024:07:01', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:07:11', 'YYYY:MM:DD')  >= current)
   || (dayjs('2024:07:17', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:07:27', 'YYYY:MM:DD')  >= current)
   || (dayjs('2024:08:05', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:08:19', 'YYYY:MM:DD')  >= current)
   || (dayjs('2024:08:27', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:08:31', 'YYYY:MM:DD')  >= current)
   || (dayjs('2024:09:18', 'YYYY:MM:DD')  <= current  &&  dayjs('2024:10:31', 'YYYY:MM:DD')  >= current)

   
  
};




const Azale = () => {

  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [price, setPrice] = useState(0);
  const [codepromo,setCodepro]=useState("")
  const [prenom,setPrenom]=useState("")
  const [nom,setNom]=useState("")
  const [nbpersonne,setNbpersonne]=useState(0)
  const [adresse,setAdresse]=useState("")
  const [email,setEmail]=useState("")
  const [telephone,setTelephone]=useState("")
  const [commentaire,setCommentaire]=useState("")
  const [checkedtype, setCheckedType] = useState(false)
  const [checkedopt1, setCheckedopt1] = useState(false)
  const [checkedopt2, setCheckedopt2] = useState(false)
  const [checkedopt3, setCheckedopt3] = useState(false)
  const [checkedopt4, setCheckedopt4] = useState(false)
  const [checkedopt5, setCheckedopt5] = useState(false)
  const [checkedopt6, setCheckedopt6] = useState(false)
  const [checkedopt7, setCheckedopt7] = useState(false)
  const [checkedopt8, setCheckedopt8] = useState(false)
  const [checkedopt9, setCheckedopt9] = useState(false)
  const [checkedopt10, setCheckedopt10] = useState(false)
  const [checkedopt11, setCheckedopt11] = useState(false)
  const [checkedopt12, setCheckedopt12] = useState(false)
  const [checkedopt13, setCheckedopt13] = useState(false)
  const [checkedopt14, setCheckedopt14] = useState(false)
  const navigate =useNavigate()

  
  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
        const [startDate, endDate] = dates;
        setFrom(startDate);
        setTo(endDate);

        const getSeasonPrice = (date) => {
            // Logique pour déterminer le prix en fonction de la saison
            if (
                ((dayjs('2024-01-16').isBefore(date) || dayjs('2024-01-16').isSame(date, 'day')) &&
                    (dayjs('2024-03-31').isAfter(date) || dayjs('2024-03-31').isSame(date, 'day'))) ||
                ((dayjs('2024-11-01').isBefore(date) || dayjs('2024-11-01').isSame(date, 'day')) &&
                    (dayjs('2024-12-14').isAfter(date) || dayjs('2024-12-14').isSame(date, 'day')))
            ) {
                return 1100; // Base saison
            } else if (
                ((dayjs('2024-01-01').isBefore(date) || dayjs('2024-01-01').isSame(date, 'day')) &&
                    (dayjs('2024-01-15').isAfter(date) || dayjs('2024-01-15').isSame(date, 'day'))) ||
                ((dayjs('2024-04-01').isBefore(date) || dayjs('2024-04-01').isSame(date, 'day')) &&
                    (dayjs('2024-06-15').isAfter(date) || dayjs('2024-06-15').isSame(date, 'day'))) ||
                ((dayjs('2024-09-15').isBefore(date) || dayjs('2024-09-15').isSame(date, 'day')) &&
                    (dayjs('2024-10-31').isAfter(date) || dayjs('2024-10-31').isSame(date, 'day'))) ||
                ((dayjs('2024-12-15').isBefore(date) || dayjs('2024-12-15').isSame(date, 'day')) &&
                    (dayjs('2024-12-31').isAfter(date) || dayjs('2024-12-31').isSame(date, 'day')))
            ) {
                return 1400; // Moyenne saison
            } else if (
                (dayjs('2024-06-16').isBefore(date) || dayjs('2024-06-16').isSame(date, 'day')) &&
                (dayjs('2024-09-14').isAfter(date) || dayjs('2024-09-14').isSame(date, 'day'))
            ) {
                return 1800; // Haute saison
            }
            return 1100; // Default to base saison
        };

        const seasonPrice = getSeasonPrice(startDate);
        const totalNights = endDate.diff(startDate, 'days');
        let totalPrice = seasonPrice;

        // Calcul du prix total en fonction du nombre de nuits
        if (totalNights >= 4 && totalNights <= 6) {
            totalPrice *= totalNights === 4 ? 0.65 : totalNights === 5 ? 0.75 : 0.87;
        } else if (totalNights === 7) {
            totalPrice *= 1;
        } else if (totalNights >= 8 && totalNights <= 14) {
            totalPrice *= 1.8;
        } else if (totalNights >= 15 && totalNights <= 21) {
            totalPrice *= 2.6;
        } else if (totalNights >= 22 && totalNights <= 28) {
            totalPrice *= 3.3;
        } else if (totalNights >= 29) {
            alert("Un devis sera préparé pour vous.");
            totalPrice = 0;
        }

        // Vérification et application de la remise
        let minimumNights = 4; // Minimum de nuits par défaut
        if (startDate.month() === 6 || startDate.month() === 7) { // Juillet ou août
            minimumNights = 7; // 8 jours avec 7 nuits
        }
        if (totalNights < minimumNights) {
            alert("Veuillez sélectionner une période d'au moins " + minimumNights + " nuits.");
        }

        // Application de la réduction pour le mois de juillet et avec le code promo "R10-0724"
        if (startDate.month() === 6 && codepromo === "R10-0724") { // 6 correspond à juillet (index 0)
            totalPrice *= 0.9; // Appliquer une réduction de 10%
        }

        setPrice(totalPrice);
    } else {
        // Si les dates sont nulles, réinitialiser le prix total à 0
        setPrice(0);
    }
};



 
           // pour appeler useraction et dispatche login dans onclick sur le button

            const handleSubmit = (event) => {

              try {
              event.preventDefault(); // Empêcher le rafraîchissement de la page

             // Vérifier la période
              if (!from || !to) {
                toast.error("Veuillez remplir la période.");
                return;
              }

              // Vérifier le type de logement
              if (!checkedtype) {
                toast.error("Veuillez choisir le type de logement.");
                return;
              }

              // Vérifier le prénom et le nom
              if (!prenom || !nom) {
                toast.error("Veuillez remplir votre prénom et nom.");
                return;
              }

              // Vérifier nb personne
              if (!nbpersonne) {
                toast.error("Veuillez ajouter le nombre de personne.");
                return;
              }
              // Vérifier adresse
              if (!adresse) {
                toast.error("Veuillez remplir votre adresse.");
                return;
              }

              // Vérifier l'email
              if (!email) {
                toast.error("Veuillez remplir votre email.");
                return;
              }

              // Vérifier le téléphone
              if (!telephone) {
                toast.error("Veuillez remplir votre téléphone.");
                return;
              }


  
              let dataBookSend = {
                checktype: checkedtype,
                checkedopt1: checkedopt1,
                checkedopt2: checkedopt2,
                checkedopt3: checkedopt3,
                checkedopt4: checkedopt4,
                checkedopt5: checkedopt5,
                checkedopt6: checkedopt6,
                checkedopt7: checkedopt7,
                checkedopt8: checkedopt8,
                checkedopt9: checkedopt9,
                checkedopt10: checkedopt10,
                checkedopt11: checkedopt11,
                checkedopt12: checkedopt12,
                checkedopt13: checkedopt13,
                checkedopt14: checkedopt14,
                from: from,
                to: to,
                codepromo:codepromo,
                prenom:prenom,
                nom:nom,
                nbpersonne:nbpersonne,
                totalprix: price,
                adresse:adresse,
                email:email,
                telephone: telephone,
                commentaire: commentaire
              }
              
              
              // // Perform form submission
              
                 axios.post('/api/email/sendEmailBooking', dataBookSend);
                
                    
                    toast.success("Votre demande de réservation a été envoyée avec succès.");

                        // Naviguer après un court délai
                        setTimeout(() => {
                          navigate("/nosvilla");
                        }, 5000); // 3000 millisecondes = 3 secondes
                                    
              } catch (error) {
                // Handle error
                console.error(error);
              }
             
          };

                  

  const carouselPhotos = [
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696151/Azal%C3%A9%20avec%20mes%20modif/DSC_0808_onfkn0.jpg',
      src :'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696151/Azal%C3%A9%20avec%20mes%20modif/DSC_0808_onfkn0.jpg',
      alt: 'Le lever du Soleil',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0708_es9cmp.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0708_es9cmp.jpg',
      alt: 'Petit dejeuner piscine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696161/Azal%C3%A9%20avec%20mes%20modif/DSC_0619_lkiv2d.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696161/Azal%C3%A9%20avec%20mes%20modif/DSC_0619_lkiv2d.jpg',
      alt: 'Jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0721_xe1kpn.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0721_xe1kpn.jpg',
      alt: 'Entrée principale',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0719_n90epj.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696152/Azal%C3%A9%20avec%20mes%20modif/DSC_0719_n90epj.jpg',
      alt: 'Terrasse vue jardin',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696160/Azal%C3%A9%20avec%20mes%20modif/DSC_0725_e2zy59.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696160/Azal%C3%A9%20avec%20mes%20modif/DSC_0725_e2zy59.jpg',
      alt: 'Parking',
    },
    
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696150/Azal%C3%A9%20avec%20mes%20modif/DSC_0632_kbumgk.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696150/Azal%C3%A9%20avec%20mes%20modif/DSC_0632_kbumgk.jpg',
      alt: 'Salon',
    },
    
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0571_xenmp1.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0571_xenmp1.jpg',
      alt: 'Suite la fidèle',
    },
    
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0565_iloyiq.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0565_iloyiq.jpg',
      alt: 'Suite la fidèle',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696144/Azal%C3%A9%20avec%20mes%20modif/DSC_0559_laukrt.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696144/Azal%C3%A9%20avec%20mes%20modif/DSC_0559_laukrt.jpg',
      alt: 'Douche jaccozi Suite la fidèle',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0581_tdeonu.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0581_tdeonu.jpg',
      alt: 'Suite la douce',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0579_uq1pkt.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696143/Azal%C3%A9%20avec%20mes%20modif/DSC_0579_uq1pkt.jpg',
      alt: 'Suite la douce',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696145/Azal%C3%A9%20avec%20mes%20modif/DSC_0572_dxmu8m.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696145/Azal%C3%A9%20avec%20mes%20modif/DSC_0572_dxmu8m.jpg',
      alt: 'SB Suite la douce',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696147/Azal%C3%A9%20avec%20mes%20modif/DSC_0593_cqadfz.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696147/Azal%C3%A9%20avec%20mes%20modif/DSC_0593_cqadfz.jpg',
      alt: 'Suite la belle',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696146/Azal%C3%A9%20avec%20mes%20modif/DSC_0598_scqwax.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696146/Azal%C3%A9%20avec%20mes%20modif/DSC_0598_scqwax.jpg',
      alt: 'Suite la belle',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696147/Azal%C3%A9%20avec%20mes%20modif/DSC_0592_pum1mo.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696147/Azal%C3%A9%20avec%20mes%20modif/DSC_0592_pum1mo.jpg',
      alt: 'Suite la belle',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696148/Azal%C3%A9%20avec%20mes%20modif/DSC_0588_mcjbb2.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696148/Azal%C3%A9%20avec%20mes%20modif/DSC_0588_mcjbb2.jpg',
      alt: 'SB Suite la belle',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696159/Azal%C3%A9%20avec%20mes%20modif/DSC_0687_iwzg68.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696159/Azal%C3%A9%20avec%20mes%20modif/DSC_0687_iwzg68.jpg',
      alt: 'Chambre RDC',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696158/Azal%C3%A9%20avec%20mes%20modif/DSC_0671_qvznft.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696158/Azal%C3%A9%20avec%20mes%20modif/DSC_0671_qvznft.jpg',
      alt: 'Chambre RDC',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1719161423/azal%C3%A9e/DSC_0534_drsccp.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1719161423/azal%C3%A9e/DSC_0534_drsccp.jpg',
      alt: 'SB Chambre RDC',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696149/Azal%C3%A9%20avec%20mes%20modif/DSC_0537_asrc8w.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696149/Azal%C3%A9%20avec%20mes%20modif/DSC_0537_asrc8w.jpg',
      alt: 'SB Chambre RDC',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696149/Azal%C3%A9%20avec%20mes%20modif/DSC_0538_ytjxpn.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696149/Azal%C3%A9%20avec%20mes%20modif/DSC_0538_ytjxpn.jpg',
      alt: 'SB Chambre RDC',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696156/Azal%C3%A9%20avec%20mes%20modif/DSC_0658_hejvyn.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696156/Azal%C3%A9%20avec%20mes%20modif/DSC_0658_hejvyn.jpg',
      alt: 'Cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696157/Azal%C3%A9%20avec%20mes%20modif/DSC_0661_prefri.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696157/Azal%C3%A9%20avec%20mes%20modif/DSC_0661_prefri.jpg',
      alt: 'Salon cuisine',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696154/Azal%C3%A9%20avec%20mes%20modif/DSC_0622_qsjy9q.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696154/Azal%C3%A9%20avec%20mes%20modif/DSC_0622_qsjy9q.jpg',
      alt: 'Décoration',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696154/Azal%C3%A9%20avec%20mes%20modif/DSC_0556_jmz3wb.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696154/Azal%C3%A9%20avec%20mes%20modif/DSC_0556_jmz3wb.jpg',
      alt: 'Décoration',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696155/Azal%C3%A9%20avec%20mes%20modif/DSC_0629_jr1nve.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1720696155/Azal%C3%A9%20avec%20mes%20modif/DSC_0629_jr1nve.jpg',
      alt: 'Porte entrée',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd4_gz7zoz.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd3_egd2ie.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd3_egd2ie.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd6_xgmnpi.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd2_iivciu.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd2_iivciu.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd1_x61hpv.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd1_x61hpv.jpg',
      alt: 'Petit-déjenuer',
    },
    {
      thumbnail: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg',
      src: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1721639048/Petit%20dej/ptd5_pkr1zr.jpg',
      alt: 'Petit-déjenuer',
    }      
  ];

  const onInit = () => {
    console.log('lightGallery has been initialized');
};

  
 
  return (
    <div >
      {/* Container pour les toasts */}
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

      <Navbar/>

                    <LightGallery
                        onInit={onInit}
                        speed={100}
                        plugins={[lgThumbnail,lgAutoplay, lgFullscreen, lgRotate,lgVideo]}
                    >
                        <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                            <MDBCardImage
                              src='https://res.cloudinary.com/dp0fbotva/image/upload/v1720696151/Azal%C3%A9%20avec%20mes%20modif/DSC_0808_onfkn0.jpg'
                              alt=''
                              position='top'
                            />
                           <MDBCardOverlay>

                           </MDBCardOverlay>
                            <MDBCardBody>
                              <MDBCardTitle>Villa Azalée</MDBCardTitle>
                              <MDBCardText>
                              Vous pouvez regarder tous les images de cette villa en cliquant sur la photo
                              </MDBCardText>
                            
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                      
                    {carouselPhotos.map((image, index) => {
                        return (
                          
                          <a href={image.src} key={index} data-sub-html={image.alt} data-thumb={image.thumbnail}>
                          <img src={image.thumbnail} alt={image.alt} style={{ display: 'none' }} />
                        </a>
 
                        )
                    })}

                  </LightGallery>

                  <MDBListGroupItem noBorders color='primary' className='px-3 mb-2 rounded-3' style={{margin:'50px',display:'flex',justifyContent:'center'}}>
                  Enregistrement de votre demande de réservation
                  </MDBListGroupItem>   
                  

              <div style={{margin:'50px',display:'flex',justifyContent:'center',flexWrap:'wrap', alignItems:'center', gap:'10px', color:'GrayText'}}>
              Merci de saisir le code promo : 
              <MDBInput name='codepromo' value={codepromo} wrapperClass='mb-4' id='form6Example70' label='Code Promo' type='text' onChange={(e)=>setCodepro(e.target.value) } /> 
              <MDBIcon far icon="hand-point-right" /> <div style={{color:'red'}}>(En rouge dates indisponibles)</div>
                
                  {/* nous pouvons importé la component calandrer mais il faut resoudre le probleme de l'envois de props car nous voulons recupérer des valeurs depuis component */}

                  {/* Configueprovider est pour personnaliser le coleur des date disable en rouge dans rangepicker */}
                  <ConfigProvider
                      theme={{
                        components: {
                          DatePicker: {
                            cellBgDisabled: 'red',
                            cellActiveWithRangeBg: 'green',
                            
                          },
                          
                        },
                      }}
                    >

                  <Space direction="vertical" size={12}>

                    <RangePicker 
                    disabledDate={disabledDate}
                    onChange={handleDateChange}
                    
                    />
                    
                  </Space>
                  </ConfigProvider>
              </div>

              <hr className="hr hr-blurry" />
                  <MDBBadge color='success' light style={{margin:'10px',display:'flex',justifyContent:'center'}}>Le prix de la période sélectionnée selon la saison sans options est: {price}€</MDBBadge>
                  
              <MDBRow className='row-cols-1 row-cols-md-2 g-5'style={{display:'flex',justifyContent:'center', paddingTop:'50px'}} >
                        <MDBCol>
                          <MDBCard className='h-100'>
                          <form style={{margin:'20px'}} onSubmit={handleSubmit}>
                            Merci de choisir le type de votre logement :
                          <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='Entièrevilla'
                                label='Villa Entière Azalée '
                                value="Villa Entière Azalée"
                                onChange={(e)=>setCheckedType(e.target.value)}
                              />
                              {/* <MDBCheckbox
                                disabled
                                wrapperClass='d-flex justify-content-center mb-4'
                                label='Chambre hôtes Azalée '
                                
                              /> */}
                              <MDBRow className='mb-4'>
                                <MDBCol>
                                  <MDBInput name='prenom' id='form6Example1' label='Prénom' type='text' onChange={(e)=>setPrenom(e.target.value)}/>
                                  
                                </MDBCol>
                                <MDBCol>
                                  <MDBInput name='nom' id='form6Example2' label='Nom' type='text' onChange={(e)=>setNom(e.target.value)}/>
                                </MDBCol>
                              </MDBRow>
                              

                              <MDBInput name='nbpersonne' wrapperClass='mb-4' id='form6Example3' label='Nombre de personne' type='number' onChange={(e)=>setNbpersonne(e.target.value) } max={8} min={1}/> 
                              {/* <MDBBtn className='mb-4'  disabled block color='success'>
                              Le prix total de logement sans options est: {totalprix}€
                              </MDBBtn>                           */}
                              <MDBInput name='adresse' wrapperClass='mb-4' id='form6Example4' label='Adresse (N°, Rue, Ville, Pays)' type='text' onChange={(e)=>setAdresse(e.target.value)}/>
                              <MDBInput name='email' wrapperClass='mb-4' type='email' id='form6Example5' label='Email' onChange={(e)=>setEmail(e.target.value)}/>
                              <MDBInput name='telephone' wrapperClass='mb-4' type='tel' id='form6Example6' label='Téléphone: Code pays - Numéro ' onChange={(e)=>setTelephone(e.target.value)} />

                              <MDBInput name='commentaire' wrapperClass='mb-4' textarea id='form6Example7' rows={4} label='Commentaires ou demandes particuliéres' onChange={(e)=>setCommentaire(e.target.value)}/>

                              Options choisies: 
                                  {/* nous pouvons importé la component optiontarifs mais il faut resoudre le probleme de l'envois de props car nous voulons recupérer des valeurs depuis component */}
                                  
                                  <div style={{display:'flex',justifyContent:'space-between',flexWrap:'wrap',margin:'15px'}}>
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example1'
                                label='Accueil Aéroport et transfert villa (50€)'
                                value='Accueil Aéroport et transfert villa (50€)'
                                onChange={(e)=>setCheckedopt1(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example2'
                                label='Femme de ménage 4 heures (39€)'
                                value='Femme de ménage 4 heures (39€)'
                                onChange={(e)=>setCheckedopt2(e.target.value)} 
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example3'
                                label='Cuisinière à domicile  par repas et hors produits 4 heures (39€)'
                                value='Cuisinière à domicile  par repas et hors produits 4 heures (39€)'
                                onChange={(e)=>setCheckedopt3(e.target.value)} 
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example4'
                                label='Petit déjeuner chambre hôtes par personne (9€) '
                                value='Petit déjeuner chambre hôtes par personne (9€) '
                                onChange={(e)=>setCheckedopt4(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example5'
                                label='Service de courses à domicile pour alimentation et boissons, hors produits (29€)'
                                value='Service de courses à domicile pour alimentation et boissons, hors produits (29€)'
                                onChange={(e)=>setCheckedopt5(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example6'
                                label='Serviette de piscine  avec chaussons (par personne) offerts en fin de séjour (25€)'
                                value='Serviette de piscine  avec chaussons (par personne) offerts en fin de séjour (25€)'
                                onChange={(e)=>setCheckedopt6(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example7'
                                label='Sac de plage avec serviette ( par personne) offerts en fin de séjour (35€)'
                                value='Sac de plage avec serviette ( par personne) offerts en fin de séjour (35€)'
                                onChange={(e)=>setCheckedopt7(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example8'
                                label='Service pressing sur demande avec prise en charge dans votre villa, hors prestation du pressing (15€)'
                                value='Service pressing sur demande avec prise en charge dans votre villa, hors prestation du pressing (15€)'
                                onChange={(e)=>setCheckedopt8(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example9'
                                label='Massage à domicile 60 minutes (39€)'
                                value='Massage à domicile 60 minutes (39€)'
                                onChange={(e)=>setCheckedopt9(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example10'
                                label='Voiture de location ( 5 personnes) pour  7 jours (250€ à 400€)'
                                value='Voiture de location ( 5 personnes) pour  7 jours (250€ à 400€)'
                                onChange={(e)=>setCheckedopt10(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example11'
                                label='Chauffeur personnel pour location voiture et par jour 8 heures (70€)'
                                value='Chauffeur personnel pour location voiture et par jour 8 heures (70€)'
                                onChange={(e)=>setCheckedopt11(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example12'
                                label='Baby-sitting de 4 heures (par tranche de 3 enfants) (40€)'
                                value='Baby-sitting de 4 heures (par tranche de 3 enfants) (40€)'
                                onChange={(e)=>setCheckedopt12(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example13'
                                label='Peignoirs individualisés offerts en fin de séjour (35€)'
                                value='Peignoirs individualisés offerts en fin de séjour (35€)'
                                onChange={(e)=>setCheckedopt13(e.target.value)}
                              />
                              <MDBCheckbox
                                wrapperClass='d-flex justify-content-center mb-4'
                                id='form6Example14'
                                label='Organisation activités : équitation, dromadaire,  calèche, jet-ski, kyte-surf, balnéothérapie, sortie en mer, excursions, etc. (Sur devis)'
                                value='Organisation activités : équitation, dromadaire,  calèche, jet-ski, kyte-surf, balnéothérapie, sortie en mer, excursions, etc. (Sur devis)'
                                onChange={(e)=>setCheckedopt14(e.target.value)}
                              />
                            </div>
                              
                              <MDBBtn className='mb-4' type='submit' block>
                                Enoyez votre demande de réservation
                              </MDBBtn>
                            </form>

                            <MDBCardBody>

                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                  </MDBRow>
                  <Footer/>
    </div>
  )
}

export default Azale