import axios from "axios"
import { ADD_CALENDRESA, FAIL_CALENDRESA, LOAD_CALENDRESA } from "../actionTypes/actionTypes"




export const addResaCalend=(newEvent)=> async(dispatch)=>   
{   dispatch({type:LOAD_CALENDRESA}) // appel load

    try {
        let  result =await axios.post("/api/calender/addresacalend",newEvent)  
        dispatch({type:ADD_CALENDRESA,payload:result.data})
        console.log(result)
    } catch (error) {
            dispatch({type:FAIL_CALENDRESA,payload:error})
    }

}


