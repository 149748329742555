

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import moment from 'moment';
import { updateReservation } from '../../redux/actions/resaActions'; // Assurez-vous que ce chemin est correct

const ReservationModifie = () => {
  const [reservations, setReservations] = useState([]);
  const [editableReservation, setEditableReservation] = useState(null);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  

  const fetchReservations = async () => {
    try {
      const response = await axios.get('/api/booking/get_resa');
      if (response.data && Array.isArray(response.data.reservation)) {
        const formattedReservations = response.data.reservation.map(reservation => {
          const formattedSousAcompte = reservation.sousAcompte.map(sousAcompte => ({
            ...sousAcompte,
            datacompt: sousAcompte.datacompt ? moment(sousAcompte.datacompt).format('DD/MM/YYYY') : sousAcompte.datacompt,
            acompt: sousAcompte.acompt ? sousAcompte.acompt.toFixed(2) : sousAcompte.acompt,
            soldacompt: sousAcompte.soldacompt ? sousAcompte.soldacompt.toFixed(2) : sousAcompte.soldacompt,
          }));

          const formattedSousOC = reservation.sousOC.map(sousOC => ({
            ...sousOC,
            datOC: sousOC.datOC ? moment(sousOC.datOC).format('DD/MM/YYYY') : sousOC.datOC,
            payemenOC: sousOC.payemenOC ? sousOC.payemenOC.toFixed(2) : sousOC.payemenOC,
            montantOC: sousOC.montantOC ? sousOC.montantOC.toFixed(2) : sousOC.montantOC,
            soldOC: sousOC.soldOC ? sousOC.soldOC.toFixed(2) : sousOC.soldOC,
          }));

          return {
            ...reservation,
            dateR: moment(reservation.dateR).format('DD/MM/YYYY'),
            dateA: moment(reservation.dateA).format('DD/MM/YYYY'),
            dateD: moment(reservation.dateD).format('DD/MM/YYYY'),
            dateRVT: moment(reservation.dateRVT).format('DD/MM/YYYY'),
            dateEC: moment(reservation.dateEC).format('DD/MM/YYYY'),
            dateRC: moment(reservation.dateRC).format('DD/MM/YYYY'),
            totalL: reservation.totalL.toFixed(2),
            Comsion: reservation.Comsion.toFixed(2),
            TACommi: reservation.TACommi.toFixed(2),
            sousAcompte: formattedSousAcompte,
            sousOC: formattedSousOC,
          };
        });
        setReservations(formattedReservations);
      } else {
        console.error('Data received is not in the expected format:', response.data);
      }
    } catch (error) {
      console.error('Error fetching reservations:', error);
    }
  };

  useEffect(() => {
    fetchReservations();
  }, []);

  const handleEdit = (reservation) => {
    setEditableReservation(reservation.idresa);
    setFormData({
      ...reservation,
      dateR: moment(reservation.dateR, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dateA: moment(reservation.dateA, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dateD: moment(reservation.dateD, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dateRVT: moment(reservation.dateRVT, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dateEC: moment(reservation.dateEC, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      dateRC: moment(reservation.dateRC, 'DD/MM/YYYY').format('YYYY-MM-DD')
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSousAcompteChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSousAcompte = [...formData.sousAcompte];
    updatedSousAcompte[index] = {
      ...updatedSousAcompte[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      sousAcompte: updatedSousAcompte,
    });
  };

  const handleSousOCChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSousOC = [...formData.sousOC];
    updatedSousOC[index] = {
      ...updatedSousOC[index],
      [name]: value,
    };
    setFormData({
      ...formData,
      sousOC: updatedSousOC,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedData = {
      ...formData,
      dateR: formData.dateR ? moment(formData.dateR, 'YYYY-MM-DD').format('DD/MM/YYYY') : null,
      dateA: formData.dateA ? moment(formData.dateA, 'YYYY-MM-DD').toISOString() : null,
      dateD: formData.dateD ? moment(formData.dateD, 'YYYY-MM-DD').toISOString() : null,
      dateRVT: formData.dateRVT ? moment(formData.dateRVT, 'YYYY-MM-DD').toISOString() : null,
      dateEC: formData.dateEC ? moment(formData.dateEC, 'YYYY-MM-DD').toISOString() : null,
      dateRC: formData.dateRC ? moment(formData.dateRC, 'YYYY-MM-DD').toISOString() : null,
      sousAcompte: formData.sousAcompte.map(sousAcompte => ({
        ...sousAcompte,
        datacompt: sousAcompte.datacompt ? moment(sousAcompte.datacompt, 'YYYY-MM-DD').format('DD/MM/YYYY') : sousAcompte.datacompt,
        acompt: sousAcompte.acompt ? parseFloat(sousAcompte.acompt).toFixed(2) : sousAcompte.acompt,
        soldacompt: sousAcompte.soldacompt ? parseFloat(sousAcompte.soldacompt).toFixed(2) : sousAcompte.soldacompt,
      })),
      sousOC: formData.sousOC.map(sousOC => ({
        ...sousOC,
        datOC: sousOC.datOC ? moment(sousOC.datOC, 'YYYY-MM-DD').format('DD/MM/YYYY') : sousOC.datOC,
        payemenOC: sousOC.payemenOC ? parseFloat(sousOC.payemenOC).toFixed(2) : sousOC.payemenOC,
        montantOC: sousOC.montantOC ? parseFloat(sousOC.montantOC).toFixed(2) : sousOC.montantOC,
        soldOC: sousOC.soldOC ? parseFloat(sousOC.soldOC).toFixed(2) : sousOC.soldOC,
      })),
    };

    dispatch(updateReservation(editableReservation, formattedData)).then(() => {
      setEditableReservation(null);
      fetchReservations(); // Trigger table refresh
    });
  };

  return (
    <div>
      <MDBTable striped>
        <MDBTableHead dark>
          <tr>
          <th scope='col'>Index</th>
            <th scope='col'>ID/Fac</th>
            <th scope='col'>Villa</th>
            <th scope='col'>Locateur</th>
            <th scope='col'>Nationnalité</th>
            <th scope='col'>NB Personne</th>
            <th scope='col'>Plateforme</th>
            <th scope='col'>Date Resa</th>
            <th scope='col'>Date Arriver</th>
            <th scope='col'>Date Départ</th>
            <th scope='col'>NB Nuits</th>
            <th scope='col'>Code</th>
            <th scope='col'>Total €</th>
            <th scope='col'>Commission €</th>
            <th scope='col'>Total Final €</th>
            <th scope='col'>Virement Final</th>
            <th scope='col'>Détails</th>
            <th scope='col'>des</th>
            <th scope='col'>acomptes</th>
            <th scope='col'>faites</th>
            <th scope='col'>par</th>
            <th scope='col'>client</th>
            <th scope='col'>Etat dossier</th>
            <th scope='col'>Détails </th>
            <th scope='col'>des</th>
            <th scope='col'>options</th>
            <th scope='col'>choisis</th>
            <th scope='col'></th>
            <th scope='col'></th>
            <th scope='col'>Date Enovois Contrat</th>
            <th scope='col'>Date Recevoir Contrat</th>
            <th scope='col'>Actions</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {reservations.map((reservation, index) => (
            <tr key={index}>
              <th scope='row'>{index + 1}</th>
              <td>{reservation.idresa}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="text"
                  name="villa"
                  value={formData.villa}
                  onChange={handleChange}
                />
              ) : (
                reservation.villa
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="text"
                  name="nomL"
                  value={formData.nomL}
                  onChange={handleChange}
                />
              ) : (
                reservation.nomL
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="text"
                  name="nat"
                  value={formData.nat}
                  onChange={handleChange}
                />
              ) : (
                reservation.nat
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="number"
                  name="nbP"
                  value={formData.nbP}
                  onChange={handleChange}
                />
              ) : (
                reservation.nbP
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="text"
                  name="siteR"
                  value={formData.siteR}
                  onChange={handleChange}
                />
              ) : (
                reservation.siteR
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="date"
                  name="dateR"
                  value={formData.dateR}
                  onChange={handleChange}
                />
              ) : (
                reservation.dateR
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="date"
                  name="dateA"
                  value={formData.dateA}
                  onChange={handleChange}
                />
              ) : (
                reservation.dateA
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="date"
                  name="dateD"
                  value={formData.dateD}
                  onChange={handleChange}
                />
              ) : (
                reservation.dateD
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="number"
                  name="nbN"
                  value={formData.nbN}
                  onChange={handleChange}
                />
              ) : (
                reservation.nbN
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="text"
                  name="codL"
                  value={formData.codL}
                  onChange={handleChange}
                />
              ) : (
                reservation.codL
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="number"
                  name="totalL"
                  value={formData.totalL}
                  onChange={handleChange}
                />
              ) : (
                reservation.totalL
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="number"
                  name="Comsion"
                  value={formData.Comsion}
                  onChange={handleChange}
                />
              ) : (
                reservation.Comsion
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="number"
                  name="TACommi"
                  value={formData.TACommi}
                  onChange={handleChange}
                />
              ) : (
                reservation.TACommi
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="date"
                  name="dateRVT"
                  value={formData.dateRVT}
                  onChange={handleChange}
                />
              ) : (
                reservation.dateRVT
              )}</td>

              <td colSpan={6}>
                  {editableReservation === reservation.idresa ? (
                    reservation.sousAcompte.map((sousAcompte, i) => (
                      <div key={i}>
                        <strong>Acompte €:</strong>
                        <input
                          type="number"
                          name="acompt"
                          value={sousAcompte.acompt}
                          onChange={(e) => handleSousAcompteChange(i, e)}
                        />
                        , <strong>BQE:</strong>
                        <input
                          type="text"
                          name="bqacompt"
                          value={sousAcompte.bqacompt}
                          onChange={(e) => handleSousAcompteChange(i, e)}
                        />
                        , <strong>Date:</strong>
                        <input
                          type="date"
                          name="datacompt"
                          value={sousAcompte.datacompt}
                          onChange={(e) => handleSousAcompteChange(i, e)}
                        />
                        , <strong>Solde:</strong>
                        <input
                          type="number"
                          name="soldacompt"
                          value={sousAcompte.soldacompt}
                          onChange={(e) => handleSousAcompteChange(i, e)}
                        />
                      </div>
                    ))
                  ) : (
                    reservation.sousAcompte.map((sousAcompte, i) => (
                      <div key={i}>
                        <strong>Acompte €:</strong> {sousAcompte.acompt}, <strong>BQE:</strong> {sousAcompte.bqacompt}, <strong>Date:</strong> {sousAcompte.datacompt}, <strong>Solde:</strong> {sousAcompte.soldacompt}
                      </div>
                    ))
                  )}
                </td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="text"
                  name="dossiefini"
                  value={formData.dossiefini}
                  onChange={handleChange}
                />
              ) : (
                reservation.dossiefini
              )}</td>
              <td colSpan={6}>
                  {editableReservation === reservation.idresa ? (
                    reservation.sousOC.map((sousOC, i) => (
                      <div key={i}>
                        <strong>OC:</strong>
                        <input
                          type="text"
                          name="OC"
                          value={sousOC.OC}
                          onChange={(e) => handleSousOCChange(i, e)}
                        />
                        , <strong>Montant €:</strong>
                        <input
                          type="text"
                          name="montantOC"
                          value={sousOC.montantOC}
                          onChange={(e) => handleSousOCChange(i, e)}
                        />
                        , <strong>Date:</strong>
                        <input
                          type="date"
                          name="datOC"
                          value={sousOC.datOC}
                          onChange={(e) => handleSousOCChange(i, e)}
                        />
                        , <strong>Payement:</strong>
                        <input
                          type="text"
                          name="payemenOC"
                          value={sousOC.payemenOC}
                          onChange={(e) => handleSousOCChange(i, e)}
                        />
                        , <strong>BQE:</strong>
                        <input
                          type="text"
                          name="bqOC"
                          value={sousOC.bqOC}
                          onChange={(e) => handleSousOCChange(i, e)}
                        />
                        , <strong>Solde €:</strong>
                        <input
                          type="text"
                          name="soldOC"
                          value={sousOC.soldOC}
                          onChange={(e) => handleSousOCChange(i, e)}
                        />
                      </div>
                    ))
                  ) : (
                    reservation.sousOC.map((sousOC, i) => (
                      <div key={i}>
                        <strong>OC:</strong> {sousOC.OC}, <strong>Montant €:</strong> {sousOC.montantOC}, <strong>Date:</strong> {sousOC.datOC}, <strong>Payement:</strong> {sousOC.payemenOC}, <strong>BQE:</strong> {sousOC.bqOC}, <strong>Solde €:</strong> {sousOC.soldOC}
                      </div>
                    ))
                  )}
                </td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="date"
                  name="dateEC"
                  value={formData.dateEC}
                  onChange={handleChange}
                />
              ) : (
                reservation.dateEC
              )}</td>
              <td>{editableReservation === reservation.idresa ? (
                <input
                  type="date"
                  name="dateRC"
                  value={formData.dateRC}
                  onChange={handleChange}
                />
              ) : (
                reservation.dateRC
              )}</td>              
              <td>
                {editableReservation === reservation.idresa ? (
                  <>
                    <button onClick={handleSubmit}>Save</button>
                    <button onClick={() => setEditableReservation(null)}>Cancel</button>
                  </>
                ) : (
                  <button onClick={() => handleEdit(reservation)}>Edit</button>
                )}
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
      
    </div>
  );
};

export default ReservationModifie;
