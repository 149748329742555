


import React, { useState } from 'react';
import {
  MDBNavbar,
  MDBContainer,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import logo from '../images/logoER.png'
import { useDispatch } from 'react-redux';
import { logOut } from '../redux/actions/userActions';

const NavbarLogout = () => {
  const [openNavCentred, setOpenNavCentred] = useState(false);

  const dispatch=useDispatch()

  return (
    <div>
      <header role="banner">
  <img id="logo-main" src={logo} width="200" alt="Logo Thing main logo"/>

  
</header>
<MDBNavbar expand='lg' light bgColor='light'>
      <MDBContainer fluid >
        <MDBNavbarToggler 
          type='button'
          data-target='#navbarCenteredExample'
          aria-controls='navbarCenteredExample'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setOpenNavCentred(!openNavCentred)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar open={openNavCentred} id='navbarCenteredExample' style={{justifyContent:'center'}}>
          <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-1' style={{gap:'20px'}} >

            
            <MDBNavbarItem>
              <MDBNavbarLink href='/resadd'>Gestion Réservations</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/gestionTarifs'>Gestion Tarifs</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/calender'>Calendrier</MDBNavbarLink>
            </MDBNavbarItem>
            <MDBNavbarItem>
              <MDBNavbarLink href='/' onClick={()=>dispatch(logOut())}>Déconnecter</MDBNavbarLink>
            </MDBNavbarItem>
            
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
      
    </div>
  )
}

export default NavbarLogout