import { MDBCard, MDBCardBody, MDBCardImage, MDBContainer, MDBNavbar } from 'mdb-react-ui-kit'
import React from 'react'
import NavbarLogout from './NavbarLogout'

const TravauxAlert = () => {
  return (
    <div>
      <header>
                            <MDBNavbar expand='lg' light bgColor='white' sticky>
                              <MDBContainer fluid style={{justifyContent:'center'}}>
                                
                                {/* Importation de narbar component  */}
                                <NavbarLogout/>

                              </MDBContainer>
                            </MDBNavbar>

                            {/* Contenu de la page contactez-nous en dissous en ferme avec </header */}
      <MDBCard className='mb-3'>
        <MDBCardImage position='top' src='https://i0.wp.com/art-de-peindre.com/wp-content/uploads/2020/05/Page-en-construction.jpg?resize=600%2C518' alt='...' />
        <MDBCardBody>
          
        </MDBCardBody>
      </MDBCard>

      </header>
    </div>
  )
}

export default TravauxAlert