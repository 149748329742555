import React from 'react';
import { MDBContainer, MDBNavbar, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardImage, MDBCardTitle, MDBCardText } from 'mdb-react-ui-kit';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';



const partnersData = [
  {
    name: 'Eden des Sages',
    sousname: 'Le REPOS DES GUERRIERS',
    logo: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1723188922/photo%20site%20partenariat/Logo_Eden_guerrier_kly2bd.png',
    activities: [
      {
        photo: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_600,w_900/v1723188534/photo%20site%20partenariat/sage1_skph0s.jpg',
        description: 'Vous souhaitez faire une petite escapade sur le continent et explorer d’avantage le sud de la Tunisie, voire même aller jusqu’à la porte du désert. Tout cela peut s’organiser.',
        title: 'Évasion sur le continent',
      },
      {
        photo: 'https://res.cloudinary.com/dp0fbotva/image/upload/v1723188534/photo%20site%20partenariat/jetski_r0lriy.jpg',
        description: 'Si vous avez choisi Djerba, cela reflète votre passion pour la mer et les magnifiques plages. Nous vous proposons une diversité de sorties en bateau ou en Jet Ski.',
        title: 'Activités en mer',
      },
      {
        photo: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_600,w_900/v1723188535/photo%20site%20partenariat/laguna_l6cufm.png',
        description: 'Les balades à cheval et à dos de dromadaire offrent des expériences uniques, chacune ayant ses propres charmes et spécificités. Tentez votre première expérience à Djerba pour découvrir des paysages et une nature hors du temps. Balades quotidiennes pour les amateurs.',
        title: 'Balade à cheval ou à dromadaire',
      },
      {
        photo: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_600,w_900/v1723188534/photo%20site%20partenariat/caption_v4nhne.jpg',
        description: 'Vous êtes passionné de quads et de buggy ? Je vous organise votre sortie en toute sérénité, en groupe ou en individuel.',
        title: 'Quads & Buggy',
      },
      {
        photo: 'https://res.cloudinary.com/dp0fbotva/image/upload/c_scale,h_600,w_900/v1723188534/photo%20site%20partenariat/spa_bfnaco.jpg',
        description: 'Si vous avez besoin d’un moment de détente, je peux organiser vos soins à la carte : hammam, gommages corporels, massages, soins du visage, etc.',
        title: 'Spa & Massages',
      },
    ],
  },
];


const Partenariat = () => {
  return (
    <div>
      <Helmet>
        <title>Nos Partenaires</title>
        <meta name="description" content="Nos Partenaires" />
      </Helmet>
      <header>
        <MDBNavbar expand="lg" light bgColor="white" sticky>
          <MDBContainer fluid style={{ justifyContent: 'center' }}>
            <Navbar />
          </MDBContainer>
        </MDBNavbar>
      

      <main>
        <MDBContainer className="text-center my-5">
          <img src={partnersData[0].logo} alt={`${partnersData[0].name} logo`} style={{ width: '150px', height: 'auto' }} />
          <h1 className="mt-3">{partnersData[0].name}</h1>
          <h3 className="mb-5">{partnersData[0].sousname}</h3>

          <MDBRow className="row-cols-1 row-cols-md-3 g-4 justify-content-center">
            {partnersData[0].activities.map((activity, index) => (
              <MDBCol key={index}>
                <MDBCard className="h-100">
                  <MDBCardImage src={activity.photo} alt={activity.title} position="top" className="img-fluid" />
                  <MDBCardBody>
                    <MDBCardTitle>{activity.title}</MDBCardTitle>
                    <MDBCardText>{activity.description}</MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </main>

      <Footer />
      </header>
    </div>
  );
};

export default Partenariat;
